import React from 'react';

class Toggle extends React.PureComponent<Props> {
    onChange = (value: unknown) => {
        if(this.props.currentValue === value) return;

        this.props.onChange(value);
    };

    componentDidMount() {
        if(!this.props.currentValue || this.props.currentValue === null) {
            this.props.onChange(this.props.value1);
        }
    }

    render() {
        return (
            <div className="toggle">
                <div className={'toggle-value1' + (this.props.currentValue === this.props.value1 ? ' selected' : ' unselected')} onClick={() => this.onChange(this.props.value1)}>{this.props.displayValue1}</div>
                <div className={'toggle-value2' + (this.props.currentValue === this.props.value2 ? ' selected' : ' unselected')} onClick={() => this.onChange(this.props.value2)}>{this.props.displayValue2}</div>
            </div>
        );
    }
}

type Props = {
    value1: unknown,
    value2: unknown,
    displayValue1: string,
    displayValue2: string,

    currentValue?: unknown,
    onChange: (value: any) => void
};

export default Toggle;
