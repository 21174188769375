import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';
import {InitialState} from './initialState';
import {StoreTimesheetFromClient} from '../typescript/storeTypes';

export default function (state: {[assignmentId: number]: StoreTimesheetFromClient[]} = InitialState.timesheetsFromClient, action: Action): {[assignmentId: number]: StoreTimesheetFromClient[]} {
    switch (action.type) {
        case ActionTypes.GET_TIMESHEETS_FROM_CLIENT_INFO_SUCCEEDED: {
            return Object.assign([], state, {[action.payload.assignmentId]: action.payload.body});
        }
        case ActionTypes.DELETE_ASSIGNMENTDOCUMENT_SUCCEEDED: {
            const deletedAssignmentDocumentIndex = state[action.payload.assignmentId].findIndex(a => a.assignmentDocumentId === action.payload.assignmentDocumentId);

            const assignmentDocs = Object.assign([], state[action.payload.assignmentId]);
            assignmentDocs.splice(deletedAssignmentDocumentIndex, 1);
            return Object.assign([], state, {[action.payload.assignmentId]: assignmentDocs});
        }
        case ActionTypes.UPLOAD_CLIENTTIMESHEET_SUCCEEDED: {
            const newAssignmentDoc = {
                assignmentDocumentId: action.payload.assignmentDocumentId,
                assignmentId: action.payload.assignmentId,
                documentName: action.payload.documentName,
                uploadedOn: new Date()
            };
            const newDocs = Object.assign([], state[action.payload.assignmentId]);
            newDocs.push(newAssignmentDoc);
            return Object.assign([], state, {[action.payload.assignmentId]: newDocs});
        }
        default: {
            return state;
        }
    }
}
