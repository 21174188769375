import AuthorizedRequest from './authorizedRequests';
import ErrorHelper from '../helpers/errorHelper';

class GroupApi {
    static getGroups(): Promise<any> {
        return AuthorizedRequest.getAuthorizedRequest('group')
            .ok(res => res.status === 200)
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }
}

export default GroupApi;
