import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';
import {InitialState} from './initialState';
import {StoreSupplier, StoreSuppliers} from '../typescript/storeTypes';

export default function (state: StoreSuppliers = InitialState.suppliers, action: Action): StoreSuppliers {
    switch (action.type) {
        case ActionTypes.SUPPLIERS_SUCCEEDED: {
            return Object.assign({}, state, { suppliers: action.payload as StoreSupplier[] });
        }
        case ActionTypes.GET_ALL_SUPPLIERS_SUCCEEDED: {
            return Object.assign({}, state, { allSuppliers: action.payload as StoreSupplier[] });
        }
        case ActionTypes.CLEAR_SUPPLIERS_REQUESTED: {
            return Object.assign({}, state, { suppliers: [] as StoreSupplier[] });
        }
        default: {
            return state;
        }
    }
}
