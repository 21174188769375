import React from 'react';

class TextInput extends React.PureComponent<Props> {
    isReadOnly = () => {
        return (this.props.readOnly === undefined || this.props.readOnly === false) ? false : true;
    };

    render() {
        const wrapperClass = `form-group${this.props.error ? ' has-error' : ''}`;
        return (
            <div className={wrapperClass}>
                {this.props.label &&
                <label htmlFor={this.props.name} className={`${this.props.labelExtra ? ' has-extra ': ''} ${this.props.required ? 'required': ''}`}>
                    {this.props.label}
                    {this.props.labelExtra && <span> {this.props.labelExtra}</span>}
                    {!this.props.labelExtra && ':'}
                </label>}
                <input
                    type={this.props.password ? 'password' : (this.props.search ? 'search' : 'text')}
                    name={this.props.name} id={this.props.name}
                    className={'form-control'}
                    readOnly={this.isReadOnly()}
                    disabled={!!this.props.disabled}
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                    onChange={this.props.onChange}
                    autoFocus={this.props.autoFocus}
                    onKeyUp={this.props.onKeyUp}/>
                {this.props.error && <div className="alert alert-danger">{this.props.error}</div>}
            </div>
        );
    }
}

type Props = {
    id?: string,
    name: string,
    label?: string,
    labelExtra?: any,
    value?: string,
    placeholder?: string,
    onChange(event: React.ChangeEvent<HTMLInputElement>): void,
    error?: string,
    password?: boolean,
    autoFocus?: boolean,
    readOnly?: boolean,
    disabled?: boolean,
    search?: boolean,
    required?: boolean,
    onKeyUp?: (event: React.SyntheticEvent<HTMLInputElement> & {keyCode: number}) => void
};

export default TextInput;
