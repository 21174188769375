import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';
import {InitialState} from './initialState';
import {StoreReporting} from '../typescript/storeTypes';

export default function (state: StoreReporting = InitialState.reporting, action: Action): StoreReporting {
    switch (action.type) {
        case ActionTypes.SUPPLIERS_EMPLOYEES_SUCCEEDED: {
            return Object.assign({}, state, {supplierEmployees: action.payload});
        }
        case ActionTypes.POST_GET_TIME_LEFT_ON_ORDER_SUCCEEDED: {
            return Object.assign({}, state, {timeLeftOnOrder: action.payload});
        }
        case ActionTypes.CLEAR_TIME_LEFT_ON_ORDER_SUCCEEDED: {
            return Object.assign({}, state, {timeLeftOnOrder: InitialState.reporting.timeLeftOnOrder, icPartnersOnTsCode: InitialState.reporting.icPartnersOnTsCode});
        }
        case ActionTypes.GET_TSCODES_ICPARTNERS_SUCCEEDED: {
            return Object.assign({}, state, {icPartnersOnTsCode: action.payload});
        }
        case ActionTypes.SUPPLIERS_EMPLOYEES_FAILED:
        case ActionTypes.CLEAR_SUPPLIERS_EMPLOYEES_REQUESTED: {
            return Object.assign({}, state, {supplierEmployees: []});
        }
        default: {
            return state;
        }
    }
}
