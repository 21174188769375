import Disclaimer from './Disclaimer';
import MaintenanceModeDisclaimer from './MaintenanceModeDisclaimer';
import React from 'react';
import TimesheetFooterCode from './TimesheetFooterCode';
import TimesheetFooterDay from './TimesheetFooterDay';
import TimesheetFooterTotal from './TimesheetFooterTotal';
import TimesheetHelper from '../../../../helpers/timesheetHelper';
import Version from './Version';

import {StoreMaintenanceDetails, StoreSettings, StoreTimesheet, StoreTimesheetCode, StoreTimesheetEntry, StoreUser} from '../../../../typescript/storeTypes';
import AccessibleUserHelper from '../../../../helpers/accessibleUserHelper';

class TimesheetFooter extends React.PureComponent<Props> {
    isCompleted = (): boolean => {
        return TimesheetHelper.isTimesheetComplete(this.props.timesheet);
    };

    onAddHours = () => {
        this.props.onDetailView(TimesheetHelper.defaultEntry);
    };

    orderTimesheetCodes = (): StoreTimesheetCode[] => {
        if(this.props.settings.timesheetCodeOrder) {
            return this.props.timesheet.timesheetCodes;
        }

        return TimesheetHelper.orderTsCodesByLastUsed(this.props.timesheet.timesheetCodes);
    };

    render() {
        const activeDay = this.props.timesheet.period.days[this.props.activeDayIndex];
        let totalHoursDay = 0;

        return (
            <div className="timesheet-footer">
                {activeDay && <div className="active">
                    <TimesheetFooterDay day={activeDay}/>
                    <div className="timesheet-codes">
                        {this.orderTimesheetCodes().map(timesheetCode => {
                            const entry = TimesheetHelper.getEntry(timesheetCode, activeDay);
                            if (entry !== TimesheetHelper.defaultEntry) {
                                totalHoursDay += entry.totalHours;
                                return (<TimesheetFooterCode key={timesheetCode.id}
                                                             timesheetCode={timesheetCode}
                                                             entry={entry}
                                                             onDetailView={this.props.onDetailView}/>);
                            }
                        })}
                        <TimesheetFooterTotal totalHoursDay={totalHoursDay}/>
                    </div>
                    <div className="add-hours">
                        <input id="addHoursButton"
                               type="submit"
                               onClick={this.onAddHours}
                               disabled={this.isCompleted() || !this.props.canAddEntry || !AccessibleUserHelper.isAccessibleUser(this.props.user)}
                               value={this.isCompleted() ? 'Timesheet Completed' : 'Add Hours'}/>
                    </div>
                </div>}
                <div className="timesheet-footer__footer-info">
                    <Disclaimer/>
                    {!this.props.maintenanceDetails.isInMaintenanceMode ? null :
                        <MaintenanceModeDisclaimer />
                    }
                    <Version/>
                </div>
            </div>
        );
    }
}

type Props = {
    user: StoreUser,
    timesheet: StoreTimesheet,
    settings: StoreSettings,
    activeDayIndex: number,
    canAddEntry: boolean,
    maintenanceDetails: StoreMaintenanceDetails,

    onDetailView: (entry: StoreTimesheetEntry, timesheetCode?: StoreTimesheetCode) => void
};

export default TimesheetFooter;
