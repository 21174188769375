import {ActionTypes} from '../types/actionTypes';
import {call, put, takeEvery} from 'redux-saga/effects';
import releaseNoteApi from '../api/releaseNoteApi';

function* getReleaseNotes(): Generator<any, any,any> {
    try {
        const releaseNotes = yield call(releaseNoteApi.getReleaseNotes);
        yield put({type: ActionTypes.GET_RELEASE_NOTES_SUCCEEDED, payload: releaseNotes});
    } catch (e) {
        const releaseNotes = [] as any[];
        yield put({type: ActionTypes.GET_RELEASE_NOTES_FAILED, payload: releaseNotes});
    }
}

function* releaseNoteSaga(): Generator<any, any, any> {
    yield takeEvery(ActionTypes.GET_RELEASE_NOTES_REQUESTED, getReleaseNotes);
}

export default releaseNoteSaga;
