export const KeyCodes = {
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
    SHIFT: 16,
    DELETE: 46,
    CTRL: 17,
    BACKSPACE: 8,
    ENTER: 13
};
