import * as React from 'react';

class MobileModal extends React.PureComponent<Props> {
    render() {
        return (
            <div className="modal-mobile">
                <div className="modal-mobile-forefront">
                    <div className={'modal-mobile-dialog ' + (this.props.className ? this.props.className : '')}>
                        <div className="modal-mobile-content">
                            {this.props.children}
                        </div>
                    </div>
                </div>
                <div className="modal-mobile-backdrop" />
            </div>
        );
    }
}

type Props = {
    children: React.ReactElement[] | React.ReactElement,
    className?: string
};

export default MobileModal;
