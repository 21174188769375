import {StoreBaseEmployeeName, StoreEmployee, StoreVisibleUser} from '../typescript/storeTypes';
import WildCardHelper from './wildCardHelper';

class EmployeeSortHelper {
    static isHit(word: string, employee: StoreBaseEmployeeName): boolean {
       return WildCardHelper.containsString(word, `${employee.firstName} ${employee.lastName} ${employee.lastName} ${employee.firstName} ${employee.username} ${employee.employer} ${employee.nickName}`);
    }

    static sort(stack: SortStack, sortOrder: string): StoreEmployee[] {
        const sortedStack = stack.sort((a, b) => {
            if (a.hits < b.hits) return 1;
            if (a.hits > b.hits) return -1;

            let nameA;
            let nameB;
            if(sortOrder.toLowerCase() === 'lastname') {
                nameA = a.user.lastName.toLowerCase();
                nameB = b.user.lastName.toLowerCase();
            } else {
                nameA = a.user.firstName.toLowerCase();
                nameB = b.user.firstName.toLowerCase();
            }

            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;

            return 0;
        });

        return sortedStack.map(s => s.user);
    }

    static findMatch = (searchString: string, currentUsername: string, visibleUsers: StoreVisibleUser[], orderBy: string): StoreEmployee[] => {
        const splitted = searchString.trim().toLowerCase().split(' ');
        const stack: SortStack = [];
        for (const user of visibleUsers.filter(vu => vu.username !== currentUsername)) {
            let hits = 0;
            for (const word of splitted) {
                if (EmployeeSortHelper.isHit(word, user) || !word) hits++;
            }

            if (hits === splitted.length) {
                stack.push({user: user, hits: hits});
            }
        }

        return EmployeeSortHelper.sort(stack, orderBy).slice(0, 100);
    };
}

type SortStack = SortStackItem[];
type SortStackItem = {
    user: StoreEmployee,
    hits: number
};

export default EmployeeSortHelper;
