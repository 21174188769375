import {Day, Period} from '../typescript/dateTypes';
import {EntryLocationInfo} from '../typescript/infoTypes';
import {NavigationEvent} from '../typescript/timesheetTypes';
import {StoreMonthYear, StoreTimesheet, StoreTimesheetCode, StoreTimesheetEntry, StoreTsCode, StoreTsCodes} from '../typescript/storeTypes';
import DateHelper from './dateHelper';

class TimesheetHelper {
    static defaultEntry: StoreTimesheetEntry = {
        id: 0,
        date: '',
        normalHours: 0,
        extraHours: 0,
        standByHours: 0,
        interventionHours: 0,
        totalHours: 0,
        completed: false,
        canBeDeleted: true,
        modDate: new Date(),
        comments: undefined,
        assignmentId:0
    };

    static getEntry(timesheetCode: StoreTimesheetCode, day: Day): StoreTimesheetEntry {
        const entry = timesheetCode.entries.find(entry => entry.date.indexOf(day.dateString) === 0);
        return entry ? entry : TimesheetHelper.defaultEntry;
    }

    static getEntries(timesheetCodes: StoreTimesheetCode[], day: Day): StoreTimesheetEntry[] {
        const entries = [];

        for (const timesheetCode of timesheetCodes) {
            const entry = timesheetCode.entries.find(entry => entry.date.indexOf(day.dateString) === 0);
            if (entry) entries.push(entry);
        }

        return entries;
    }

    static changeInEntries(prevEntry: StoreTimesheetEntry, nextEntry: StoreTimesheetEntry): boolean {
        if (prevEntry.normalHours !== nextEntry.normalHours) return true;
        if (prevEntry.extraHours !== nextEntry.extraHours) return true;
        if (prevEntry.standByHours !== nextEntry.standByHours) return true;
        if (prevEntry.interventionHours !== nextEntry.interventionHours) return true;
        else return prevEntry.comments !== nextEntry.comments;
    }

    static changeInEntryLocation(prevEntry: EntryLocationInfo, nextEntry: EntryLocationInfo): boolean {
        if (prevEntry.tsCodeIndex !== nextEntry.tsCodeIndex) return true;
        if (prevEntry.dayIndex !== nextEntry.dayIndex) return true;
        return prevEntry.level !== nextEntry.level;
    }

    static hasExtraHours(entry: StoreTimesheetEntry): boolean {
        return !!(entry.extraHours || entry.standByHours || entry.interventionHours);
    }

    static didMoveUp(event: NavigationEvent): boolean {
        return (event.key === 'ArrowUp' || event.key === 'w' || event.key === 'W' || event.key === 'z' || event.key === 'Z') && !this.hasModifierKeys(event);
    }

    static didMoveDown(event: NavigationEvent): boolean {
        return (event.key === 'ArrowDown' || event.key === 's' || event.key === 'S') && !this.hasModifierKeys(event);
    }

    static didMoveLeft(event: NavigationEvent): boolean {
        return (event.shiftKey && event.key === 'Tab') || (event.key === 'a' || event.key === 'A' || event.key === 'q' || event.key === 'Q') && !this.hasModifierKeys(event);
    }

    static didMoveRight(event: NavigationEvent): boolean {
        return ((!event.shiftKey && event.key === 'Tab') || (event.key === 'd' || event.key === 'D')) && !this.hasModifierKeys(event);
    }

    static didExpand(event: NavigationEvent): boolean {
        return event.key === ' ';
    }

    static hasModifierKeys(event: NavigationEvent): boolean {
        return event.shiftKey || event.altKey || event.ctrlKey;
    }

    static isTimesheetComplete(timesheet: StoreTimesheet): boolean {
        const notReadonlyTimesheetCodes = timesheet.timesheetCodes.filter(t => !t.isReadOnly);
        return (notReadonlyTimesheetCodes.length > 0 && notReadonlyTimesheetCodes.findIndex(t => !TimesheetHelper.isTimesheetCodeCompletedEntries(t, timesheet.period)) === -1);
    }

    static isTimesheetCodeCompleted(timesheetCode: StoreTimesheetCode, date: Date): boolean {
        return timesheetCode.completedMonths !== undefined && timesheetCode.completedMonths.findIndex(m => m.month === (date.getMonth() +1) && m.year === date.getFullYear()) !== -1;
    }

    static isTimesheetCodeCompletedEntries(timesheetCode: StoreTimesheetCode, period: Period): boolean {
        if(timesheetCode.entries.length > 0) {
            return timesheetCode.entries.findIndex(entry => !entry.completed) === -1;
        } else {
            return period.days.findIndex(day => !TimesheetHelper.isTimesheetCodeCompleted(timesheetCode, DateHelper.getNormalizedDate(new Date(day.dateString)))) === -1;
        }
    }

    static isTimesheetEntryForCompletedMonth = (entryMonth: number, entryYear: number, timesheetMonth: number, incompleteMonths: StoreMonthYear[] = []): boolean => {
        return !incompleteMonths.some(date => date.month === entryMonth && date.year === entryYear)
            && entryMonth !== timesheetMonth;
    };

    static isCurrentMonth = (entryMonth: number, entryYear: number): boolean => {
        return new Date().getMonth() + 1 === entryMonth && new Date().getFullYear() === entryYear;
    };

    static formatTimesheetCodeDesktop(name: string): string {
        return name.length > 22 ? name.substring(0, 22) + ' ...' : name;
    }

    static formatTimesheetCodeMobile(name: string): string {
        return name.length > 18 ? name.substring(0, 18) + ' ...' : name;
    }

    static calculateDays(timesheet: StoreTimesheet): number {
        let days = 0;
        for (const day of timesheet.period.days) {
            if (DateHelper.isWeekend(day)) continue;

            const hours = TimesheetHelper.getTotalDayHours(timesheet, day);
            if (hours < (7.6 - 0.00000001)) days++; // Floating Point fix.
        }

        return days;
    }

    static getTotalDayHours(timesheet: StoreTimesheet, day: Day): number {
        const entries = [];
        for (const timesheetCode of timesheet.timesheetCodes) {
            const entry = timesheetCode.entries.find(entry => entry.date.indexOf(day.dateString) === 0);
            if (entry) entries.push(entry);
        }

        return entries.reduce((acc: number, cur: StoreTimesheetEntry) => acc + cur.totalHours, 0);
    }

    static sortTsCodes(tsCodes: StoreTsCodes): StoreTsCodes {
        return tsCodes.sort((a: StoreTsCode, b: StoreTsCode) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
            if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;

            return 0;
        });
    }

    static orderTsCodesByLastUsed(timesheetCodes: StoreTimesheetCode[]): StoreTimesheetCode[]{
        timesheetCodes = timesheetCodes.slice();
        return timesheetCodes.sort((t1: StoreTimesheetCode, t2: StoreTimesheetCode) => {
            if ((t1.isVacationOrIllness || t1.isHoliday) && !(t2.isVacationOrIllness || t2.isHoliday)) return 1;
            if (!(t1.isVacationOrIllness || t1.isHoliday) && (t2.isVacationOrIllness || t2.isHoliday)) return -1;
            if ((t1.isVacationOrIllness || t1.isHoliday) && (t2.isVacationOrIllness || t2.isHoliday)) {
                if (t1.name.toLowerCase() < t2.name.toLowerCase()) return -1;
                if (t1.name.toLowerCase() > t2.name.toLowerCase()) return 1;
            }

            if (t1.isReadOnly && !t2.isReadOnly) return 1;
            if (t2.isReadOnly && !t1.isReadOnly) return -1;

            const maxModDatet1 = new Date(Math.max.apply(null, t1.entries.slice().map(t => new Date(t.modDate)) as unknown as number[]));
            const maxModDatet2 = new Date(Math.max.apply(null, t2.entries.slice().map(t => new Date(t.modDate)) as unknown as number[]));

            const date1 = DateHelper.isInvalidDate(maxModDatet1) ? new Date(1900, 1, 1) : maxModDatet1;
            const date2 = DateHelper.isInvalidDate(maxModDatet2) ? new Date(1900, 1, 1) : maxModDatet2;

            if (date1 < date2) return 1;
            if (date1 > date2) return -1;

            return 0;
        });
    }

    static orderTsCodesByAlphabetical(timesheetCodes: StoreTimesheetCode[]): StoreTimesheetCode[]{
        timesheetCodes = timesheetCodes.slice();
        return timesheetCodes.sort((t1: StoreTimesheetCode, t2: StoreTimesheetCode) => {
            if ((t1.isVacationOrIllness || t1.isHoliday) && !(t2.isVacationOrIllness || t2.isHoliday)) return 1;
            if (!(t1.isVacationOrIllness || t1.isHoliday) && (t2.isVacationOrIllness || t2.isHoliday)) return -1;

            if (t1.isReadOnly && !t2.isReadOnly) return 1;
            if (t2.isReadOnly && !t1.isReadOnly) return -1;

            if (t1.name.toLowerCase() < t2.name.toLowerCase()) return -1;
            if (t1.name.toLowerCase() > t2.name.toLowerCase()) return 1;

            return 0;
        });
    }
}

export default TimesheetHelper;
