import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';
import {InitialState} from './initialState';
import {StorePreset} from '../typescript/storeTypes';

export default function (state: StorePreset = InitialState.userPresets, action: Action): StorePreset {
    switch (action.type) {
        case ActionTypes.LOAD_PRESET_REQUESTED: {
            return Object.assign({}, state, {
                activePreset: action.payload
            });
        }
        case ActionTypes.GET_PRESETS_SUCCEEDED: {
            return Object.assign({}, state, {
                presets: action.payload
            });
        }
        case ActionTypes.GET_PRESETS_FAILED:{
            return Object.assign({}, state, {activePreset: {
                location: '',
                name: '',
                data: undefined
            }, presets: []});
        }
        case ActionTypes.CLEAR_ACTIVE_PRESET_REQUESTED: {
            return Object.assign({}, state, {activePreset: {
                location: '',
                name: '',
                data: undefined
            }});
        }
        default: {
            return state;
        }
    }
}
