import {StoreSupplier} from '../../../../typescript/storeTypes';
import Autosuggest, {InputProps} from 'react-autosuggest';
import ContractHoursToggle from '../../../../common/ContractHoursToggle';
import React from 'react';
import TextInput from '../../../../common/TextInput';

class ExportMonthlyDetailExtraInfo extends React.PureComponent<Props, State> {
    state: State = {
        selectedSupplierValue: '',
        selectedSupplier: null,
        ownProjectLeader: '',
        clientProjectLeader: '',
        emailAddress: '',
        emailAddressErrorLine: '',
        companyError: '',
        hideComments: false,
        supplierFilterValue: ''
    };

    getFilteredSuggestions = (): StoreSupplier[] => {
        return this.props.suppliers.slice().filter(s => s.name.toLocaleLowerCase().includes(this.state.supplierFilterValue.toLocaleLowerCase()));
    };

    onSuggestionsFetchRequested = ({value}: { value: string }) => {
        this.setState({companyError:'', supplierFilterValue: value});
    };
    getSuggestionValue = (supplier: StoreSupplier): any => supplier;
    renderSuggestion = (supplier: StoreSupplier) => <div className="company-data">{supplier.name}</div>;

    onChange = (event: React.SyntheticEvent<HTMLElement>, {newValue}: { newValue: StoreSupplier | string }) => {
        if (newValue instanceof Object) {
            this.setState({selectedSupplierValue: newValue.name, selectedSupplier: newValue});
        } else {
            const target = event.currentTarget as HTMLInputElement;
            this.setState({
                selectedSupplierValue: target.value,
                selectedSupplier: null
            });
        }
    };

    exportSelected = () => {
        if(!this.isValidEmailAddress() || !this.state.selectedSupplier) {
            if(!this.state.emailAddress) this.setState({emailAddressErrorLine: 'please enter an email address'});
            if(!this.state.selectedSupplier) this.setState({companyError: 'please fill in a company'});
            return;
        }

        this.props.exportSelected(this.props.hoursPerWeek, this.state.emailAddress,
            this.state.ownProjectLeader, this.state.clientProjectLeader, this.state.selectedSupplier as unknown as StoreSupplier, this.state.hideComments);
    };

    isValidEmailAddress = (): boolean => {
        const rgx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return rgx.test(this.state.emailAddress);
    };

    render() {
        const inputProps: InputProps<StoreSupplier> = {
            placeholder: 'required',
            value: this.state.selectedSupplierValue as string,
            onChange: (event: React.FormEvent<HTMLElement>, newValue: { newValue: any }) => this.onChange(event, newValue),
            onBlur: () => this.state.selectedSupplier === null ? this.setState({selectedSupplierValue: ''}) : null
        };

        return (
            <div className="export-monthly-extra-info">
                <div className="input-fields">
                    <div className="form-group hours-per-week-group">
                        <label>Hours per week:</label>
                        <ContractHoursToggle />
                    </div>
                    <div className={`form-group company-group ${this.state.companyError ? 'has-error': ''}`}>
                        <label>Company background image:</label>
                        <Autosuggest suggestions={this.getFilteredSuggestions()}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                        getSuggestionValue={this.getSuggestionValue}
                                        renderSuggestion={this.renderSuggestion}
                                        inputProps={inputProps}/>
                        {this.state.companyError && <div className="alert alert-danger">{this.state.companyError}</div>}
                    </div>
                    <TextInput  name="emailAddress"
                                label="Email address to send to"
                                placeholder="someone@cronos.be"
                                required
                                error={this.state.emailAddressErrorLine}
                                onChange={(event: React.SyntheticEvent<HTMLInputElement> & { currentTarget: HTMLInputElement }) => this.setState({[event.currentTarget.name]: event.currentTarget.value, emailAddressErrorLine: ''} as any)}/>
                    <TextInput name="ownProjectLeader"
                                label="Project leader"
                                onChange={(event: React.SyntheticEvent<HTMLInputElement> & { currentTarget: HTMLInputElement }) => this.setState({[event.currentTarget.name]: event.currentTarget.value} as any)}/>
                    <TextInput name="clientProjectLeader"
                                label="Client project leader"
                                onChange={(event: React.SyntheticEvent<HTMLInputElement> & { currentTarget: HTMLInputElement }) => this.setState({[event.currentTarget.name]: event.currentTarget.value} as any)}/>
                    <div className="form-group">
                        <label>Hide comments</label>
                        <input type="checkbox" checked={this.state.hideComments} onChange={() => this.setState({hideComments: !this.state.hideComments})}/>
                    </div>
                    <div className="export-monthly-extra-info__required_message">Fields marked with an * are required</div>
                </div>
                <div className="export-container">
                    <input className="export-button"
                            type="submit" value="EXPORT"
                            onClick={this.exportSelected}/>
                </div>
            </div>
        );
    }
}
type State = {
    selectedSupplierValue: string | null,
    selectedSupplier: StoreSupplier | null,
    ownProjectLeader: string,
    clientProjectLeader: string,
    emailAddress: string,
    emailAddressErrorLine: string,
    companyError: string,
    hideComments: boolean,
    supplierFilterValue: string
};

type Props = {
    suppliers: StoreSupplier[],
    hoursPerWeek: number,

    exportSelected: (hoursPerWeek: number, emailAddress: string, ownProjectLeader: string, clientProjectLeader: string, selectedSupplier: StoreSupplier, hideComments: boolean) => void,
    closeModal: () => void
};

export default ExportMonthlyDetailExtraInfo;
