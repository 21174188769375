import DateHelper from '../helpers/dateHelper';

import {StoreState} from '../typescript/storeTypes';

export const InitialState: StoreState = {
    user: {
        authenticatedUser: {
            token: '',
            username: '',
            isEmployee: false,
            hasActivePayroll: false,
            expiresOn: '',
            scheme: ''
        },
        impersonatedUser: {
            id: 0,
            employeeId: 0,
            firstName: '',
            lastName: '',
            nickName: '',
            username: '',
            employer: '',
            employerId: -1,
            useSigningHub: false,
            _links: []
        },
        authenticationError: '',
        employee: {
            id: 0,
            employeeId: 0,
            firstName: '',
            lastName: '',
            nickName: '',
            username: '',
            employer: '',
            employerId: -1,
            useSigningHub: false,
            _links: []
        },
        permissions: [],
        visibleUsers: [],
        visibleSuppliers: [],
        invoiceViewerSuppliers: [],
        userGroups: []
    },
    timesheet: {
        period: DateHelper.getPeriod(DateHelper.firstDateOfMonth(new Date()), DateHelper.lastDateOfMonth(new Date())),
        timesheetCodes: [],
        incompleteMonths: [],
        signingHubOverview: []
    },
    settings: {
        hideWeekends: false,
        monthViewSelected: true,
        showCommentTooltips: false,
        firstNameLastName: false,
        defaultContractHours: 38,
        defaultOrderBy: 'tscode',
        defaultCompanyForTemplate: null,
        defaultCompanyTemplateProject: null,
        timesheetCodeOrder: true,
        lastSeenReleaseNotes: 0
    },
    timesheetsFromClient: {},
    assignedTimesheetCodes: [],
    tsCodes: [],
    mostRecentTsCodes: [],
    suppliers: {
        suppliers: [],
        allSuppliers: []
    },
    calls: {
        timesheetCompletionCalled: false,
        loginRequested: false,
        completeTimesheetOpen: false,
        exportMonthlyOpen: false,
        changeUserOpen: false,
        profileOpen: false,
        settingsOpen: false,
        reportRequested: false,
        validateActualsRequested: false,
        processImportActualsRequested: false,
        invoiceRequested: false,
        invoiceNumbersRequested: false
    },
    maintenanceDetails: {
        isInMaintenanceMode: false,
        isLatestVersion: true,
        maintenanceScheduleStartTime: new Date('1970-01-01T00:00:00.0000000Z'),
        maintenanceScheduleEndTime: new Date('1970-01-01T00:00:00.0000000Z')
    },
    validatedActuals: {
        actuals: [],
        errorMessage: ''
    },
    releaseNotes: {
        application: '',
        notes: '',
        versionNumber: ''
    },
    invoiceDetails: {
        invoice: {
            fileName: '',
            blob: new Blob(),
            blobAsArray: new ArrayBuffer(0)
        },
        sentInvoices: [],
        invoiceNumbers: []
    },
    reporting: {
        supplierEmployees: [],
        timeLeftOnOrder: {
            tsCodeName: '',
            consultants: '',
            icPartner: '',
            orderNumber: '',
            orderLineNumber: '',
            actualHoursOnOrder: 0,
            extraHoursOnOrder: 0,
            standbyHoursOnOrder: 0,
            interventionHoursOnOrder: 0,
            daysOnOrder: 0,
            hoursForCalculation: 0,
            isHourPrice: false,
            actualPrice: 0,
            currency: ''
        },
        icPartnersOnTsCode: []
    },
    userPresets: {
        activePreset: {
            location: '',
            name: '',
            data: undefined
        },
        presets: []
    },
    documents:{},
    projects: {
        project: {
            id: 0,
            timesheetCodes: [],
            name: '',
            yourReference: '',
            ourReference: '',
            contractReference: '',
            internalProjectManager: {
                id: 0,
                firstName: '',
                employer: '',
                lastName: '',
                username: '',
                nickName: '',
                groups: []
            },
            externalProjectManager: '',
            clientName: '',
            isOld: false
        },
        projects: []
    },
    group: {
        groups: []
    }
};
