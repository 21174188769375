import {SuppliersEmployeesType} from '../typescript/supplierTypes';
import AuthorizedRequest from './authorizedRequests';
import ErrorHelper from '../helpers/errorHelper';

class SupplierApi {
    static getSuppliers(search: string, page = 1) {
        if (!search) return new Promise(() => { return; }).then(() => []);

        return AuthorizedRequest.getAuthorizedRequest(`supplier/search?page=${page}&name=${encodeURIComponent(search.padEnd(2, '%'))}`)
            .ok(res => res.status === 200)
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static getAllSuppliers() {
        return AuthorizedRequest.getAuthorizedRequest('supplier/suppliers')
            .ok(res => res.status === 200)
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static getSuppliersEmployees(data: SuppliersEmployeesType) {
        return AuthorizedRequest.getAuthorizedRequest(`supplier/${data.supplierId}/employees/${data.month}/${data.year}`)
            .ok(res => res.status === 200)
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static getInvoiceNumbers(supplierId: number, page = 1) {
        return AuthorizedRequest.getAuthorizedRequest(`supplier/${supplierId}/invoicenumbers?page=${page}`)
            .ok(res => res.status === 200)
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }
}

export default SupplierApi;
