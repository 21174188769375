// eslint-disable-next-line import/no-unused-modules
export type Procedure = (...args: any[]) => void;

class ApiHelper {
    static debounce<F extends Procedure>(func: F, wait: number, immediate = false):
        (this: ThisParameterType<F>, ...args: Parameters<F>) => void {

        let timeoutId: ReturnType<typeof setTimeout> | undefined;

        return function(this: ThisParameterType<F>, ...args: Parameters<F>) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const context = this;

            const later = function() {
                timeoutId = undefined;
                if (!immediate) {
                    func.apply(context, args);
                }
            };

            const callNow = immediate && timeoutId === undefined;

            if (timeoutId !== undefined) {
                clearTimeout(timeoutId);
            }

            timeoutId = setTimeout(later, wait);

            if (callNow) {
                func.apply(context, args);
            }
        };
    }
}

export default ApiHelper;
