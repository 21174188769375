import Modal from '../../../../common/Modal';
import React from 'react';
import TextInput from '../../../../common/TextInput';

import EmailHelper from '../../../../helpers/emailHelper';
import MessageHelper from '../../../../helpers/messageHelper';

import {KeyCodes} from '../../../../types/keyCodeTypes';
import {LocationTypes} from '../../../../types/locationTypes';
import {MessageTypes} from '../../../../types/messageTypes';

import {MappedUserCredentials, UserCredentials} from '../../../../typescript/userTypes';

class LoginModal extends React.PureComponent<Props, State> {
    popupId : string|number = 9999;
    state = {
        credentialsLogin: !!process.env.USECREDENTIALLOGIN,
    };

    openLocalLogin = () => this.setState({credentialsLogin: true});
    closeLocalLogin = () => this.setState({credentialsLogin: false});
    openStsLogin = (cronosEmailAddress: string) => window.location.assign(LocationTypes.STS_MOBILE && LocationTypes.STS_MOBILE.replace('{{USERNAME}}', cronosEmailAddress));

    loginOnEnter = (event: React.SyntheticEvent<HTMLInputElement> & {keyCode: number}): void => {
        if(event.keyCode === KeyCodes.ENTER) {
            this.login(this.props.credentials.username);
        }
    };

    login = (email: string): void => {
        email = EmailHelper.addCronosEmailIfNeeded(email).toLocaleLowerCase();

        if(!EmailHelper.isValidEmailAddress(email)) {
            this.popupId = MessageHelper.showMessage({type: MessageTypes.INFO, message: 'Invalid email address'}, this.popupId);
            return;
        }

        if(EmailHelper.isCronosEmailAddress(email)) {
            this.openStsLogin(email);
        } else {
            this.openLocalLogin();
        }
    };

    render() {
        return (
            <Modal>
                { this.state.credentialsLogin ?
                <form>
                    {!!this.props.authenticationError &&
                    <div className="form-group has-error"><label>{this.props.authenticationError}</label></div>}
                    <div className="back-close-icon">
                            <i className="fas fa-times" aria-hidden="true"
                               onClick={this.closeLocalLogin}/>
                    </div>
                    <TextInput id="usernameInput"
                               name="username"
                               label="Username"
                               labelExtra={(<div className="not-you" onClick={this.closeLocalLogin}>(Not you?)</div>)}
                               value={this.props.credentials.username}
                               onChange={this.props.onChange}
                               error={this.props.credentialsErrors.username}
                               autoFocus={this.props.credentials.username ? false : true}
                               disabled={process.env.USECREDENTIALLOGIN === undefined}
                               />
                    <TextInput id="passwordInput"
                               name="password"
                               label="Password"
                               value={this.props.credentials.password}
                               onChange={this.props.onChange}
                               error={this.props.credentialsErrors.password}
                               password
                               autoFocus={this.props.credentials.username ? true : false}/>
                    <div className="login-modal-options">
                        <div className="forgot-password">
                            <div className="forgot-password-text" onClick={() => this.props.resetPassword(this.props.credentials.username)}>Forgot Password?</div>
                        </div>
                        <div className="clear-fix">
                            <input type="submit" value="Submit" onClick={this.props.onSubmit}/>
                        </div>
                    </div>
                </form> :
                <div className="login-selection">
                    <div className="select-block">
                        <div className="logo fas fa-user-tie"/>
                        <div className="title">Sign in</div>
                        <TextInput id="usernameInput"
                            name="username"
                            label={undefined}
                            placeholder="cronos username or email"
                            value={this.props.credentials.username}
                            onChange={this.props.onChange}
                            error={this.props.credentialsErrors.username}
                            onKeyUp={this.loginOnEnter}
                            autoFocus/>
                        <input className="login-selection-button" type="submit" value="Next" onClick={() => this.login(this.props.credentials.username)}/>
                    </div>
                </div> }
            </Modal>
        );
    }
}

type Props = {
    credentials: UserCredentials,
    credentialsErrors: MappedUserCredentials,
    authenticationError: string,

    onChange: (event: React.SyntheticEvent<HTMLInputElement> & { currentTarget: HTMLInputElement }) => void,
    onSubmit: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void,
    updateSetting: (name: string, value: unknown) => void,
    resetPassword: (username: string) => void
};

type State = {
    credentialsLogin: boolean
};

export default LoginModal;
