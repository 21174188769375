import ExportMonthlyDetailExtraInfo from './ExportMonthlyDetailExtraInfo';
import ExportMonthlyDetailList from './ExportMonthlyDetailList';
import React from 'react';
import TimesheetReturn from './TimesheetReturn';

import {StoreSupplier, StoreSuppliers, StoreTimesheetCode} from '../../../../typescript/storeTypes';

class ExportMonthlyDetail extends React.Component<Props, State> {
    state: State = {
        selectedTimesheetCodes: [] as StoreTimesheetCode[],
        selectAllChecked: false,
        detailsModalOpen: false,
    };

    onSelectAll = () => {
        const selectedTimesheetCodes = [];
        if (!this.state.selectAllChecked) {
            for (let i = 0, len = this.props.timesheetCodes.length; i < len; i++) {
                selectedTimesheetCodes.push(this.props.timesheetCodes[i]);
            }
            this.setState({selectAllChecked: true});
        }
        else {
            this.setState({selectAllChecked: false});
        }

        this.setState({selectedTimesheetCodes: selectedTimesheetCodes});
    };

    onTimesheetCodeClick = (timesheetCode: StoreTimesheetCode) => {
        if (this.state.selectedTimesheetCodes.find(tsCode => tsCode.id === timesheetCode.id)) {
            const index = this.state.selectedTimesheetCodes.indexOf(timesheetCode);
            this.state.selectedTimesheetCodes.splice(index, 1);
        } else {
            this.state.selectedTimesheetCodes.push(timesheetCode);
        }
        this.setState({
            selectedTimesheetCodes: this.state.selectedTimesheetCodes,
            selectAllChecked: this.state.selectedTimesheetCodes.length === this.props.timesheetCodes.length
        });
    };

    exportSelected = (hoursPerWeek: number, emailAddress: string, ownProjectLeader: string,
        clientProjectLeader: string, selectedSupplier: StoreSupplier, hideComments: boolean): void => {

        this.props.exportMonthlyTimesheet(hoursPerWeek, emailAddress, ownProjectLeader,
            clientProjectLeader, selectedSupplier, this.state.selectedTimesheetCodes, hideComments);

        this.closeDetailExtraInfo();
        this.props.closeModal();
    };

    openDetailExtraInfo = (): void => {
        this.setState({detailsModalOpen: true});
    };

    closeDetailExtraInfo = (): void => {
        this.setState({detailsModalOpen: false});
    };

    render () {
        return (
            <div className="export-monthly-detail">
                <TimesheetReturn title={this.state.detailsModalOpen ? 'Export Timesheetcode(s)' : 'Select timesheet code(s) to export'}
                                 onCloseDetailView={this.state.detailsModalOpen ? this.closeDetailExtraInfo : this.props.closeModal} />
                {this.state.detailsModalOpen ?
                <ExportMonthlyDetailExtraInfo closeModal={this.closeDetailExtraInfo}
                                              suppliers={this.props.suppliers.allSuppliers}
                                              exportSelected={this.exportSelected}
                                              hoursPerWeek={this.props.hoursPerWeek} /> :
                <ExportMonthlyDetailList timesheetCodes={this.props.timesheetCodes}
                                         onTimesheetCodeClick={this.onTimesheetCodeClick}
                                         selectedTimesheetCodes={this.state.selectedTimesheetCodes}
                                         selectAllChecked={this.state.selectAllChecked}
                                         onSelectAll={this.onSelectAll}
                                         openDetailExtraInfo={this.openDetailExtraInfo}/>
                }
            </div>
        );
    }
}

type State = {
    selectedTimesheetCodes: StoreTimesheetCode[],
    selectAllChecked: boolean,
    detailsModalOpen: boolean
};

type Props = {
    timesheetCodes: StoreTimesheetCode[],
    suppliers: StoreSuppliers,
    hoursPerWeek: number,

    closeModal: () => void,
    exportMonthlyTimesheet: (hoursPerWeek: number, emailAddress: string,
    ownProjectLeader: string, clientProjectLeader: string,
    selectedSupplier: StoreSupplier, selectedTimesheetCodes: StoreTimesheetCode[], hideComments: boolean) => void
};

export default ExportMonthlyDetail;
