import React from 'react';
import resizeHandlerPolyfill from 'resize-polyfill';

class TextAreaInput extends React.PureComponent<Props> {
    static defaultProps = {
        height: '90px',
        isReadOnly: false,
        autoFocus: false,
        error: undefined
    };

    componentDidMount() {
        const el = document.getElementsByClassName('resize');
        resizeHandlerPolyfill(el);
    }

    render() {
        const wrapperClass = 'form-group' + (this.props.error ? ' has-error' : '');
        return (
            <div className={wrapperClass}>
                {this.props.label &&
                <label htmlFor={this.props.name} className={this.props.labelExtra && 'has-extra'}>
                    {this.props.label}
                    {this.props.labelExtra && <span> {this.props.labelExtra}</span>}
                    {!this.props.labelExtra && ':'}
                </label>}
                <div>
                    <textarea
                        style={{height: this.props.height}}
                        name={this.props.name} id={this.props.name}
                        className="form-control resize"
                        placeholder={this.props.placeholder}
                        value={this.props.value === undefined ? '' : this.props.value}
                        onChange={this.props.onChange}
                        autoFocus={this.props.autoFocus}
                        disabled={this.props.isReadOnly}/>
                </div>
                {this.props.error && <div className="alert alert-danger">{this.props.error}</div>}
            </div>
        );
    }
}

type Props = {
    name: string,
    label?: string,
    labelExtra?: string,
    height: string,
    value: string | undefined,
    placeholder?: string,
    error?: string,
    autoFocus?: boolean,
    isReadOnly: boolean,

    onChange: (event: React.ChangeEvent<HTMLTextAreaElement> & { currentTarget: HTMLInputElement }) => void
};

export default TextAreaInput;
