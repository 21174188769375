import {ActionTypes} from '../types/actionTypes';
import {call, put, takeEvery} from 'redux-saga/effects';

import {Action} from '../typescript/actionTypes';
import {Message} from '../typescript/messageTypes';
import {MessageTypes} from '../types/messageTypes';
import MessageHelper from '../helpers/messageHelper';
import reportApi from '../api/reportApi';

function* exportActualsCsv(action: Action): Generator<any, any, any> {
    try {
        const csv = yield call(reportApi.exportActualsCsv, action.payload);
        yield put({type: ActionTypes.EXPORT_ACTUALS_CSV_SUCCEEDED, payload: csv});
    } catch (e) {
        const msg = showToastOnNoContent(e);
        if (msg !== null) MessageHelper.showMessage(msg);

        yield put({type: ActionTypes.EXPORT_ACTUALS_CSV_FAILED, payload: e});
    }
}

function* exportActualsPdf(action: Action): Generator<any, any, any> {
    try {
        const pdf = yield call(reportApi.exportActualsPdf, action.payload);
        yield put({type: ActionTypes.EXPORT_ACTUALS_PDF_SUCCEEDED, payload: pdf});
    } catch (e) {
        const msg = showToastOnNoContent(e);
        if (msg !== null) MessageHelper.showMessage(msg);

        yield put({type: ActionTypes.EXPORT_ACTUALS_PDF_FAILED, payload: e});
    }
}

function* exportActualsExcel(action: Action): Generator<any, any, any> {
    try {
        const excel = yield call(reportApi.exportActualsExcel, action.payload);
        yield put({type: ActionTypes.EXPORT_ACTUALS_EXCEL_SUCCEEDED, payload: excel});
    } catch (e) {
        const msg = showToastOnNoContent(e);
        if (msg !== null) MessageHelper.showMessage(msg);

        yield put({type: ActionTypes.EXPORT_ACTUALS_EXCEL_FAILED, payload: e});
    }
}

function* exportTimesheetsPdf(action: Action): Generator<any, any, any> {
    try {
        const pdf = yield call(reportApi.exportTimesheetsPdf, action.payload);
        yield put({type: ActionTypes.EXPORT_TIMESHEETS_PDF_SUCCEEDED, payload: pdf});
    } catch (e) {
        const msg = showToastOnNoContent(e);
        if (msg !== null) MessageHelper.showMessage(msg);

        yield put({type: ActionTypes.EXPORT_TIMESHEETS_PDF_FAILED, payload: e});
    }
}

function* exportTimesheetsLandscapePdf(action: Action): Generator<any, any, any> {
    try {
        const pdf = yield call(reportApi.exportTimesheetsLandscapePdf, action.payload);
        yield put({type: ActionTypes.EXPORT_TIMESHEETS_LANDSCAPE_PDF_SUCCEEDED, payload: pdf});
    } catch (e) {
        const msg = showToastOnNoContent(e);
        if (msg !== null) MessageHelper.showMessage(msg);

        yield put({type: ActionTypes.EXPORT_TIMESHEETS_LANDSCAPE_PDF_FAILED, payload: e});
    }
}

function* exportTimesheetsWeeklyPdf(action: Action): Generator<any, any, any> {
    try {
        const pdf = yield call(reportApi.exportTimesheetsWeeklyPdf, action.payload);
        yield put({type: ActionTypes.EXPORT_TIMESHEETS_WEEKLY_PDF_SUCCEEDED, payload: pdf});
    } catch (e) {
        const msg = showToastOnNoContent(e);
        if (msg !== null) MessageHelper.showMessage(msg);

        yield put({type: ActionTypes.EXPORT_TIMESHEETS_WEEKLY_PDF_FAILED, payload: e});
    }
}

function* exportTimesheetsWeeklyExcel(action: Action): Generator<any, any, any> {
    try {
        const pdf = yield call(reportApi.exportTimesheetsWeeklyExcel, action.payload);
        yield put({type: ActionTypes.EXPORT_TIMESHEETS_WEEKLY_EXCEL_SUCCEEDED, payload: pdf});
    } catch (e) {
        const msg = showToastOnNoContent(e);
        if (msg !== null) MessageHelper.showMessage(msg);

        yield put({type: ActionTypes.EXPORT_TIMESHEETS_WEEKLY_EXCEL_FAILED, payload: e});
    }
}

function* exportTimesheetsLandscapeExcel(action: Action): Generator<any, any, any> {
    try {
        const pdf = yield call(reportApi.exportTimesheetsLandscapeExcel, action.payload);
        yield put({type: ActionTypes.EXPORT_TIMESHEETS_LANDSCAPE_EXCEL_SUCCEEDED, payload: pdf});
    } catch (e) {
        const msg = showToastOnNoContent(e);
        if (msg !== null) MessageHelper.showMessage(msg);

        yield put({type: ActionTypes.EXPORT_TIMESHEETS_LANDSCAPE_EXCEL_FAILED, payload: e});
    }
}

function* postGetTimeLeftOnOrder(action: Action): Generator<any, any, any> {
    try {
        const result = yield call(reportApi.getTimeLeftOnOrder, action.payload);
        yield put({type: ActionTypes.POST_GET_TIME_LEFT_ON_ORDER_SUCCEEDED, payload: result});
    } catch (e) {
        const msg = showToastOnNoContent(e);
        if (msg !== null) MessageHelper.showMessage(msg);

        yield put({type: ActionTypes.POST_GET_TIME_LEFT_ON_ORDER_FAILED, payload: e});
    }
}

function* clearTimeLeftOnOrder(): Generator<any, any, any> {
    yield put({type: ActionTypes.CLEAR_TIME_LEFT_ON_ORDER_SUCCEEDED, payload: null});
}

const showToastOnNoContent = (e: Error): Message|null => {
    const theSplit = e.message.split('|');
    if(theSplit[0] === '204' || theSplit[0] === '403') {
        const msg: Message = {
            type: MessageTypes.INFO,
            message: theSplit[1]
        };

        return msg;
    }

    return null;
};

function* reportSaga(): Generator<any, any, any> {
    yield takeEvery(ActionTypes.EXPORT_ACTUALS_CSV_REQUESTED, exportActualsCsv);
    yield takeEvery(ActionTypes.EXPORT_ACTUALS_EXCEL_REQUESTED, exportActualsExcel);
    yield takeEvery(ActionTypes.EXPORT_ACTUALS_PDF_REQUESTED, exportActualsPdf);
    yield takeEvery(ActionTypes.EXPORT_TIMESHEETS_PDF_REQUESTED, exportTimesheetsPdf);
    yield takeEvery(ActionTypes.EXPORT_TIMESHEETS_LANDSCAPE_PDF_REQUESTED, exportTimesheetsLandscapePdf);
    yield takeEvery(ActionTypes.EXPORT_TIMESHEETS_LANDSCAPE_EXCEL_REQUESTED, exportTimesheetsLandscapeExcel);
    yield takeEvery(ActionTypes.EXPORT_TIMESHEETS_WEEKLY_PDF_REQUESTED, exportTimesheetsWeeklyPdf);
    yield takeEvery(ActionTypes.EXPORT_TIMESHEETS_WEEKLY_EXCEL_REQUESTED, exportTimesheetsWeeklyExcel);
    yield takeEvery(ActionTypes.POST_GET_TIME_LEFT_ON_ORDER_REQUESTED, postGetTimeLeftOnOrder);
    yield takeEvery(ActionTypes.CLEAR_TIME_LEFT_ON_ORDER_REQUESTED, clearTimeLeftOnOrder);
}

export default reportSaga;
