import ExportMonthly from './exportMonthly/ExportMonthly';
import Identity from './identity/Identity';
import Profile from './profile/Profile';
import React from 'react';
import TimesheetComplete from './timesheetComplete/TimesheetComplete';

class Header extends React.Component {
    render() {
        return (
            <div className="header">
                <Identity/>
                <ExportMonthly/>
                <TimesheetComplete/>
                <Profile/>
            </div>
        );
    }
}

export default Header;
