import React from 'react';
import TimesheetWeekDay from './TimesheetWeekDay';

class TimesheetWeek extends React.PureComponent {
    render() {
        return (
            <div className="timesheet-week">
                <TimesheetWeekDay weekDay="mon"/>
                <TimesheetWeekDay weekDay="tue"/>
                <TimesheetWeekDay weekDay="wed"/>
                <TimesheetWeekDay weekDay="thu"/>
                <TimesheetWeekDay weekDay="fri"/>
                <TimesheetWeekDay weekDay="sat"/>
                <TimesheetWeekDay weekDay="sun"/>
            </div>
        );
    }
}

export default TimesheetWeek;
