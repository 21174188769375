import {ConnectedProps, connect} from 'react-redux';
import {Day} from '../../../../typescript/dateTypes';
import {getAddableTimesheetCodes} from '../../../selectors/timesheetSelectors';
import React from 'react';
import TimesheetHelper from '../../../../helpers/timesheetHelper';

import {StoreState, StoreTimesheetCode, StoreTimesheetEntry} from '../../../../typescript/storeTypes';

export class TimesheetEntryOptions extends React.Component<Props & MappedProps, State> {
    static defaultTimesheetCode = {
        id: 'select-a-timesheet-code',
        name: 'Select a timesheet code'
    };

    state: State = {
        expanded: false,
        selectedTimesheetCode: TimesheetEntryOptions.defaultTimesheetCode
    };

    onChange = (event: React.MouseEvent<HTMLElement> & { target: any }): void => {
        const selectedTimesheetCode = this.props.timesheetCodes.find(timesheetCode => timesheetCode.id.toString() === event.target.id);
        this.props.onDetailView(TimesheetHelper.defaultEntry, selectedTimesheetCode);

        this.setState({
            selectedTimesheetCode: {
                id: event.target.id,
                name: event.target.innerHTML
            }
        });
        this.setState({expanded: false});
    };

    onExpand = (): void => {
        this.setState({expanded: true});
    };

    onCollapse = (event: React.MouseEvent<HTMLElement> & { target: HTMLElement }): void => {
        if (this.state.expanded && event.target.className !== 'option') {
            this.setState({expanded: false});
        }
    };

    render() {
        return (
            <div className="timesheet-entry-options form-group">
                {this.state.expanded ?
                    <div className="options expanded" onClick={this.onChange}>
                        <div id={TimesheetEntryOptions.defaultTimesheetCode.id}
                             className="option">{TimesheetEntryOptions.defaultTimesheetCode.name}</div>
                        {this.props.timesheetCodes.filter(tsc => tsc.isTsCodeActive).map(timesheetCode =>
                            <div key={timesheetCode.id}
                                 id={`${timesheetCode.id}`}
                                 className="option">{timesheetCode.name}</div>
                        )}
                    </div> :
                    <div className="options" onClick={this.onExpand}>
                        <div id={this.state.selectedTimesheetCode.id}
                             className="option">{this.state.selectedTimesheetCode.name}</div>
                        <i className="fas fa-caret-down"/>
                    </div>}
            </div>
        );
    }

    componentDidMount() {
        document.body.addEventListener('click', this.onCollapse as any);
    }

    componentWillUnmount() {
        document.body.removeEventListener('click', this.onCollapse as any);
    }
}

const connector = connect(mapStateToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = {
    day: Day,

    onDetailView: (entry: StoreTimesheetEntry, timesheetCode?: StoreTimesheetCode) => void
};

type State = {
    expanded: boolean,
    selectedTimesheetCode: {
        id: string,
        name: string
    }
};

function mapStateToProps(state: StoreState, props: Props) {
    return {
        timesheetCodes: getAddableTimesheetCodes(state, props)
    };
}

export default connector(TimesheetEntryOptions);
