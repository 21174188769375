import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';
import {InitialState} from './initialState';
import {StoreSettings} from '../typescript/storeTypes';

export default function (state: StoreSettings = InitialState.settings, action: Action): StoreSettings {
    switch (action.type) {
        case ActionTypes.UPDATE_SETTING: {
            return Object.assign({}, state, action.payload);
        }
        default: {
            return state;
        }
    }
}
