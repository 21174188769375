import React from 'react';
import TimesheetDay from './TimesheetDay';
import TimesheetHelper from '../../../../helpers/timesheetHelper';

import {StoreTimesheet} from '../../../../typescript/storeTypes';

class TimesheetDays extends React.PureComponent<Props> {
    getSkippedDays = (): React.ReactElement[] => {
        const skippedDays = [];
        const daysToSkip = this.props.timesheet.period.days[0].dayOfWeek ? this.props.timesheet.period.days[0].dayOfWeek - 1 : 6;

        for (let i = 0; i < daysToSkip; i++) {
            skippedDays.push(<div key={i} className="timesheet-day"/>);
        }

        return skippedDays;
    };

    render() {
        return (
            <div className="timesheet-days">
                {this.getSkippedDays()}
                {this.props.timesheet.period.days.map((day, index) =>
                    <TimesheetDay key={day.dateString}
                                  day={day}
                                  hasEntries={!!TimesheetHelper.getEntries(this.props.timesheet.timesheetCodes, day).length}
                                  isActive={index === this.props.activeDayIndex}
                                  dayIndex={index}
                                  onNavigateDay={this.props.onNavigateDay}/>)}
            </div>
        );
    }
}

type Props = {
    timesheet: StoreTimesheet,
    activeDayIndex: number,

    onNavigateDay: (dayIndex: number) => void
};

export default TimesheetDays;
