import React from 'react';

import { KeyCodes } from '../types/keyCodeTypes';

class DefaultSubmit extends React.PureComponent<Props> {
    static defaultProps = {
        disabled: false
    };

    componentDidMount() {
        document.body.addEventListener('keyup', this.keyUp);
    }

    componentWillUnmount() {
        document.body.removeEventListener('keyup', this.keyUp);
    }

    keyUp = (event: KeyboardEvent): void => {
        if (event.keyCode === KeyCodes.ENTER) {
            if(!this.props.disabled)
            {
                event.preventDefault();
                this.props.onSubmit();
            }
        }
    };

    render() {
        return (
            <input type="submit"
                className={this.props.className}
                disabled={this.props.disabled}
                value={this.props.value}
                onClick={this.props.onSubmit} />
        );
    }
}

type Props = {
    value: string,
    disabled: boolean,
    className: string,
    onSubmit: () => void
};

export default DefaultSubmit;
