import {MessageTypes} from '../types/messageTypes';

import {IsDuplicateSigningHubQueue, SigningHubObject} from '../typescript/signingHubTypes';

import AuthorizedRequest from './authorizedRequests';
import ErrorHelper from '../helpers/errorHelper';
import MessageHelper from '../helpers/messageHelper';

class SigningHubApi {
    static sendMonthlyToSigningHub(monthlyTimesheetToSigningHub: SigningHubObject) {
        return AuthorizedRequest.postAuthorizedRequest('digitalsigning/monthly', monthlyTimesheetToSigningHub)
            .ok(res => res.status === 201)
            .then(response => response.body)
            .catch(e => {
                MessageHelper.showMessage({type: MessageTypes.ERROR, message: e.response.statusText});
                throw ErrorHelper.getCustomWebsiteError(e);
            });
    }

    static sendMonthlyToSigningHubImpersonated(payload: any) {
        return AuthorizedRequest.postAuthorizedRequest(`digitalsigning/monthly/${payload.username}` , payload.monthlyTimesheetToSigningHub)
            .ok(res => res.status === 201)
            .then(response => response.body)
            .catch(e => {
                MessageHelper.showMessage({type: MessageTypes.ERROR, message: e.response.statusText});
                throw ErrorHelper.getCustomWebsiteError(e);
            });
    }

    static sendWeeklyToSigningHub(weeklyTimesheetToSigningHub: SigningHubObject) {
        return AuthorizedRequest.postAuthorizedRequest('digitalsigning/weekly', weeklyTimesheetToSigningHub)
            .ok(res => res.status === 201)
            .then(response => response.body)
            .catch(e => {
                MessageHelper.showMessage({type: MessageTypes.ERROR, message: e.response.statusText});
                throw ErrorHelper.getCustomWebsiteError(e);
            });
    }

    static sendWeeklyToSigningHubImpersonated(payload: any) {
        return AuthorizedRequest.postAuthorizedRequest(`digitalsigning/weekly/${payload.username}` , payload.weeklyTimesheetToSigningHub)
            .ok(res => res.status === 201)
            .then(response => response.body)
            .catch(e => {
                MessageHelper.showMessage({type: MessageTypes.ERROR, message: e.response.statusText});
                throw ErrorHelper.getCustomWebsiteError(e);
            });
    }

	static postIsDuplicateSigningHubQueue(isDuplicateSigningHubQueue: IsDuplicateSigningHubQueue) {
        return AuthorizedRequest.postAuthorizedRequest('digitalsigning/isduplicate', isDuplicateSigningHubQueue)
            .ok(res => res.status === 200)
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static postCancelSigningHubQueue(signingHubQueueId: number) {
        return AuthorizedRequest.postAuthorizedRequest(`digitalsigning/${signingHubQueueId}/cancel`, {})
            .ok(res => res.status === 200)
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }
}

export default SigningHubApi;
