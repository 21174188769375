import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';
import {InitialState} from './initialState';
import {StoreTsCodes} from '../typescript/storeTypes';

export default function (state: StoreTsCodes = InitialState.tsCodes, action: Action): StoreTsCodes {
    switch (action.type) {
        case ActionTypes.TSCODES_SUCCEEDED: {
            return (action.payload as StoreTsCodes);
        }
        case ActionTypes.CLEAR_TSCODES_REQUESTED: {
            return InitialState.tsCodes;
        }
        default: {
            return state;
        }
    }
}
