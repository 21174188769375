import DateHelper from '../../../../helpers/dateHelper';
import React from 'react';

import {Period} from '../../../../typescript/dateTypes';
import MonthYearPicker from '../../../../desktop/components/common/MonthYearPicker';


class SwitchMonth extends React.PureComponent<Props> {
    minDate = new Date(2000, 0, 1);
    maxDate = DateHelper.addMonths(new Date(), 12);

    handleAMonthChange = (year: number, month: number) => {
        this.props.getTimesheet(DateHelper.getNormalizedDate(new Date(year, month - 1, 1)));
    };

    render() {
        return (
            <div className="switch-month">
                <MonthYearPicker
                    value={this.props.period.startDate}
                    minDate={this.minDate}
                    maxDate={this.maxDate}
                    onChange={(month: number, year: number) => this.handleAMonthChange(year, month)}
                />
            </div>
        );
    }
}

type Props = {
    period: Period,
    getTimesheet: (startDate: Date) => void
};

export default SwitchMonth;
