import { MessageTypes } from '../types/messageTypes';
import MessageHelper from './messageHelper';

class ErrorHelper {
    static getCustomWebsiteError(error: any): Error {
        if (error && error.response) {
            return new Error(error.status + '|' + error.response.statusText);
        } else {
            return new Error(error.status);
        }
    }

    static getCustomWebsiteErrorFunction() {
        return (error: any) => {
            if (error && error.response) {
                throw new Error(error.status + '|' + error.response.statusText);
            } else {
                return new Error(error.status);
            }
        };
    }

    static showMessageAndRethrow() {
        return (error: any) => {
            if (error && error.response) {
                MessageHelper.showMessage({type: MessageTypes.ERROR, message: error.response.statusText});
                throw new Error(error.status + '|' + error.response.statusText);
            } else {
                return new Error(error.status);
            }
        };
    }
}

export default ErrorHelper;
