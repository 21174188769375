import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';
import {SuppliersEmployeesType} from '../typescript/supplierTypes';


export function getSuppliers(search: string): Action {
    return {type: ActionTypes.SUPPLIERS_REQUESTED, payload: search};
}

export function getAllSuppliers(): Action {
    return {type: ActionTypes.GET_ALL_SUPPLIERS_REQUESTED, payload: null};
}

export function getSuppliersEmployees(params: SuppliersEmployeesType): Action {
    return {type: ActionTypes.SUPPLIERS_EMPLOYEES_REQUESTED, payload: params};
}

export function clearSuppliersEmployees(): Action {
    return {type: ActionTypes.CLEAR_SUPPLIERS_EMPLOYEES_REQUESTED, payload: null};
}

export function clearSuppliers(): Action {
    return {type: ActionTypes.CLEAR_SUPPLIERS_REQUESTED, payload: null};
}

export function getInvoiceNumbers(supplierId: number, page: number): Action {
    return {type: ActionTypes.GET_SUPPLIER_INVOICENUMBERS_REQUESTED, payload: {supplierId: supplierId, page: page}};
}
