import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';
import {InitialState} from './initialState';

import {StoreAssignmentModel} from '../typescript/storeTypes';

export default function (state: StoreAssignmentModel[] = InitialState.assignedTimesheetCodes, action: Action): StoreAssignmentModel[] {
    switch (action.type) {
        case ActionTypes.GET_ASSIGNED_TIMESHEET_CODES_SUCCEEDED: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}
