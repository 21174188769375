import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';
import {InitialState} from './initialState';
import {StoreInvoiceViewerSupplier, StoreUser, StoreVisibleSupplier} from '../typescript/storeTypes';

export default function (state: StoreUser = InitialState.user, action: Action): StoreUser {
    const logoutUser = () => {
        return Object.assign({}, state, {
            authenticatedUser: InitialState.user.authenticatedUser,
            employee: InitialState.user.employee,
            impersonatedUser: InitialState.user.impersonatedUser,
            visibleUsers: InitialState.user.visibleUsers,
            permissions: InitialState.user.permissions,
            visibleSuppliers: InitialState.user.visibleSuppliers,
            invoiceViewerSuppliers: InitialState.user.invoiceViewerSuppliers
        });
    };

    switch (action.type) {
        case ActionTypes.USER_LOGOUT: {
            return logoutUser();
        }
        case ActionTypes.USER_ADFS_LOGIN_SUCCEEDED:
        case ActionTypes.USER_LOGIN_SUCCEEDED: {
            return Object.assign({}, state, {
                authenticatedUser: action.payload,
                authenticationError: ''
            });
        }
        case ActionTypes.USER_ADFS_LOGIN_FAILED:
        case ActionTypes.USER_LOGIN_FAILED: {
           return Object.assign({}, logoutUser(), {authenticationError: action.payload});
        }
        case ActionTypes.USER_SUCCEEDED: {
            return Object.assign({}, state, {
                employee: action.payload,
                impersonatedUser: action.payload
            });
        }
        case ActionTypes.PERMISSIONS_SUCCEEDED: {
            return Object.assign({}, state, {permissions: action.payload});
        }
        case ActionTypes.VISIBLE_USERS_SUCCEEDED: {
            return Object.assign({}, state, {visibleUsers: action.payload});
        }
        case ActionTypes.VISIBLE_SUPPLIERS_SUCCEEDED: {
            (action.payload as StoreVisibleSupplier[]).sort((sup1: StoreVisibleSupplier, sup2: StoreVisibleSupplier) => {
                if(sup1.name.toLowerCase() > sup2.name.toLowerCase()) return 1;
                if(sup1.name.toLowerCase() < sup2.name.toLowerCase()) return -1;
                return 0;
            });

            return Object.assign({}, state, {visibleSuppliers: action.payload});
        }
        case ActionTypes.INVOICEVIEWER_SUPPLIERS_SUCCEEDED: {
            (action.payload as StoreInvoiceViewerSupplier[]).sort((sup1: StoreInvoiceViewerSupplier, sup2: StoreInvoiceViewerSupplier) => {
                if(sup1.name.toLowerCase() > sup2.name.toLowerCase()) return 1;
                if(sup1.name.toLowerCase() < sup2.name.toLowerCase()) return -1;
                return 0;
            });

            return Object.assign({}, state, {invoiceViewerSuppliers: action.payload});
        }

        case ActionTypes.USER_GROUPS_SUCCEEDED: {
            return Object.assign({}, state, {userGroups: action.payload});
        }

        case ActionTypes.CHANGE_USER_REQUESTED: {
            return Object.assign({}, state, {impersonatedUser: action.payload});
        }
        case ActionTypes.TIMESHEET_REQUEST_FAILED:
        case ActionTypes.ADD_ENTRY_FAILED:
        case ActionTypes.DELETE_ENTRY_FAILED:
        case ActionTypes.EXPORT_MONTHLY_TIMESHEET_EXCEL_FAILED:
        case ActionTypes.EXPORT_MONTHLY_TIMESHEET_FAILED:
        case ActionTypes.EXPORT_WEEKLY_TIMESHEET_FAILED:
        case ActionTypes.MOST_RECENT_TSCODES_FAILED:
        case ActionTypes.PERMISSIONS_FAILED:
        case ActionTypes.SUPPLIERS_FAILED:
        case ActionTypes.TIMESHEET_COMPLETE_FAILED:
        case ActionTypes.TIMESHEET_INCOMPLETE_FAILED:
        case ActionTypes.TIMESHEETCODE_COMPLETE_FAILED:
        case ActionTypes.TIMESHEETCODE_DELETE_FAILED:
        case ActionTypes.TIMESHEETCODE_INCOMPLETE_FAILED:
        case ActionTypes.SUPPLIERS_EMPLOYEES_FAILED:
        case ActionTypes.TSCODES_FAILED:
        case ActionTypes.UPDATE_ENTRY_FAILED:
        case ActionTypes.USER_FAILED:
        case ActionTypes.VISIBLE_USERS_FAILED:
        case ActionTypes.VISIBLE_SUPPLIERS_FAILED:
        case ActionTypes.INVOICEVIEWER_SUPPLIERS_FAILED:
        case ActionTypes.ADD_ASSIGNMENT_FAILED:
        case ActionTypes.USER_GROUPS_FAILED: {
            const errorCode = action.payload.toString().split('|')[0];
            if(errorCode === '401' || errorCode === '503') return logoutUser();

            return state;
        }
        default:
            return state;
    }
}
