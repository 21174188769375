import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';

export function getTsCodes(search: string, page = 1, showOldAndReadOnly = false, showAllAliases = false, month: number = (new Date().getMonth() + 1), year: number = new Date().getFullYear()): Action {
    return {type: ActionTypes.TSCODES_REQUESTED, payload: {search: search, page: page, showOldAndReadOnly: showOldAndReadOnly, showAllAliases: showAllAliases, month: month, year: year}};
}

export function getMostRecentTsCodes(username: string, month: number, year: number): Action {
    return {type: ActionTypes.MOST_RECENT_TSCODES_REQUESTED, payload: {username: username, month: month, year: year}};
}

export function getTsCodesIcPartners(tscodeId: string): Action {
    return {type: ActionTypes.GET_TSCODES_ICPARTNERS_REQUESTED, payload: tscodeId};
}

export function clearTsCodes(): Action {
    return {type: ActionTypes.CLEAR_TSCODES_REQUESTED, payload: ''};
}
