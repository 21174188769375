import {Interval} from '../types/enumTypes';
import schedule, {Job} from 'node-schedule';

class CronHelper {
    static createNewJob(intervalInMinutes: Interval, cronFunction: () => void): Job {
        const rule = new schedule.RecurrenceRule();
        const startMinute = new Date().getMinutes();

        rule.minute = new Array(Math.ceil(60 / intervalInMinutes)).fill(0).map((_, i) => (startMinute + i * intervalInMinutes) % 60);

        return schedule.scheduleJob(rule, cronFunction);
    }

    static stopCronJob(cronJob: Job | undefined) {
        if(cronJob !== undefined) {
            cronJob.cancel();
        }
    }
}

export default CronHelper;
