import { Message } from '../typescript/messageTypes';
import {MessageTypes} from '../types/messageTypes';
import { UpdateOptions, toast } from 'react-toastify';
import EmptyTimesheetToast from '../common/EmptyTimesheetToast';
import React from 'react';

class MessageHelper {
    static isActive(toastId: string|number): boolean {
        return toast.isActive(toastId);
    }

    static showMessage(message: Message, id: string|number = 0, autoClose: number|false|undefined = undefined): string|number {
        toast.dismiss(id);

        switch(message.type) {
            case MessageTypes.INFO:
                id = toast.info(message.message, {
                    type: 'info',
                    className: 'toastify info',
                    bodyClassName: 'toastify-body info',
                    autoClose: autoClose,
                    icon: false
                });
                break;
            case MessageTypes.ERROR:
                id = toast.error(message.message, {
                    type: 'error',
                    className: 'toastify error',
                    bodyClassName: 'toastify-body error',
                    autoClose: autoClose,
                    icon: false
                });
                break;
            case MessageTypes.QUESTION_RELEASENOTES:
                id = toast.info(message.message, {
                    type: 'info',
                    className: 'toastify info',
                    bodyClassName: 'toastify-body info',
                    autoClose: autoClose,
                    icon: false
                });
                break;
        }

        return id;
    }

    static showEmptyTimesheetMessage(id: string|number = 0): string|number {
        toast.dismiss(id);

        id = toast.info(<EmptyTimesheetToast />, {
                type: 'info',
                className: 'toastify info',
                bodyClassName: 'toastify-body info',
                icon: false
        });

        return id;
    }

    static dismiss(toastId?: number|string): void {
        toast.dismiss(toastId);
    }

    static update(toastId: string|number, options?: UpdateOptions): void {
        toast.update(toastId, options);
    }
}

export default MessageHelper;
