import AuthorizedRequest from './authorizedRequests';
import ErrorHelper from '../helpers/errorHelper';

class ImportApi {
    static postValidateActuals(actuals: Record<string, any>) {
        return AuthorizedRequest.postAuthorizedRequest('import/actuals', actuals)
            .ok(res => res.status === 200)
            .then(response => {
                return response.body;
            })
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static postProcessImportActuals(importId: string) {
        return AuthorizedRequest.postAuthorizedRequest(`import/actuals/${importId}/process`, {})
            .ok(res => res.status === 200)
            .then(response => {
                return response.body;
            })
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }
}

export default ImportApi;
