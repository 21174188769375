import {AnyAction, Dispatch,bindActionCreators} from 'redux';
import { ConnectedProps, connect } from 'react-redux';
import React from 'react';
import ScrollBar from 'react-custom-scrollbars-2';

import {clearTsCodes, getMostRecentTsCodes, getTsCodes} from '../../../../actions/tsCodeActions';

import ApiHelper from '../../../../helpers/apiHelper';
import DateHelper from '../../../../helpers/dateHelper';
import TextInput from '../../../../common/TextInput';
import TimesheetHelper from '../../../../helpers/timesheetHelper';

import {Period} from '../../../../typescript/dateTypes';
import {StoreState, StoreTsCode, StoreTsCodes } from '../../../../typescript/storeTypes';

export class AddTimesheetCode extends React.PureComponent<Props & MappedProps, State> {
    static defaultState: State = {
        value: '',
        activeTab: 0,
        selectedTsCodes: [],
        mostRecentTsCodes: [],
        selectAllChecked: false
    };
    state = Object.assign(AddTimesheetCode.defaultState, {value: this.props.value});
    getTsCodes: (value: string) => void = ApiHelper.debounce(this.props.getTsCodes, 300);

    onChange = (event: React.SyntheticEvent<HTMLInputElement> & { currentTarget: HTMLInputElement }): void => {
        this.setState({value: event.currentTarget.value});
        this.getTsCodes(event.currentTarget.value);
    };

    onTimesheetCodeClick = (tsCode: StoreTsCode) => {
        const tsCodeIndex = this.state.selectedTsCodes.findIndex(stc => stc.id === tsCode.id);
        const newSelectedTsCodes = this.state.selectedTsCodes.slice();
        if (tsCodeIndex === -1) {
            newSelectedTsCodes.push(tsCode);
        } else {
            newSelectedTsCodes.splice(tsCodeIndex, 1);
        }
        this.setState({
                        selectedTsCodes: newSelectedTsCodes,
                        selectAllChecked: newSelectedTsCodes.length > 0 && newSelectedTsCodes.length === this.state.mostRecentTsCodes.length
                    });
    };

    addSelected = (): void => {
        this.props.addAssignments(this.state.selectedTsCodes);
    };

    getFilteredTimesheetCodes = (): StoreTsCodes => {
        return TimesheetHelper.sortTsCodes(this.getTsCodesToUse()
            .filter(tsc => this.props.timesheetCodes.findIndex(tsc2 => tsc.id === tsc2.id) === -1)
        );
    };

    isTabActive = (tab: number): boolean => this.state.activeTab === tab;

    setTabActive = (tab: number): void => {
        this.setState({activeTab: tab, selectedTsCodes: [], selectAllChecked: false});
        switch (tab) {
            case 0: {
                if (this.props.tsCodes.length > 0 && this.state.mostRecentTsCodes.length === 0) {
                    this.setState({mostRecentTsCodes: this.props.tsCodes});
                }
                this.props.getTsCodes(this.state.value);
                break;
            }
            case 1: {
                if (this.state.mostRecentTsCodes.length === 0) {
                    const month = DateHelper.addMonths(this.props.period.startDate, -1);
                    this.props.getMostRecentTsCodes(this.props.user.impersonatedUser.username,
                        month.getMonth() + 1,
                        month.getFullYear());
                }
                break;
            }
        }
    };

    getTsCodesToUse = (): StoreTsCodes => {
        return (this.state.activeTab === 1 && this.state.mostRecentTsCodes.length > 0 ? this.state.mostRecentTsCodes : this.props.tsCodes);
    };

    onSelectAll = (): void => {
        const selectedTsCodes = [];

        if (!this.state.selectAllChecked) {
            for (let i = 0, len = this.getFilteredTimesheetCodes().length; i < len; i++) {
                selectedTsCodes.push(this.getFilteredTimesheetCodes()[i]);
            }
            this.setState({selectAllChecked: true});
        }
        else {
            this.setState({selectAllChecked: false});
        }
        this.setState({selectedTsCodes: selectedTsCodes});
    };

    componentWillUnmount() {
        this.props.clearTsCodes();
    }

    render() {
        return (
            <div className="add-timesheet-code ">
                <div className="timesheet-code-tab-container">
                    <div className={'timesheet-code-tab' + (this.isTabActive(0) ? ' active' : '')}
                         onClick={() => this.setTabActive(0)}>
                        <div>SEARCH TIMESHEET CODE</div>
                        <div className={'line' + (this.isTabActive(0) ? ' black' : '')}/>
                    </div>
                    <div className={'timesheet-code-tab' + (this.isTabActive(1) ? ' active' : '')}
                         onClick={() => this.setTabActive(1)}>
                        <div>MY RECENT TIMESHEET CODES</div>
                        <div className={'line' + (this.isTabActive(1) ? ' black' : '')}/>
                    </div>
                </div>
                {this.isTabActive(0) ?
                    <div className="search-timesheetcode-container">
                        <TextInput id="searchTimesheetCode"
                            name="searchTimesheetCode"
                            onChange={(event: React.SyntheticEvent<HTMLInputElement> & { currentTarget: HTMLInputElement }) => this.onChange(event)}
                            autoFocus/>
                    </div>
                    : null
                }
                {this.props.tsCodes.length === parseInt(process.env.API_PAGESIZE as unknown as string) && this.isTabActive(0) ?
                    <div className="searchResultMessage">Too many results. Please refine your search.</div>
                    : null
                }
                {this.isTabActive(1) ?
                    (this.getFilteredTimesheetCodes().length > 0 ?
                        <div className="addTimesheetCode-selectAll no-select"
                             onClick={this.onSelectAll}>
                            <input type="checkbox" className="addTimesheetCode-selectAll-checkbox" checked={this.state.selectAllChecked}/>
                            <div className="addTimesheetCode-selectAll-checkbox-text">SELECT ALL</div>
                        </div> : <div className="no-most-recent-tscodes">No results found</div>
                    )
                    : null
                }
                <div className="selected-timesheet-code-list">
                    {this.state.selectedTsCodes.map(tsCode => {
                        return (<div key={tsCode.id} className="selected-timesheet-code">
                                <input type="checkbox" checked onChange={() => this.onTimesheetCodeClick(tsCode)}/>
                                <div className="selected-timesheet-code-container" onClick={() => this.onTimesheetCodeClick(tsCode)}>
                                    <div className="selected-timesheet-code-name">{tsCode.name}</div>
                                </div>
                        </div>);
                    })}
                </div>
                <div className="timesheet-code-list">
                    <div className={'scrollbar-container-timesheet-code-list' + (this.getFilteredTimesheetCodes().length === this.state.selectedTsCodes.length ? '' : ' has-elements')}>
                        <ScrollBar>
                            {this.getFilteredTimesheetCodes().map(tsCode => {
                                return (
                                    this.state.selectedTsCodes.find(t => t.id === tsCode.id) === undefined ?
                                    <div key={tsCode.id} className="selectable-timesheet-code"
                                            onClick={() => this.onTimesheetCodeClick(tsCode)}>
                                            <input type="checkbox"
                                                checked={this.state.selectedTsCodes.find(t => t.id === tsCode.id) !== undefined}
                                                onChange={() => this.onTimesheetCodeClick(tsCode)}/>
                                        <div className="selectable-timesheet-code-container"
                                                onClick={() => this.onTimesheetCodeClick(tsCode)}>
                                            <div className="selectable-timesheet-code-name">{tsCode.name}</div>
                                            <div className="selectable-timesheet-code-description">{tsCode.description}</div>
                                        </div>
                                    </div> : null
                                );
                            })}
                        </ScrollBar>
                    </div>
                </div>
                <div className="add-selected">
                    <input type="submit" value="ADD SELECTED"
                           className="add-timesheet-code-button"
                           onClick={this.addSelected}
                           disabled={this.state.selectedTsCodes.length === 0}/>
                </div>
            </div>
        );
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = {
    value?: string,
    period: Period,

    addAssignments: (tsCodes: StoreTsCodes) => void
};

type State = {
    value: string,
    activeTab: number,
    selectedTsCodes: StoreTsCodes,
    mostRecentTsCodes: StoreTsCodes,
    selectAllChecked: boolean
};

function mapStateToProps(state: StoreState, props: Props) {
    return {
        value: props.value,
        period: props.period,
        user: state.user,
        tsCodes: state.tsCodes,
        timesheetCodes: state.timesheet.timesheetCodes,

        addAssignments: props.addAssignments
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({getTsCodes, getMostRecentTsCodes, clearTsCodes}, dispatch);
}

export default connector(AddTimesheetCode);

