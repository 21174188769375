import { AnyAction, Dispatch, bindActionCreators } from 'redux';
import { ConnectedProps, connect } from 'react-redux';
import { Job } from 'node-schedule';
import { MessageTypes, Messages } from '../../../types/messageTypes';
import { ToastContainer } from 'react-toastify';
import { checkVersion, getMaintenanceDetails } from '../../../actions/maintenanceActions';
import { forceRefreshToken } from '../../../actions/userActions';
import CronHelper from '../../../helpers/cronHelper';
import Login from './login/Login';
import MaintenanceHelper from '../../../helpers/maintenanceHelper';
import MessageHelper from '../../../helpers/messageHelper';
import MobileModal from '../common/MobileModal';
import React from 'react';
import RefreshToast from '../../../common/RefreshToast';
import Timesheet from './timesheet/Timesheet';
import WindowHelper from '../../../helpers/windowHelper';

import { GroupTypes } from '../../../types/groupTypes';
import { Interval } from '../../../types/enumTypes';
import { LocationTypes, ValidRedirects } from '../../../types/locationTypes';
import { StoreState } from '../../../typescript/storeTypes';


export class Content extends React.Component<Props> {
    loggedInJob: Job|null = null;
    checkVersionJob: Job|null = null;
    maintenanceToastId: string|number = 9999;
    versionToastId: string|number = 9998;
    invalidPayrollToastId: string|number = 9996;

    checkVersionShown = false;

    canBypassMaintenanceMode = () => {
        return this.props.user.userGroups.some(ug => ug.username === this.props.user.authenticatedUser.username && ug.groups.some(g => g === GroupTypes.BYPASS_MAINTENANCEMODE));
    };

    getUserAndMaintenanceDetails = () => {
        if(this.canBypassMaintenanceMode()) return;

        this.props.getMaintenanceDetails();
    };

    componentDidMount() {
        this.getUserAndMaintenanceDetails();
        CronHelper.createNewJob(Interval.Every30Minutes, this.getUserAndMaintenanceDetails);
        this.loggedInJob = CronHelper.createNewJob(Interval.EveryHour, this.props.forceRefreshToken);
    }

    componentWillUnmount() {
        this.loggedInJob && CronHelper.stopCronJob(this.loggedInJob);
    }

    componentDidUpdate(prevProps: Props): void {
        if(!ValidRedirects.ForWebsite.includes(WindowHelper.getWindowLocation().pathname)) {
            WindowHelper.setWindowLocation(LocationTypes.HOME);
        }

        if(this.props.user.authenticatedUser.token && !this.props.user.authenticatedUser.hasActivePayroll && !MessageHelper.isActive(this.invalidPayrollToastId)) {
            this.invalidPayrollToastId = MessageHelper.showMessage(Messages.InactivePayroll, this.invalidPayrollToastId, false);
        } else if (this.props.user.authenticatedUser.hasActivePayroll && MessageHelper.isActive(this.invalidPayrollToastId)) {
            MessageHelper.dismiss(this.invalidPayrollToastId);
        }

        if(!MessageHelper.isActive(this.maintenanceToastId) && MaintenanceHelper.isMaintenanceModeConfigured(this.props.maintenanceDetails)) {
            this.maintenanceToastId = MessageHelper.showMessage({type: MessageTypes.INFO, message:MaintenanceHelper.getMaintenanceMessage(this.props.maintenanceDetails)});
        }

        if(!MessageHelper.isActive(this.versionToastId) && this.checkVersionShown === false && this.props.maintenanceDetails.isLatestVersion === false) {
            this.checkVersionShown = true;
            this.versionToastId = MessageHelper.showMessage({type: MessageTypes.INFO, message: <RefreshToast/>}, this.versionToastId, false);
        } else if (MessageHelper.isActive(this.versionToastId) && prevProps.maintenanceDetails.isLatestVersion === false && this.props.maintenanceDetails.isLatestVersion === true) {
            MessageHelper.dismiss(this.versionToastId);
            this.checkVersionShown = false;
        } else if(MessageHelper.isActive(this.versionToastId) && this.checkVersionShown === true) {
            this.checkVersionShown = false;
        }

        if(prevProps.user.authenticatedUser.token !== ''){
            if(this.checkVersionJob === null) {
                this.checkVersionJob = CronHelper.createNewJob(Interval.EveryHour, this.props.checkVersion);
                this.props.checkVersion();
            } else if(this.checkVersionJob && this.props.user.authenticatedUser.token === '') {
                CronHelper.stopCronJob(this.checkVersionJob);
                this.checkVersionJob = null;
            }
        } else if (this.checkVersionJob === null && this.props.user.authenticatedUser.token !== '') {
            this.checkVersionJob = CronHelper.createNewJob(Interval.Every10Minutes, this.props.checkVersion);
            this.props.checkVersion();
        }
    }

    render() {
        return (
            <div className="content">
                {this.props.maintenanceDetails.isInMaintenanceMode && (this.props.user.userGroups.length > 0 || this.props.user.authenticationError !== '') ?
                    <MobileModal className="modal-dialog-override">
                        <div className="inMaintenance">
                            {MaintenanceHelper.getMaintenanceMessage(this.props.maintenanceDetails)}
                        </div>
                    </MobileModal>
                :
                    <div className="default-content">
                        <ToastContainer
                            position="top-center"
                            autoClose={20000}
                            newestOnTop
                            closeOnClick/>
                        <Timesheet/>
                        <Login/>
                    </div>
                }
            </div>
        );
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = MappedProps;

function mapStateToProps(state: StoreState) {
    return {
        maintenanceDetails: state.maintenanceDetails,
        user: state.user
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({
        getMaintenanceDetails,
        forceRefreshToken,
        checkVersion
    }, dispatch);
}

export default connector(Content);
