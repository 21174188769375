import {AnyAction, Dispatch,bindActionCreators} from 'redux';
import {ConnectedProps, connect} from 'react-redux';
import {
    closeHeaderScreens,
    openChangeUser,
    openProfile,
    openSettings
} from '../../../../actions/callsActions';
import {logoutUser} from '../../../../actions/userActions';
import React from 'react';

import StringHelper from '../../../../helpers/stringHelper';


import {InitialState} from '../../../../reducers/initialState';
import {StoreState} from '../../../../typescript/storeTypes';

import ProfileModal from './ProfileModal';

export class Profile extends React.Component<Props> {
    closeModals = () => {
        this.props.closeHeaderScreens();
    };

    logOff = () => {
        this.props.logoutUser();
        this.props.closeHeaderScreens();
    };

    onChangeUserHandle = () => {
        this.props.closeHeaderScreens();
        this.props.openChangeUser();
    };

    onOpenSettings = () => {
        this.props.closeHeaderScreens();
        this.props.openSettings();
    };

    canUserChangeUser = (): boolean => {
        return this.props.user.visibleUsers.length > 1;
    };

    render() {
        return (this.props.user.impersonatedUser !== undefined && this.props.user.impersonatedUser.id !== InitialState.user.impersonatedUser.id ?
            <div className="profileContainer">
                <div className="clickable-area" onClick={this.props.openProfile} />
                <div className="profile">
                    <div className="profile-avatar">
                        {StringHelper.getAvatarText(this.props.user, this.props.settings.firstNameLastName)}
                    </div>
                    {this.props.calls.profileOpen ? <ProfileModal canUserChangeUser={this.canUserChangeUser()}
                                                                title={`Timesheet for ${StringHelper.getUserFullName(this.props.user, this.props.settings.firstNameLastName)}`}
                                                                closeModal={this.closeModals}
                                                                onChangeUserHandle={this.onChangeUserHandle}
                                                                logOff={this.logOff}
                                                                openSettings={this.onOpenSettings} /> : null}
                </div>
            </div> : null
        );
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = MappedProps;

function mapStateToProps(state: StoreState) {
    return {
        user: state.user,
        calls: state.calls,
        settings: state.settings
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({
        logoutUser,
        openChangeUser,
        openSettings,
        closeHeaderScreens,
        openProfile
    }, dispatch);
}

export default connector(Profile);
