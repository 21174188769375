import {ActionTypes} from '../types/actionTypes';
import {call, put, takeEvery} from 'redux-saga/effects';
import importApi from '../api/importApi';

import {Action} from '../typescript/actionTypes';

function* validateActuals(action: Action): Generator<any, any, any> {
    try {
        const validatedActuals = yield call(importApi.postValidateActuals, action.payload);

        yield put({type: ActionTypes.VALIDATE_ACTUALS_SUCCEEDED, payload: {actuals: validatedActuals, errorMessage: ''}});
    } catch (e) {
        yield put({type: ActionTypes.VALIDATE_ACTUALS_FAILED, payload: e.message});
    }
}

function* processImportActuals(action: Action): Generator<any, any, any> {
    try {
        yield call(importApi.postProcessImportActuals, action.payload.importId);

        yield put({type: ActionTypes.PROCESS_IMPORT_ACTUALS_SUCCEEDED, payload: null});
    } catch (e) {
        yield put({type: ActionTypes.PROCESS_IMPORT_ACTUALS_FAILED, payload: e.message});
    }
}

function* importSaga(): Generator<any, any, any> {
    yield takeEvery(ActionTypes.VALIDATE_ACTUALS_REQUESTED, validateActuals);
    yield takeEvery(ActionTypes.PROCESS_IMPORT_ACTUALS_REQUESTED, processImportActuals);
}

export default importSaga;
