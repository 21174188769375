import React from 'react';
import ScrollBar from 'react-custom-scrollbars-2';

import {StoreTimesheetCode} from '../../../../typescript/storeTypes';

class ExportMonthlyDetailList extends React.Component<Props> {
    render () {
        return (
            <div className="export-monthly">
                <div className="timesheet-codes-list">
                    <div className="scrollbar-container-timesheet-codes-list">
                    {this.props.timesheetCodes.length === 0 ?
                        <div className="no-tscode-select">
                            No timesheet codes to export
                        </div>
                    :
                        <ScrollBar>
                            {this.props.timesheetCodes.map(timesheetCode =>
                            <div key={timesheetCode.id} className="tscode-select">
                                <input id={`${timesheetCode.id}`}
                                    type="checkbox"
                                    checked={this.props.selectedTimesheetCodes.find(t => t.id === timesheetCode.id) !== undefined}
                                    onChange={() => this.props.onTimesheetCodeClick(timesheetCode)}/>
                                <div className="tscode-select-name no-select"
                                        onClick={() => this.props.onTimesheetCodeClick(timesheetCode)}>
                                    {timesheetCode.name}
                                </div>
                            </div>)}
                        </ScrollBar>}
                    </div>
                </div>
                <div className="export-monthly-actions">
                    <div className="button-spacing">
                        <input className="export-monthly-select-all"
                               type="submit"
                               onClick={this.props.onSelectAll}
                               value={this.props.selectAllChecked ? 'DESELECT ALL' : 'SELECT ALL'}
                               disabled={(this.props.timesheetCodes.length === 0)} />
                    </div>
                    <input className={'export-selected-button' + (this.props.selectedTimesheetCodes.length === 0 ? ' disabled' : '')}
                        type="submit" value="NEXT"
                        onClick={this.props.openDetailExtraInfo}
                        disabled={(this.props.selectedTimesheetCodes.length === 0)} />
                </div>
            </div>
        );
    }
}

type Props = {
    timesheetCodes: StoreTimesheetCode[],
    selectedTimesheetCodes: StoreTimesheetCode[],
    selectAllChecked: boolean,
    onSelectAll: () => void,
    openDetailExtraInfo: () => void,
    onTimesheetCodeClick: (timesheetCode: StoreTimesheetCode) => void
};

export default ExportMonthlyDetailList;
