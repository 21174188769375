import React from 'react';

class Modal extends React.PureComponent<Props> {
    static defaultProps = {
        className: ''
    };

    render() {
        return (
            <div className="modal">
                <div className="modal-forefront">
                    <div className={`modal-dialog ${this.props.className}`}>
                        <div className="modal-content">
                            {this.props.children}
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop" />
            </div>
        );
    }
}

type Props = {
    children: React.ReactElement[] | React.ReactElement,
    className: string
};

export default Modal;
