export const LocationTypes = {
    HOME: '/',
    REPORTING: '/reporting',
    REPORTING_ACTUALS: '/reporting/actuals',
    REPORTING_TIMESHEETS: '/reporting/timesheets',
    REPORTING_WEEKLY_TIMESHEETS: '/reporting/timesheetsweekly',
    REPORTING_LANDSCAPE_TIMESHEETS: '/reporting/timesheetslandscape',
    REPORTING_TIME_LEFT_ON_ORDER: '/reporting/timeleftonorder',
    REPORTING_PROJECT_TIMESHEET_REPORT: '/reporting/projecttimesheet',
    IMPORT: '/import',
    IMPORT_ACTUALS: '/import/actuals',
    RELEASE_NOTES: '/releasenotes',
    SETTINGS: '/settings',
    ADFS_LOGIN: '/adfs/login',
    STS: process.env.STS_WEBSITE_ENDPOINT,
    STS_MOBILE: process.env.STS_MOBILE_ENDPOINT,
    INVOICE_VIEWER: '/invoiceviewer',
    ACCESSRIGHTS: process.env.ACCESSRIGHTS_REDIRECT_URI
};

export const ValidRedirects = {
    ForSTS: [
        LocationTypes.HOME, 
        LocationTypes.REPORTING, 
        LocationTypes.REPORTING_ACTUALS, 
        LocationTypes.REPORTING_TIMESHEETS, 
        LocationTypes.REPORTING_WEEKLY_TIMESHEETS, 
        LocationTypes.REPORTING_LANDSCAPE_TIMESHEETS, 
        LocationTypes.REPORTING_TIME_LEFT_ON_ORDER,
        LocationTypes.REPORTING_PROJECT_TIMESHEET_REPORT,
        LocationTypes.IMPORT_ACTUALS,
        LocationTypes.RELEASE_NOTES,
        LocationTypes.INVOICE_VIEWER],
    ForWebsite: [
        LocationTypes.HOME, 
        LocationTypes.REPORTING, 
        LocationTypes.REPORTING_ACTUALS, 
        LocationTypes.REPORTING_TIMESHEETS, 
        LocationTypes.REPORTING_WEEKLY_TIMESHEETS, 
        LocationTypes.REPORTING_LANDSCAPE_TIMESHEETS, 
        LocationTypes.REPORTING_TIME_LEFT_ON_ORDER,
        LocationTypes.REPORTING_PROJECT_TIMESHEET_REPORT,
        LocationTypes.IMPORT,
        LocationTypes.IMPORT_ACTUALS,
        LocationTypes.SETTINGS,
        LocationTypes.ADFS_LOGIN,
        LocationTypes.RELEASE_NOTES,
        LocationTypes.INVOICE_VIEWER
    ]
};
