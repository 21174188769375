import AddTimesheetCode from './AddTimesheetCode';
import DateHelper from '../../../../helpers/dateHelper';
import React from 'react';
import RemoveTimesheetCode from './RemoveTimesheetCode';
import TimesheetEntry from './TimesheetEntry';
import TimesheetHelper from '../../../../helpers/timesheetHelper';
import TimesheetReturn from './TimesheetReturn';

import {Action} from '../../../../typescript/actionTypes';
import {Day, Period} from '../../../../typescript/dateTypes';
import {DetailViewInfo} from '../../../../typescript/infoTypes';
import {
    StoreTimesheetCode, StoreTimesheetEntry, StoreTsCode,
    StoreTsCodes
} from '../../../../typescript/storeTypes';

class TimesheetDetail extends React.PureComponent<Props, State> {
    state: State = {
        addTimesheetCode: false,
        deleteTimesheetCode: false
    };

    getDate = (): string => {
        return DateHelper.getNormalizedDate(new Date(this.props.day.dateString)).toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
        });
    };

    getTimesheetReturnTitle = (): string => {
        if (this.state.addTimesheetCode) {
            return 'Add timesheet code';
        } else if (this.state.deleteTimesheetCode) {
            return 'Remove timesheet code';
        } else if (this.props.detailView.entry === TimesheetHelper.defaultEntry) {
            return `Add hours for ${this.getDate()}`;
        } else {
            return `Update hours for ${this.getDate()}`;
        }
    };

    onAddTimesheetCode = () => {
        this.props.onDetailView(TimesheetHelper.defaultEntry);
        this.setState({addTimesheetCode: true});
    };

    onRemoveTimesheetCode = () => {
        this.props.onDetailView(TimesheetHelper.defaultEntry);
        this.setState({deleteTimesheetCode: true});
    };

    onAddAssignments = (tsCodes: StoreTsCodes) => {
        for (let i = 0, len = tsCodes.length; i < len; i++) {
            this.props.addAssignment(tsCodes[i]);
        }

        this.props.clearTsCodes();
        this.setState({addTimesheetCode: false});
    };

    onCloseDetailView = () => {
        if (this.state.deleteTimesheetCode) {
            this.setState({deleteTimesheetCode: false});
        } else if (this.state.addTimesheetCode) {
            this.setState({addTimesheetCode: false});
        } else {
            this.props.onCloseDetailView();
        }
    };

    render() {
        return (
            <div className="timesheet-detail">
                <TimesheetReturn title={this.getTimesheetReturnTitle()}
                                 onCloseDetailView={this.onCloseDetailView}/>
                {this.state.addTimesheetCode ?
                    <AddTimesheetCode period={this.props.period}
                                      addAssignments={this.onAddAssignments} /> :
                    this.state.deleteTimesheetCode ?
                        <RemoveTimesheetCode /> :
                        <TimesheetEntry day={this.props.day}
                                        entry={this.props.detailView.entry}
                                        timesheetCode={this.props.detailView.timesheetCode}
                                        isReadOnly={this.props.isReadOnly || (this.props.detailView.timesheetCode !== undefined ? this.props.detailView.timesheetCode.isReadOnly : false) || !this.props.isAccessibleUser}
                                        canAddTimesheetCode={this.props.canAddTimesheetCode && this.props.isAccessibleUser}
                                        canDeleteTimesheetCode={this.props.canDeleteTimesheetCode && this.props.isAccessibleUser}

                                        onSubmit={this.props.onEntryChange}
                                        onDetailView={this.props.onDetailView}
                                        onAddTimesheetCode={this.onAddTimesheetCode}
                                        onRemoveTimesheetCode={this.onRemoveTimesheetCode}/>}
            </div>
        );
    }
}

type State = {
    addTimesheetCode: boolean,
    deleteTimesheetCode: boolean
};

type Props = {
    day: Day,
    period: Period,
    detailView: DetailViewInfo,
    tsCodes: StoreTsCodes,
    isReadOnly: boolean,
    canAddTimesheetCode: boolean,
    canDeleteTimesheetCode: boolean,
    isAccessibleUser: boolean,

    onCloseDetailView: () => void,
    onEntryChange: (entry: StoreTimesheetEntry, timesheetCode: StoreTimesheetCode) => void,
    onDetailView: (entry: StoreTimesheetEntry, timesheetCode?: StoreTimesheetCode) => void,

    addAssignment: (tsCode: StoreTsCode) => void,
    clearTsCodes: () => Action
};

export default TimesheetDetail;
