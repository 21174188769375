import React from 'react';

class TimesheetFooterTotal extends React.PureComponent<Props> {
    render() {
        return (
            this.props.totalHoursDay ?
                <div className="total">
                    <div className="total-separator"/>
                    <div className="total-hours-day">
                        <div className="total-label">Total hours for this day</div>
                        <div className="total-hours">
                            <span>{+this.props.totalHoursDay.toFixed(2)}</span><label> hrs</label>
                            <i className="fas fa-angle-right expand-hours"/>
                        </div>
                    </div>
                </div> : <div className="no-registered-hours">No registered hours yet.</div>
        );
    }
}

type Props = {
    totalHoursDay: number
};

export default TimesheetFooterTotal;
