import {StoreAssignment} from '../typescript/storeTypes';
import {TimesheetCode} from '../typescript/timesheetTypes';
import AuthorizedRequest from './authorizedRequests';
import ErrorHelper from '../helpers/errorHelper';

class AssignmentApi {
    static getAssignedTimesheetCodes(username: string, month: number, year: number) {
        return AuthorizedRequest.getAuthorizedRequest(`assignment/${encodeURIComponent(username)}/${month}/${year}`)
            .ok(res => res.status === 200)
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }
 
    static getTimesheetsFromClientInfo(assignmentId: number) {
        return AuthorizedRequest.getAuthorizedRequest(`assignment/${assignmentId}/timesheetsfromclient`)
            .ok(res => res.status === 200)
            .then(response => {
                return {body: response.body, assignmentId: assignmentId};
            })
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static postAssignment(assignment: StoreAssignment) {
        return AuthorizedRequest.postAuthorizedRequest(`assignment/${encodeURIComponent(assignment.username)}`, assignment)
            .ok(res => res.status === 201)
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static postAssignmentCurrentUser(assignment: StoreAssignment) {
        return AuthorizedRequest.postAuthorizedRequest('assignment', assignment)
            .ok(res => res.status === 201)
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static postTimesheetCodeComplete(timesheetCodeToComplete: TimesheetCode) {
        return AuthorizedRequest.postAuthorizedRequest(`assignment/complete/${encodeURIComponent(timesheetCodeToComplete.username)}/${timesheetCodeToComplete.month}/${timesheetCodeToComplete.year}/${timesheetCodeToComplete.tsCodeId}`, {})
            .ok(res => res.status === 200)
            .then(() => { return; })
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static postTimesheetCodeCompleteCurrentUser(timesheetCodeToComplete: TimesheetCode) {
        return AuthorizedRequest.postAuthorizedRequest(`assignment/complete/${timesheetCodeToComplete.month}/${timesheetCodeToComplete.year}/${timesheetCodeToComplete.tsCodeId}`, {})
            .ok(res => res.status === 200)
            .then(() => { return; })
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static postTimesheetCodeIncomplete(timesheetCodeToIncomplete: TimesheetCode) {
        return AuthorizedRequest.postAuthorizedRequest(`assignment/incomplete/${encodeURIComponent(timesheetCodeToIncomplete.username)}/${timesheetCodeToIncomplete.month}/${timesheetCodeToIncomplete.year}/${timesheetCodeToIncomplete.tsCodeId}`,{})
            .ok(res => res.status === 200)
            .then(() => { return; })
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static postTimesheetCodeIncompleteCurrentUser(timesheetCodeToIncomplete: TimesheetCode) {
        return AuthorizedRequest.postAuthorizedRequest(`assignment/incomplete/${timesheetCodeToIncomplete.month}/${timesheetCodeToIncomplete.year}/${timesheetCodeToIncomplete.tsCodeId}`,{})
            .ok(res => res.status === 200)
            .then(() => { return; })
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static deleteTimesheetCode(timesheetCodeToDelete: TimesheetCode) {
        return AuthorizedRequest.deleteAuthorizedRequest(`assignment/${encodeURIComponent(timesheetCodeToDelete.username)}/${timesheetCodeToDelete.month}/${timesheetCodeToDelete.year}/${timesheetCodeToDelete.tsCodeId}`)
            .ok(res => res.status === 200)
            .then(() => { return; })
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static deleteTimesheetCodeCurrentUser(timesheetCodeToDelete: TimesheetCode) {
        return AuthorizedRequest.deleteAuthorizedRequest(`assignment/${timesheetCodeToDelete.month}/${timesheetCodeToDelete.year}/${timesheetCodeToDelete.tsCodeId}`)
            .ok(res => res.status === 200)
            .then(() => { return; })
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }
}

export default AssignmentApi;
