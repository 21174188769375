import {StoreUser} from '../typescript/storeTypes';

class PermissionHelper {
    static hasPermission(user: StoreUser, ...permissionTypes: string[]): boolean {
       try {
            if(!user || !user.permissions || user.permissions.length === 0) {
                return false;
            }

            return user.permissions.find(p => permissionTypes.some(pt => pt === p)) !== undefined;
       } catch (error) {
            return false;
       }
    }
}

export default PermissionHelper;
