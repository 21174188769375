export enum TimesheetCompleteStatus {
    Complete = 'Complete',
    PartiallyComplete = 'Partially Complete',
    Incomplete = 'Incomplete'
}

export enum ApprovalTypes {
    None = 'None',
    All = 'All',
    One = 'One'
}

export enum SigningHubStates {
    Initial = 'Initial',
    PackageCreated = 'PackageCreated',
    InProgress = 'InProgress',
    ConsultantApprovalCompleted = 'ConsultantApprovalCompleted',
    InternalApprovalCompleted = 'InternalApprovalCompleted',
    CustomerApprovalCompleted = 'CustomerApprovalCompleted',
    Completed = 'Completed',
    Declined = 'Declined',

    Error = 'Error',
    ErrorOnCreatingPackage = 'ErrorOnCreatingPackage',
    Cancelling = 'Cancelling',
    Cancelled = 'Cancelled'
}

export enum Interval {
    EveryMinute = 1,
    Every10Minutes = 10,
    Every30Minutes = 30,
    EveryHour = 60
}
