import {AnyAction, Dispatch,bindActionCreators} from 'redux';
import {ConnectedProps, connect} from 'react-redux';
import React from 'react';
import ScrollBar from 'react-custom-scrollbars-2';


import {StoreState} from '../../../../typescript/storeTypes';
import {deleteTimesheetCode} from '../../../../actions/assignmentActions';
import {getRemovableTimesheetCodes} from '../../../selectors/timesheetSelectors';

export class RemoveTimesheetCode extends React.PureComponent<Props> {
    deleteTimesheetCode = (tsCodeId: number): void => {
        this.props.deleteTimesheetCode({
            username: this.props.user.impersonatedUser.username,
            month: this.props.period.month,
            year: this.props.period.year,
            tsCodeId: tsCodeId
        }, this.props.user);
    };

    render() {
        return (
            <div className="remove-timesheetcode">
                <div className="removable-timesheetcodes">
                    <div className="scrollbar-container-removable-timesheetcodes">
                        <ScrollBar>
                            {this.props.timesheetCodes.map(tsCode => {
                                return <div className="removable-timesheetcode" key={tsCode.id}>
                                    <div className="clickable-area" onClick={() => this.deleteTimesheetCode(tsCode.id)}>
                                        <img className="remove-trash-bin" src="/images/trash.png"/>
                                    </div>
                                    <div className="removable-timesheet-code-container">
                                        <div className="removable-timesheet-code-name">{tsCode.name}</div>
                                        <div className="removable-timesheet-code-description">{tsCode.description}</div>
                                    </div>
                                </div>;
                            })}
                        </ScrollBar>
                    </div>
                </div>
            </div>
        );
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = MappedProps;

function mapStateToProps(state: StoreState) {
    return {
        timesheetCodes: getRemovableTimesheetCodes(state),
        user: state.user,
        period: state.timesheet.period
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({
        deleteTimesheetCode
    }, dispatch);
}

export default connector(RemoveTimesheetCode);
