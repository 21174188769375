import React from 'react';

import DateHelper from '../../../../helpers/dateHelper';

import {Day} from '../../../../typescript/dateTypes';

class TimesheetFooterDay extends React.PureComponent<Props> {
    getDate = (): string => {
        return DateHelper.getNormalizedDate(new Date(this.props.day.dateString)).toLocaleDateString('en-US', {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            year: 'numeric'
        });
    };

    render() {
        return (
            <div className="day">{this.getDate()}</div>
        );
    }
}

type Props = {
    day: Day
};

export default TimesheetFooterDay;
