import '../../node_modules/cookieconsent/build/cookieconsent.min';
import React from 'react';

class CookieConsent extends React.PureComponent {

    componentDidMount() {
        this.openCookieConsent();
    }

    openCookieConsent = () => {
        (window as any).cookieconsent.initialise({
            'palette': {
                'popup': {
                'background': '#44b7de',
                'text': '#ffffff'
                },
                'button': {
                'background': '#ffffff',
                'text': '#868e97'
                }
            },
            'showLink': false,
            'content': {
                'message': 'This website uses cookies to ensure you get the best experience on our website.',
                'dismiss': 'I AGREE'
            }
        });
    };

    render() {
        return (
            <div id="consent"/>
        );
    }
}

export default CookieConsent;
