import {AnyAction, Dispatch, bindActionCreators} from 'redux';
import {ConnectedProps, connect} from 'react-redux';
import {updateSetting} from '../actions/settingsActions';
import React from 'react';
import Toggle from './Toggle';

import {StoreState} from '../typescript/storeTypes';


export class ContractHoursToggle extends React.PureComponent<Props> {
    onToggleChange = (hoursPerWeek: number): void => {
        this.props.updateSetting('defaultContractHours', hoursPerWeek);
    };

    render() {
        return (
            <Toggle value1={38} displayValue1="38 H"
                    value2={40} displayValue2="40 H"
                    onChange={this.onToggleChange}
                    currentValue={this.props.defaultContractHours} />
        );
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = MappedProps;

function mapStateToProps(state: StoreState) {
    return {
        defaultContractHours: state.settings.defaultContractHours
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({updateSetting}, dispatch);
}

export default connector(ContractHoursToggle);
