import {StoreSettings, StoreUser} from '../../../../typescript/storeTypes';
import React from 'react';
import TimesheetReturn from '../timesheet/TimesheetReturn';
import Toggle from '../../../../common/Toggle';

class SettingsDetail extends React.Component<Props>{
    onNamingToggle = (value: any): void => {
        this.props.updateSetting('firstNameLastName', value === 'firstLast');
    };

    onTimesheetCodeToggle = (value: any): void => {
        this.props.updateSetting('timesheetCodeOrder', value === 'alphabetical');
    };

    render() {
        return (
            <div className="settings-detail">
                <TimesheetReturn title="SETTINGS" onCloseDetailView={this.props.closeModal} />
                <div className="settings-item">
                    <div className="item-title">Naming style</div>
                    <Toggle value1="firstLast" displayValue1={`${this.props.user.impersonatedUser.firstName} ${this.props.user.impersonatedUser.lastName}`}
                            value2="lastFirst" displayValue2={`${this.props.user.impersonatedUser.lastName} ${this.props.user.impersonatedUser.firstName}`}
                            currentValue={(this.props.settings.firstNameLastName ? 'firstLast' : 'lastFirst')}
                            onChange={this.onNamingToggle}/>
                </div>
                <div className="settings-item">
                    <div className="item-title">Timesheet code order</div>
                    <Toggle value1="alphabetical" displayValue1="Alphabetical"
                            value2="lastUsed" displayValue2="Last Used"
                            currentValue={(this.props.settings.timesheetCodeOrder ? 'alphabetical' : 'lastUsed')}
                            onChange={this.onTimesheetCodeToggle}/>
                </div>
            </div>
        );
    }
}

type Props = {
    closeModal: () => void,
    settings: StoreSettings,
    user: StoreUser,
    updateSetting(name: string, value: any): void
};

export default SettingsDetail;
