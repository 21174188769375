import {AnyAction, Dispatch,bindActionCreators} from 'redux';
import {ConnectedProps, connect} from 'react-redux';
import {InitialState} from '../../../../reducers/initialState';
import {closeHeaderScreens, openExportMonthly} from '../../../../actions/callsActions';
import React from 'react';

import {StoreState} from '../../../../typescript/storeTypes';

export class ExportMonthly extends React.Component<Props> {
    handleHeaderScreen = (): void => {
        if (this.props.calls.exportMonthlyOpen) {
            this.props.closeHeaderScreens();
        } else {
            if (this.props.calls.completeTimesheetOpen) {
                this.props.closeHeaderScreens();
            }

            this.props.openExportMonthly();
        }
    };

    render () {
        return (
            this.props.user.impersonatedUser !== InitialState.user.impersonatedUser ?
            <div className="export-monthly-header">
                <div className="clickable-area" onClick={this.handleHeaderScreen} />
                <img className="export-monthly-logo" src="/images/export-tscode-mobile.png"/>
            </div> : null
        );
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = MappedProps;

function mapStateToProps(state: StoreState) {
    return {
        user: state.user,
        calls: state.calls
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({openExportMonthly, closeHeaderScreens}, dispatch);
}

export default connector(ExportMonthly);
