class WindowHelper {
    static getWindowLocation = (): Location => {
        return window.location;
    };

    static setWindowLocation = (location: string) => {
        if(window.location.pathname !== location) {
            window.location.assign(location);
        }
    };

    static isElementInViewport = (el: any): boolean => {
        const rect     = el.getBoundingClientRect(),
            vWidth   = window.innerWidth,
            vHeight  = window.innerHeight,
            efp = (x: number, y: number) => document.elementFromPoint(x, y);

        // Return false if it's not in the viewport
        if (rect.right < 0 || rect.bottom < 0
                || rect.left > vWidth || rect.top > vHeight)
            return false;

        // Return true if any of its four corners are visible
        return (
              el.contains(efp(rect.left,  rect.top))
          ||  el.contains(efp(rect.right, rect.top))
          ||  el.contains(efp(rect.right, rect.bottom))
          ||  el.contains(efp(rect.left,  rect.bottom))
        );
    };
}

export default WindowHelper;
