class EmailHelper {
    static isValidEmailAddress(emailaddress: string): boolean {
        const rx = /^(([^<>()\\[\]\\.,;:\s@\\"]+(\.[^<>()\\[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i;
        return rx.test(emailaddress.toLowerCase());
    }

    static addCronosEmailIfNeeded = (email: string): string => !EmailHelper.isValidEmailAddress(email) ? email + '@cronos.be' : email;

    static isCronosEmailAddress = (emailaddress: string): boolean =>
        EmailHelper.isValidEmailAddress(emailaddress) && emailaddress.search('@cronos.be') > 0;

}

export default EmailHelper;
