export const PermissionTypes = {
    // AssignmentApi
    POST_ASSIGNMENT: 'PostAssignment',
    POST_ASSIGNMENT_CURRENT_USER: 'PostAssignmentCurrentUser',
    POST_ASSIGNMENT_COMPLETE: 'PostAssignmentComplete',
    POST_ASSIGNMENT_COMPLETE_CURRENT_USER: 'PostAssignmentCompleteForCurrentUser',
    POST_ASSIGNMENT_INCOMPLETE: 'PostAssignmentIncomplete',
    POST_ASSIGNMENT_INCOMPLETE_CURRENT_USER: 'PostAssignmentIncompleteForCurrentUser',
    DELETE_ASSIGNMENT: 'DeleteAssignment',
    DELETE_ASSIGNMENT_CURRENT_USER: 'DeleteAssignmentCurrentUser',

    // ImportApi
    POST_IMPORT_ACTUALS: 'PostImportActuals',
    POST_PROCESS_IMPORT_ACTUALS: 'PostProcessImportActuals',

    // MaintenanceApi

    // ReportApi
    POST_TIMESHEETS_LANDSCAPE_PDF: 'PostTimesheetsLandscapePdf',
    POST_TIMESHEETS_LANDSCAPE_EXCEL: 'PostTimesheetsLandscapeExcel',
    POST_ACTUALS_PDF: 'PostActualsPdf',
    POST_ACTUALS_CSV: 'PostActualsCsv',
    POST_ACTUALS_EXCEL: 'PostActualsExcel',
    POST_TIMESHEETS_PDF: 'PostTimesheetsPdf',
    POST_TIME_LEFT_ON_ORDER: 'PostTimeLeftOnOrder',
    POST_PROJECT_REPORT: 'PostProjectReport',
    POST_TIMESHEETS_WEEKLY_PDF: 'PostTimesheetsWeeklyPdf',
    POST_TIMESHEETS_WEEKLY_EXCEL: 'PostWeeklyTimesheetExportExcel',

    // SupplierApi
    SEARCH_SUPPLIER: 'SearchSupplier',

    // TimesheetApi
    GET_USER_TIMESHEET_PERIOD: 'GetUserTimesheetPeriod',
    POST_ACTUAL_WORK: 'PostActualWork',
    POST_ACTUAL_WORK_CURRENT_USER: 'PostActualWorkCurrentUser',
    PUT_ACTUAL_WORK: 'PutActualWork',
    PUT_ACTUAL_WORK_CURRENT_USER: 'PutActualWorkCurrentUser',
    DELETE_ACTUAL_WORK: 'DeleteActualWork',
    DELETE_ACTUAL_WORK_CURRENT_USER: 'DeleteActualWorkCurrentUser',
    POST_TIMESHEET_COMPLETE: 'PostTimesheetComplete',
    POST_TIMESHEET_COMPLETE_CURRENT_USER: 'PostTimesheetCompleteForCurrentUser',
    POST_TIMESHEET_INCOMPLETE: 'PostTimesheetIncomplete',
    POST_TIMESHEET_INCOMPLETE_CURRENT_USER: 'PostTimesheetIncompleteForCurrentUser',
    POST_TIMESHEET_EXPORT: 'PostTimesheetExport',
    POST_TIMESHEET_WEEKLY_EXPORT: 'PostTimesheetExportWeekly',
    POST_TIMESHEET_CLIENT: 'PostTimesheetClient',
    POST_TIMESHEET_CLIENT_CURRENT_USER: 'PostTimesheetClientForCurrentUser',
    POST_TIMESHEET_TO_SIGNING_HUB: 'PostTimesheetToSigningHub',
    POST_TIMESHEET_TO_SIGNING_HUB_FOR_CURRENT_USER: 'PostTimesheetToSigningHubForCurrentUser',
    POST_WEEKLY_TO_SIGNING_HUB: 'PostWeeklyTimesheetToSigningHub',
    POST_WEEKLY_TO_SIGNING_HUB_FOR_CURRENT_USER: 'PostWeeklyTimesheetToSigningHubForCurrentUser',

    // TsCodeApi
    SEARCH_TSCODE: 'SearchTsCode',
    GET_MOST_RECENT_TIMESHEET_CODES: 'GetMostRecentTsCodes',

    // UserApi
    GET_USER_BY_USERNAME: 'GetUserByUsername',
    GET_VISIBLE_SUPPLIERS:'GetVisibleCompaniesIncludingVisiblePersonCompanies',
    GET_INVOICEVIEWER_SUPPLIERS: 'GetInvoiceViewerSuppliers',

    // InvoiceApi
    SEARCH_INVOICE: 'SearchInvoice',

    // DigitalSigningApi
    POST_CANCEL_SIGNINGHUBQUEUE : 'PostCancelSigningHubQueue',

    // Projects
    GET_PROJECTS: 'GetAllProjects',

    // Groups
    GET_GROUPS: 'GetGroups'
};
