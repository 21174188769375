import React from 'react';

class Disclaimer extends React.PureComponent {
    render() {
        return (
            <div className="disclaimer">
                Disclaimer: Details for invoicing purposes only
            </div>
        );
    }
}

export default Disclaimer;
