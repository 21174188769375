class ObjectHelper {
    static deepClone(object: any) {
        return JSON.parse(JSON.stringify(object));
    }

    static toCamel(object: any): Record<string, any> {
        let newO: any, origKey, newKey, value;
        if (object instanceof Array) {
          newO = [];
          for (origKey in object) {
            value = object[origKey];
            if (typeof value === 'object') {
              value = this.toCamel(value);
            }
            newO.push(value);
          }
        } else {
          newO = {};
          for (origKey in object) {
            if (Object.prototype.hasOwnProperty.call(object, origKey)) {
              newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString();
              value = object[origKey];
              if (value instanceof Array || (value !== null && value.constructor === Object)) {
                value = this.toCamel(value);
              }
              newO[newKey] = value;
            }
          }
        }
        return newO;
    }

    static findDuplicate = (objects: Record<string, any>[], compare: (obj1: Record<string, any>, obj2: Record<string, any>) => boolean): any[] => {
      const duplicates = objects.map((o, i) => {
            if (objects.find((e, ind) => {
                if(i > ind) {
                    return compare(o, e);
                }})) {
                    return o;
                }
            }).filter(obj => obj !== undefined);

        return duplicates;
    };

    static findDuplicates = (objects: Record<string, any>[], compare: (obj1: Record<string, any>, obj2: Record<string, any>) => boolean): any[] => {
      const duplicates: any[] = [];
      objects.forEach(obj => {
          if (duplicates.indexOf(obj) === -1)
          {
              const foundObjects = objects.filter(obj2 => compare(obj, obj2));
              if (foundObjects.length > 1) {
                  foundObjects.forEach(fo => duplicates.push(fo));
              }
          }
      });
      return duplicates;
    };

    static charAt = (obj: any, pos: number): string => {
        if(typeof obj !== 'undefined' && obj !== null && typeof obj === 'string')
        {
            return obj.toString().charAt(pos);
        }
        return '';
    };

    static isEmpty = (obj: any): boolean => {
        let isEmpty = true;
        const entries = Object.entries(obj);
        if(entries.length > 0) {
          for(const [val] of entries) {
            if(Array.isArray(val)) {
              if(val.length > 0) {
                isEmpty = false;
                break;
              }
            } else {

              if(!(Object.keys(val).length === 0 && val.constructor === Object)) {
                isEmpty = false;
                break;
              }
            }
          }
        }
        return isEmpty;
    };

    static deepEqual(object1: any, object2: any): boolean {
      const keys1 = Object.keys(object1);
      const keys2 = Object.keys(object2);
    
      if (keys1.length !== keys2.length) {
        return false;
      }
    
      for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = this.isObject(val1) && this.isObject(val2);
        if (areObjects && !this.deepEqual(val1, val2) || !areObjects && val1 !== val2) {
          return false;
        }
      }
    
      return true;
    }
    
    static isObject(object: any): boolean {
      return object !== null && typeof object === 'object';
    }
}

export default ObjectHelper;
