import {AnyAction, Dispatch,bindActionCreators} from 'redux';
import {ConnectedProps, connect} from 'react-redux';
import {getTimesheet} from '../../../../actions/timesheetActions';
import DateHelper from '../../../../helpers/dateHelper';
import React from 'react';

import {StoreState} from '../../../../typescript/storeTypes';

export class Identity extends React.Component<Props> {
    onClick = (): void => {
        const today = new Date();
        const startDate = DateHelper.firstDateOfMonth(today);
        const lastDate = DateHelper.lastDateOfMonth(today);
        this.props.getTimesheet(DateHelper.getPeriod(startDate, lastDate), this.props.impersonatedUser);
    };

    render() {
        return (
            <div className="identity">
                <div className="home" onClick={this.onClick}>
                    <img className="logo" src="/images/cronos-logo@3x.png"/>
                    <span className="title">Timesheet App</span>
                </div>
            </div>
        );
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = MappedProps;

function mapStateToProps(state: StoreState) {
    return {
        impersonatedUser: state.user.impersonatedUser
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({
        getTimesheet
    }, dispatch);
}

export default connector(Identity);
