import { Filter, SortingStyle } from '../typescript/sentInvoiceTypes';
import AuthorizedRequest from './authorizedRequests';
import ErrorHelper from '../helpers/errorHelper';

class InvoiceApi {
    static searchInvoice(invoiceNumber: string) {
        return AuthorizedRequest.getAuthorizedRequest(`invoice/search?invoiceNumber=${btoa(invoiceNumber)}`)
            .ok(res => res.status === 200)
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static getInvoice(invoiceId: string, invoiceNumber: string) {
        return AuthorizedRequest.getAuthorizedRequest(`invoice/${invoiceId}/duplicate`)
            .ok(res => res.status === 200)
            .responseType('blob')
            .then(response => {
                const customResponse = {
                    fileName: `${invoiceNumber.replace(/\//g,'_')}.pdf`,
                    blob: response.body
                };
                return customResponse;
            })
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static getSentInvoices(icPartnerId: number, page: number, invoiceDateRangeStart: string, invoiceDateRangeEnd: string, filter: Filter, sortingStyle: SortingStyle) {
        let parameters = '';
        parameters += `&invoicedaterangestart=${invoiceDateRangeStart}&invoicedaterangeend=${invoiceDateRangeEnd}`;
        if(filter.supplier) {
            parameters += `&supplier=${btoa(filter.supplier)}`;
        }
        if(filter.invoicenumber) {
            parameters += `&invoicenumber=${btoa(filter.invoicenumber)}`;
        }
        if(filter.customer) {
            parameters += `&customer=${btoa(filter.customer)}`;
        }
        if(filter.amount) {
            parameters += `&amount=${filter.amount}`;
        }
        if(filter.paid) {
            parameters += `&paid=${parseInt(filter.paid) > 0 ? '1' : '0'}`;
        }
        if(sortingStyle.sortBy) {
            parameters += `&sortby=${sortingStyle.sortBy}`;
        }
        if(sortingStyle.sortOrder) {
            parameters += `&sortorder=${sortingStyle.sortOrder}`;
        }


        return AuthorizedRequest.getAuthorizedRequest(`invoice/sentinvoices/${icPartnerId}?page=${page}${parameters}`)
        .ok(res => res.status === 200)
        .then(response => response.body)
        .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static searchInvoiceNumber(invoiceNumber: string, page = 1) {
        if (!invoiceNumber) return new Promise(() => { return; }).then(() => []);
        return AuthorizedRequest.getAuthorizedRequest(`invoice/searchinvoicenumber/${page}/${encodeURIComponent(btoa(invoiceNumber.padEnd(5, '%')))}`)
            .ok(res => res.status === 200)
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static getTsCodes(search: string, page = 1, month: number = (new Date().getMonth() +1 ), year: number = new Date().getFullYear(), showOldAndReadOnly = false, showAllAliases = false) {
        if (!search) return new Promise(() => { return; }).then(() => []);
        return AuthorizedRequest.getAuthorizedRequest(`timesheetcode/search?page=${page}&name=${encodeURIComponent(search.padEnd(5, '%'))}&month=${month}&year=${year}&showOldAndReadOnly=${showOldAndReadOnly ? '1' : '0'}&showAllAliases=${showAllAliases ? '1' : '0'}`)
            .ok(res => res.status === 200)
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }
}

export default InvoiceApi;
