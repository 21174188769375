import MobileClosableModal from '../../common/MobileClosableModal';
import React from 'react';

const TimesheetCompleteWarningModal = (props: Props) => {
    return (
        <MobileClosableModal title="COMPLETE TIMESHEET"
                       closeModal={props.closeModal}
                       marginBottom={30}>
            <div className="timesheet-complete-warning-month-year">Complete timesheet
                for {props.month} {props.year}?
            </div>
            <div className="timesheet-complete-warning-attention">Attention:</div>
            <div className="timesheet-complete-warning-message">There are currently {props.days} day(s) with
                less than 7.6 hours submitted.
            </div>
            <div className="timesheet-complete-warning-button-container">
                <input className="timesheet-complete-warning-complete" type="submit" value="COMPLETE"
                       onClick={props.complete}/>
                <div className="timesheet-complete-warning-cancel" onClick={props.closeModal}>CANCEL</div>
            </div>
        </MobileClosableModal>
    );
};

type Props = {
    days: number,
    month: string,
    year: number,

    closeModal: () => void,
    complete: () => void
};

export default TimesheetCompleteWarningModal;
