import {StoreSettings, StoreUser, StoreVisibleUser} from '../typescript/storeTypes';

class UserHelper {
    static isImpersonating(user: StoreUser): boolean {
        return user.employee.username !== user.impersonatedUser.username;
    }

    static getDefaultCompanyForTemplate = (user: StoreUser, settings: StoreSettings) => {
        if(UserHelper.isImpersonating(user)) {
            const impersonatedUser = (user.impersonatedUser as unknown as StoreVisibleUser);
            return { id: impersonatedUser.employerId, name: impersonatedUser.employer, isWritable: impersonatedUser.isWritable};
        }

        if(settings.defaultCompanyForTemplate) {
            return settings.defaultCompanyForTemplate;
        }

        return null;
    };

    static getDefaultCompanyNameForTemplate = (user: StoreUser, settings: StoreSettings) => {
        if(UserHelper.isImpersonating(user)) {
            return user.impersonatedUser.employer;
        }

        if(settings.defaultCompanyForTemplate && settings.defaultCompanyForTemplate.name)
        {
            return settings.defaultCompanyForTemplate.name;
        }
        return '';
    };
}

export default UserHelper;
