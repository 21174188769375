import {StoreBaseEmployeeName, StoreEmployee, StoreUser} from '../typescript/storeTypes';
import ObjectHelper from './objectHelper';

class StringHelper {
    static getUserFullName(user: StoreUser, firstNameLastName: boolean): string {
        const firstNameToUse = StringHelper.stringHasValue(user.impersonatedUser.nickName) ? `${user.impersonatedUser.firstName} (${user.impersonatedUser.nickName})` : user.impersonatedUser.firstName;
        if (user && user.impersonatedUser && user.impersonatedUser.firstName && user.impersonatedUser.lastName) {
            return firstNameLastName ?
                `${firstNameToUse} ${user.impersonatedUser.lastName}` :
                `${user.impersonatedUser.lastName} ${firstNameToUse}`;
        }

        return '';
    }

    static stringHasValue(string: string): boolean {
        string = string && string.trim();
        return string !== undefined && string !== null && string !== '';
    }

    static getEmployeeFullName(employee: StoreBaseEmployeeName, firstNameLastName: boolean, showUsername: boolean, showEmployer: boolean): string {
        if (employee && employee.firstName && employee.lastName) {
            const firstNameToUse = StringHelper.stringHasValue(employee.nickName) ? `${employee.firstName} (${employee.nickName})` : employee.firstName;

            let result =  firstNameLastName ?
                `${firstNameToUse} ${employee.lastName}` :
                `${employee.lastName} ${firstNameToUse}`;
                if(showUsername || showEmployer) {
                    const separator = showUsername && showEmployer ? ' ' : '';
                    result = `${result} (${showUsername ? employee.username : ''}${separator}${showEmployer ? employee.employer : ''})`;
                }
            return result;
        }

        return '';
    }

    static getAvatarText(user: StoreUser, firstNameLastName: boolean): string {
        if (user && user.impersonatedUser && user.impersonatedUser.firstName && user.impersonatedUser.lastName) {
            const firstNameToUse = user.impersonatedUser.nickName !== undefined ? `${user.impersonatedUser.firstName} (${user.impersonatedUser.nickName})` : user.impersonatedUser.firstName;

            return firstNameLastName ?
                `${ObjectHelper.charAt(firstNameToUse,0).toUpperCase()}${ObjectHelper.charAt(user.impersonatedUser.lastName, 0).toUpperCase()}` :
                `${ObjectHelper.charAt(user.impersonatedUser.lastName,0).toUpperCase()}${ObjectHelper.charAt(firstNameToUse, 0).toUpperCase()}`;
        }

        return '';
    }

    static getEmployeeAvatarText(employee: StoreEmployee, firstNameLastName: boolean): string {
        if (employee && employee.firstName && employee.lastName) {
            const firstNameToUse = employee.nickName !== undefined ? `${employee.firstName} (${employee.nickName})` : employee.firstName;

            return firstNameLastName ?
                `${ObjectHelper.charAt(firstNameToUse, 0).toUpperCase()}${ObjectHelper.charAt(employee.lastName, 0).toUpperCase()}` :
                `${ObjectHelper.charAt(employee.lastName, 0).toUpperCase()}${ObjectHelper.charAt(firstNameToUse, 0).toUpperCase()}`;
        }

        return '';
    }
}

export default StringHelper;
