import React from 'react';

class TimesheetWeekDay extends React.PureComponent<Props> {
    render() {
        return (
            <div className="timesheet-week-day">
                {this.props.weekDay}
            </div>
        );
    }
}

type Props = {
    weekDay: string
};

export default TimesheetWeekDay;
