export const ActionTypes = {
    FORCE_REFRESH_TOKEN_REQUESTED: 'FORCE_REFRESH_TOKEN_REQUESTED',

    USER_ADFS_LOGIN_REQUESTED: 'USER_ADFS_LOGIN_REQUESTED',
    USER_ADFS_LOGIN_SUCCEEDED: 'USER_ADFS_LOGIN_SUCCEEDED',
    USER_ADFS_LOGIN_FAILED: 'USER_ADFS_LOGIN_FAILED',

    USER_LOGIN_REQUESTED: 'USER_LOGIN_REQUESTED',
    USER_LOGIN_SUCCEEDED: 'USER_LOGIN_SUCCEEDED',
    USER_LOGIN_FAILED: 'USER_LOGIN_FAILED',

    USER_LOGOUT: 'USER_LOGOUT',

    PERMISSIONS_REQUESTED: 'PERMISSIONS_REQUESTED',
    PERMISSIONS_SUCCEEDED: 'PERMISSIONS_SUCCEEDED',
    PERMISSIONS_FAILED: 'PERMISSIONS_FAILED',

    VISIBLE_USERS_REQUESTED: 'VISIBLE_USERS_REQUESTED',
    VISIBLE_USERS_SUCCEEDED: 'VISIBLE_USERS_SUCCEEDED',
    VISIBLE_USERS_FAILED: 'VISIBLE_USERS_FAILED',

    VISIBLE_SUPPLIERS_REQUESTED : 'VISIBLE_SUPPLIERS_REQUESTED',
    VISIBLE_SUPPLIERS_SUCCEEDED : 'VISIBLE_SUPPLIERS_SUCCEEDED',
    VISIBLE_SUPPLIERS_FAILED : 'VISIBLE_SUPPLIERS_FAILED',

    INVOICEVIEWER_SUPPLIERS_REQUESTED : 'INVOICEVIEWER_SUPPLIERS_REQUESTED',
    INVOICEVIEWER_SUPPLIERS_SUCCEEDED : 'INVOICEVIEWER_SUPPLIERS_SUCCEEDED',
    INVOICEVIEWER_SUPPLIERS_FAILED : 'INVOICEVIEWER_SUPPLIERS_FAILED',

    USER_GROUPS_REQUESTED: 'USER_GROUPS_REQUESTED',
    USER_GROUPS_SUCCEEDED: 'USER_GROUPS_SUCCEEDED',
    USER_GROUPS_FAILED : 'USER_GROUPS_FAILED',

    CHANGE_USER_REQUESTED: 'CHANGE_USER_REQUESTED',

    RESET_PASSWORD_REQUESTED: 'RESET_PASSWORD_REQUESTED',
    RESET_PASSWORD_SUCCEEDED: 'RESET_PASSWORD_SUCCEEDED',

    USER_REQUESTED: 'USER_REQUESTED',
    USER_SUCCEEDED: 'USER_SUCCEEDED',
    USER_FAILED: 'USER_FAILED',

    TIMESHEET_REQUESTED: 'TIMESHEET_REQUESTED',
    TIMESHEET_REQUEST_SUCCEEDED: 'TIMESHEET_REQUEST_SUCCEEDED',
    TIMESHEET_REQUEST_FAILED: 'TIMESHEET_REQUEST_FAILED',

    ADD_ENTRY_REQUESTED: 'ADD_ENTRY_REQUESTED',
    ADD_ENTRY_SUCCEEDED: 'ADD_ENTRY_SUCCEEDED',
    ADD_ENTRY_FAILED: 'ADD_ENTRY_FAILED',

    UPDATE_ENTRY_REQUESTED: 'UPDATE_ENTRY_REQUESTED',
    UPDATE_ENTRY_SUCCEEDED: 'UPDATE_ENTRY_SUCCEEDED',
    UPDATE_ENTRY_FAILED: 'UPDATE_ENTRY_FAILED',

    DELETE_ENTRY_REQUESTED: 'DELETE_ENTRY_REQUESTED',
    DELETE_ENTRY_SUCCEEDED: 'DELETE_ENTRY_SUCCEEDED',
    DELETE_ENTRY_FAILED: 'DELETE_ENTRY_FAILED',

    UPDATE_SETTING: 'UPDATE_SETTING',

    OPEN_EXPORT_MONTHLY: 'OPEN_EXPORT_MONTHLY',
    OPEN_TIMESHEET_COMPLETE: 'OPEN_TIMESHEET_COMPLETE',
    CLOSE_HEADER_SCREENS: 'CLOSE_HEADER_SCREENS',
    OPEN_CHANGE_USER: 'OPEN_CHANGE_USER',
    OPEN_SETTINGS: 'OPEN_SETTINGS',
    OPEN_PROFILE: 'OPEN_PROFILE',

    TSCODES_REQUESTED: 'TSCODES_REQUESTED',
    TSCODES_SUCCEEDED: 'TSCODES_SUCCEEDED',
    TSCODES_FAILED: 'TSCODES_FAILED',

    GET_TIMESHEET_APPROVERS_REQUESTED: 'GET_TIMESHEET_APPROVERS_REQUESTED',
    GET_TIMESHEET_APPROVERS_SUCCEEDED: 'GET_TIMESHEET_APPROVERS_SUCCEEDED',
    GET_TIMESHEET_APPROVERS_FAILED: 'GET_TIMESHEET_APPROVERS_FAILED',

    TIMESHEET_COMPLETE_REQUESTED: 'TIMESHEET_COMPLETE_REQUESTED',
    TIMESHEET_COMPLETE_SUCCEEDED: 'TIMESHEET_COMPLETE_SUCCEEDED',
    TIMESHEET_COMPLETE_FAILED: 'TIMESHEET_COMPLETE_FAILED',

    TIMESHEETCODE_COMPLETE_REQUESTED: 'TIMESHEETCODE_COMPLETE_REQUESTED',
    TIMESHEETCODE_COMPLETE_SUCCEEDED: 'TIMESHEETCODE_COMPLETE_SUCCEEDED',
    TIMESHEETCODE_COMPLETE_FAILED: 'TIMESHEETCODE_COMPLETE_FAILED',

    TIMESHEETCODE_INCOMPLETE_REQUESTED: 'TIMESHEETCODE_INCOMPLETE_REQUESTED',
    TIMESHEETCODE_INCOMPLETE_SUCCEEDED: 'TIMESHEETCODE_INCOMPLETE_SUCCEEDED',
    TIMESHEETCODE_INCOMPLETE_FAILED: 'TIMESHEETCODE_INCOMPLETE_FAILED',

    TIMESHEETCODE_DELETE_REQUESTED: 'TIMESHEETCODE_DELETE_REQUESTED',
    TIMESHEETCODE_DELETE_SUCCEEDED: 'TIMESHEETCODE_DELETE_SUCCEEDED',
    TIMESHEETCODE_DELETE_FAILED: 'TIMESHEETCODE_DELETE_FAILED',

    TIMESHEET_INCOMPLETE_REQUESTED: 'TIMESHEET_INCOMPLETE_REQUESTED',
    TIMESHEET_INCOMPLETE_SUCCEEDED: 'TIMESHEET_INCOMPLETE_SUCCEEDED',
    TIMESHEET_INCOMPLETE_FAILED: 'TIMESHEET_INCOMPLETE_FAILED',

    ADD_ASSIGNMENT_REQUESTED: 'ADD_ASSIGNMENT_REQUESTED',
    ADD_ASSIGNMENT_SUCCEEDED: 'ADD_ASSIGNMENT_SUCCEEDED',
    ADD_ASSIGNMENT_FAILED: 'ADD_ASSIGNMENT_FAILED',

    TIMESHEET_CODE_EXPAND: 'TIMESHEET_CODE_EXPAND',

    GET_TSCODES_ICPARTNERS_REQUESTED: 'GET_TSCODES_ICPARTNERS_REQUESTED',
    GET_TSCODES_ICPARTNERS_SUCCEEDED: 'GET_TSCODES_ICPARTNERS_SUCCEEDED',
    GET_TSCODES_ICPARTNERS_FAILED: 'GET_TSCODES_ICPARTNERS_FAILED',

    MOST_RECENT_TSCODES_REQUESTED: 'MOST_RECENT_TSCODES_REQUESTED',
    MOST_RECENT_TSCODES_SUCCEEDED: 'MOST_RECENT_TSCODES_SUCCEEDED',
    MOST_RECENT_TSCODES_FAILED: 'MOST_RECENT_TSCODES_FAILED',

    CLEAR_TSCODES_REQUESTED: 'CLEAR_TSCODES_REQUESTED',

    SUPPLIERS_REQUESTED: 'SUPPLIERS_REQUESTED',
    SUPPLIERS_SUCCEEDED: 'SUPPLIERS_SUCCEEDED',
    SUPPLIERS_FAILED: 'SUPPLIERS_FAILED',


    GET_ALL_SUPPLIERS_REQUESTED: 'GET_ALL_SUPPLIERS_REQUESTED',
    GET_ALL_SUPPLIERS_SUCCEEDED: 'GET_ALL_SUPPLIERS_SUCCEEDED',
    GET_ALL_SUPPLIERS_FAILED: 'GET_ALL_SUPPLIERS_FAILED',

    SUPPLIERS_EMPLOYEES_REQUESTED: 'SUPPLIERS_EMPLOYEES_REQUESTED',
    SUPPLIERS_EMPLOYEES_SUCCEEDED: 'SUPPLIERS_EMPLOYEES_SUCCEEDED',
    SUPPLIERS_EMPLOYEES_FAILED: 'SUPPLIERS_EMPLOYEES_FAILED',

    CLEAR_SUPPLIERS_REQUESTED: 'CLEAR_SUPPLIERS_REQUESTED',
    CLEAR_SUPPLIERS_EMPLOYEES_REQUESTED: 'CLEAR_SUPPLIERS_EMPLOYEES_REQUESTED',

    EXPORT_MONTHLY_TIMESHEET_REQUESTED: 'EXPORT_MONTHLY_TIMESHEET_REQUESTED',
    EXPORT_MONTHLY_TIMESHEET_SUCCEEDED: 'EXPORT_MONTHLY_TIMESHEET_SUCCEEDED',
    EXPORT_MONTHLY_TIMESHEET_FAILED: 'EXPORT_MONTHLY_TIMESHEET_FAILED',

    EXPORT_MONTHLY_TIMESHEET_EXCEL_REQUESTED: 'EXPORT_MONTHLY_TIMESHEET_EXCEL_REQUESTED',
    EXPORT_MONTHLY_TIMESHEET_EXCEL_SUCCEEDED: 'EXPORT_MONTHLY_TIMESHEET_EXCEL_SUCCEEDED',
    EXPORT_MONTHLY_TIMESHEET_EXCEL_FAILED: 'EXPORT_MONTHLY_TIMESHEET_EXCEL_FAILED',

    EXPORT_WEEKLY_TIMESHEET_REQUESTED: 'EXPORT_WEEKLY_TIMESHEET_REQUESTED',
    EXPORT_WEEKLY_TIMESHEET_SUCCEEDED: 'EXPORT_WEEKLY_TIMESHEET_SUCCEEDED',
    EXPORT_WEEKLY_TIMESHEET_FAILED: 'EXPORT_WEEKLY_TIMESHEET_FAILED',

    EXPORT_WEEKLY_TIMESHEET_EXCEL_REQUESTED: 'EXPORT_WEEKLY_TIMESHEET_EXCEL_REQUESTED',
    EXPORT_WEEKLY_TIMESHEET_EXCEL_SUCCEEDED: 'EXPORT_WEEKLY_TIMESHEET_EXCEL_SUCCEEDED',
    EXPORT_WEEKLY_TIMESHEET_EXCEL_FAILED: 'EXPORT_WEEKLY_TIMESHEET_EXCEL_FAILED',

    CHECK_VERSION_REQUESTED: 'CHECK_VERSION_REQUESTED',
    CHECK_VERSION_SUCCEEDED: 'CHECK_VERSION_SUCCEEDED',
    CHECK_VERSION_FAILED: 'CHECK_VERSION_FAILED',

    GET_INCOMPLETE_TIMESHEETS_REQUESTED: 'GET_INCOMPLETE_TIMESHEETS_REQUESTED',
    GET_INCOMPLETE_TIMESHEETS_SUCCEEDED: 'GET_INCOMPLETE_TIMESHEETS_SUCCEEDED',
    GET_INCOMPLETE_TIMESHEETS_FAILED: 'GET_INCOMPLETE_TIMESHEETS_FAILED',

    MAINTENANCE_DETAILS_REQUESTED: 'MAINTENANCE_DETAILS_REQUESTED',
    MAINTENANCE_DETAILS_SUCCEEDED: 'MAINTENANCE_DETAILS_SUCCEEDED',
    MAINTENANCE_DETAILS_FAILED: 'MAINTENANCE_DETAILS_FAILED',

    SHOW_TOAST_MESSAGE: 'SHOW_TOAST_MESSAGE',
    DISMISS_TOAST_MESSAGE: 'DISMISS_TOAST_MESSAGE',

    EXPORT_ACTUALS_PDF_REQUESTED: 'EXPORT_ACTUALS_PDF_REQUESTED',
    EXPORT_ACTUALS_PDF_SUCCEEDED: 'EXPORT_ACTUALS_PDF_SUCCEEDED',
    EXPORT_ACTUALS_PDF_FAILED: 'EXPORT_ACTUALS_PDF_FAILED',

    EXPORT_ACTUALS_CSV_REQUESTED: 'EXPORT_ACTUALS_CSV_REQUESTED',
    EXPORT_ACTUALS_CSV_SUCCEEDED: 'EXPORT_ACTUALS_CSV_SUCCEEDED',
    EXPORT_ACTUALS_CSV_FAILED: 'EXPORT_ACTUALS_CSV_FAILED',

    EXPORT_ACTUALS_EXCEL_REQUESTED: 'EXPORT_ACTUALS_EXCEL_REQUESTED',
    EXPORT_ACTUALS_EXCEL_SUCCEEDED: 'EXPORT_ACTUALS_EXCEL_SUCCEEDED',
    EXPORT_ACTUALS_EXCEL_FAILED: 'EXPORT_ACTUALS_EXCEL_FAILED',

    EXPORT_TIMESHEETS_PDF_REQUESTED: 'EXPORT_TIMESHEETS_PDF_REQUESTED',
    EXPORT_TIMESHEETS_PDF_SUCCEEDED: 'EXPORT_TIMESHEETS_PDF_SUCCEEDED',
    EXPORT_TIMESHEETS_PDF_FAILED: 'EXPORT_TIMESHEETS_PDF_FAILED',

    EXPORT_TIMESHEETS_LANDSCAPE_PDF_REQUESTED: 'EXPORT_TIMESHEETS_LANDSCAPE_PDF_REQUESTED',
    EXPORT_TIMESHEETS_LANDSCAPE_PDF_SUCCEEDED: 'EXPORT_TIMESHEETS_LANDSCAPE_PDF_SUCCEEDED',
    EXPORT_TIMESHEETS_LANDSCAPE_PDF_FAILED: 'EXPORT_TIMESHEETS_LANDSCAPE_PDF_FAILED',

    EXPORT_TIMESHEETS_WEEKLY_PDF_REQUESTED: 'EXPORT_TIMESHEETS_WEEKLY_PDF_REQUESTED',
    EXPORT_TIMESHEETS_WEEKLY_PDF_SUCCEEDED: 'EXPORT_TIMESHEETS_WEEKLY_PDF_SUCCEEDED',
    EXPORT_TIMESHEETS_WEEKLY_PDF_FAILED: 'EXPORT_TIMESHEETS_WEEKLY_PDF_FAILED',

    EXPORT_TIMESHEETS_WEEKLY_EXCEL_REQUESTED: 'EXPORT_TIMESHEETS_WEEKLY_EXCEL_REQUESTED',
    EXPORT_TIMESHEETS_WEEKLY_EXCEL_SUCCEEDED: 'EXPORT_TIMESHEETS_WEEKLY_EXCEL_SUCCEEDED',
    EXPORT_TIMESHEETS_WEEKLY_EXCEL_FAILED: 'EXPORT_TIMESHEETS_WEEKLY_EXCEL_FAILED',

    EXPORT_TIMESHEETS_LANDSCAPE_EXCEL_REQUESTED: 'EXPORT_TIMESHEETS_LANDSCAPE_EXCEL_REQUESTED',
    EXPORT_TIMESHEETS_LANDSCAPE_EXCEL_SUCCEEDED: 'EXPORT_TIMESHEETS_LANDSCAPE_EXCEL_SUCCEEDED',
    EXPORT_TIMESHEETS_LANDSCAPE_EXCEL_FAILED: 'EXPORT_TIMESHEETS_LANDSCAPE_EXCEL_FAILED',

    VALIDATE_ACTUALS_REQUESTED: 'VALIDATE_ACTUALS_REQUESTED',
    VALIDATE_ACTUALS_SUCCEEDED: 'VALIDATE_ACTUALS_SUCCEEDED',
    VALIDATE_ACTUALS_FAILED: 'VALIDATE_ACTUALS_FAILED',
    CLEAR_VALIDATED_ACTUALS_REQUESTED: 'CLEAR_VALIDATED_ACTUALS_REQUESTED',

    PROCESS_IMPORT_ACTUALS_REQUESTED: 'PROCESS_IMPORT_ACTUALS_REQUESTED',
    PROCESS_IMPORT_ACTUALS_SUCCEEDED: 'PROCESS_IMPORT_ACTUALS_SUCCEEDED',
    PROCESS_IMPORT_ACTUALS_FAILED: 'PROCESS_IMPORT_ACTUALS_FAILED',

    GET_RELEASE_NOTES_REQUESTED: 'GET_RELEASE_NOTES_REQUESTED',
    GET_RELEASE_NOTES_SUCCEEDED: 'GET_RELEASE_NOTES_SUCCEEDED',
    GET_RELEASE_NOTES_FAILED: 'GET_RELEASE_NOTES_FAILED',

    SEARCH_INVOICE_REQUESTED: 'SEARCH_INVOICE_REQUESTED',
    SEARCH_INVOICE_SUCCEEDED: 'SEARCH_INVOICE_SUCCEEDED',
    SEARCH_INVOICE_FAILED: 'SEARCH_INVOICE_FAILED',

    GET_SUPPLIER_INVOICENUMBERS_REQUESTED: 'GET_SUPPLIER_INVOICENUMBERS_REQUESTED',
    GET_SUPPLIER_INVOICENUMBERS_SUCCEEDED: 'GET_SUPPLIER_INVOICENUMBERS_SUCCEEDED',
    GET_SUPPLIER_INVOICENUMBERS_FAILED: 'GET_SUPPLIER_INVOICENUMBERS_FAILED',

    CLEAR_INVOICE_REQUESTED: 'CLEAR_INVOICE_REQUESTED',

    CLEAR_SENT_INVOICES_REQUESTED: 'CLEAR_SENT_INVOICES_REQUESTED',

    GET_SENT_INVOICES_REQUESTED: 'GET_SENT_INVOICES_REQUESTED',
    GET_SENT_INVOICES_SUCCEEDED: 'GET_SENT_INVOICES_SUCCEEDED',
    GET_SENT_INVOICES_FAILED: 'GET_SENT_INVOICES_FAILED',

    GET_ASSIGNED_TIMESHEET_CODES_REQUESTED: 'GET_ASSIGNED_TIMESHEET_CODES_REQUESTED',
    GET_ASSIGNED_TIMESHEET_CODES_SUCCEEDED: 'GET_ASSIGNED_TIMESHEET_CODES_SUCCEEDED',
    GET_ASSIGNED_TIMESHEET_CODES_FAILED: 'GET_ASSIGNED_TIMESHEET_CODES_FAILED',

    UPLOAD_CLIENTTIMESHEET_REQUESTED: 'UPLOAD_CLIENTTIMESHEET_REQUESTED',
    UPLOAD_CLIENTTIMESHEET_SUCCEEDED: 'UPLOAD_CLIENTTIMESHEET_SUCCEEDED',
    UPLOAD_CLIENTTIMESHEET_FAILED: 'UPLOAD_CLIENTTIMESHEET_FAILED',

    SEND_MONTHLY_TO_SIGNING_HUB_REQUESTED: 'SEND_MONTHLY_TO_SIGNING_HUB_REQUESTED',
    SEND_MONTHLY_TO_SIGNING_HUB_SUCCEEDED: 'SEND_MONTHLY_TO_SIGNING_HUB_SUCCEEDED',
    SEND_MONTHLY_TO_SIGNING_HUB_FAILED: 'SEND_MONTHLY_TO_SIGNING_HUB_FAILED',

    SEND_MONTHLY_TO_SIGNING_HUB_IMPERSONATED_REQUESTED: 'SEND_MONTHLY_TO_SIGNING_HUB_IMPERSONATED_REQUESTED',
    SEND_MONTHLY_TO_SIGNING_HUB_IMPERSONATED_SUCCEEDED: 'SEND_MONTHLY_TO_SIGNING_HUB_IMPERSONATED_SUCCEEDED',
    SEND_MONTHLY_TO_SIGNING_HUB_IMPERSONATED_FAILED: 'SEND_MONTHLY_TO_SIGNING_HUB_IMPERSONATED_FAILED',

    SEND_WEEKLY_TO_SIGNING_HUB_REQUESTED: 'SEND_WEEKLY_TO_SIGNING_HUB_REQUESTED',
    SEND_WEEKLY_TO_SIGNING_HUB_SUCCEEDED: 'SEND_WEEKLY_TO_SIGNING_HUB_SUCCEEDED',
    SEND_WEEKLY_TO_SIGNING_HUB_FAILED: 'SEND_WEEKLY_TO_SIGNING_HUB_FAILED',

    SEND_WEEKLY_TO_SIGNING_HUB_IMPERSONATED_REQUESTED: 'SEND_WEEKLY_TO_SIGNING_HUB_IMPERSONATED_REQUESTED',
    SEND_WEEKLY_TO_SIGNING_HUB_IMPERSONATED_SUCCEEDED: 'SEND_WEEKLY_TO_SIGNING_HUB_IMPERSONATED_SUCCEEDED',
    SEND_WEEKLY_TO_SIGNING_HUB_IMPERSONATED_FAILED: 'SEND_WEEKLY_TO_SIGNING_HUB_IMPERSONATED_FAILED',

    CANCEL_SIGNING_HUB_QUEUE_REQUESTED: 'CANCEL_SIGNING_HUB_QUEUE_REQUESTED',
    CANCEL_SIGNING_HUB_QUEUE_SUCCEEDED: 'CANCEL_SIGNING_HUB_QUEUE_SUCCEEDED',
    CANCEL_SIGNING_HUB_QUEUE_FAILED: 'CANCEL_SIGNING_HUB_QUEUE_FAILED',

    CLEAR_INVOICE_NUMBERS_REQUESTED: 'CLEAR_INVOICENUMBERS_REQUESTED',
    CLEAR_INVOICE_NUMBERS_SUCCEEDED: 'CLEAR_INVOICE_NUMBERS_SUCCEEDED',

    SEARCH_INVOICENUMBERS_REQUESTED : 'SEARCH_INVOICENUMBERS_REQUESTED',
    SEARCH_INVOICENUMBERS_SUCCEEDED : 'SEARCH_INVOICENUMBERS_SUCCEEDED',
    SEARCH_INVOICENUMBERS_FAILED : 'SEARCH_INVOICENUMBERS_FAILED',

    GET_INVOICE_REQUESTED : 'GET_INVOICE_REQUESTED',
    GET_INVOICE_SUCCEEDED : 'GET_INVOICE_SUCCEEDED',
    GET_INVOICE_FAILED : 'GET_INVOICE_FAILED',

    GET_PRESETS_REQUESTED: 'GET_PRESETS_REQUESTED',
    GET_PRESETS_SUCCEEDED: 'GET_PRESETS_SUCCEEDED',
    GET_PRESETS_FAILED: 'GET_PRESETS_FAILED',

    LOAD_PRESET_REQUESTED: 'LOAD_PRESET_REQUESTED',

    SAVE_PRESET_REQUESTED: 'SAVE_PRESET_REQUESTED',
    SAVE_PRESET_SUCCEEDED: 'SAVE_PRESET_SUCCEEDED',
    SAVE_PRESET_FAILED: 'SAVE_PRESET_FAILED',

    DELETE_PRESET_REQUESTED: 'DELETE_PRESET_REQUESTED',
    DELETE_PRESET_SUCCEEDED: 'DELETE_PRESET_SUCCEEDED',
    DELETE_PRESET_FAILED: 'DELETE_PRESET_FAILED',

    CLEAR_ACTIVE_PRESET_REQUESTED: 'CLEAR_ACTIVE_PRESET_REQUESTED',

    CLEAR_TIME_LEFT_ON_ORDER_REQUESTED: 'CLEAR_TIME_LEFT_ON_ORDER_REQUESTED',
    CLEAR_TIME_LEFT_ON_ORDER_SUCCEEDED: 'CLEAR_TIME_LEFT_ON_ORDER_SUCCEEDED',

    POST_GET_TIME_LEFT_ON_ORDER_REQUESTED: 'POST_GET_TIME_LEFT_ON_ORDER_REQUESTED',
    POST_GET_TIME_LEFT_ON_ORDER_SUCCEEDED: 'POST_GET_TIME_LEFT_ON_ORDER_SUCCEEDED',
    POST_GET_TIME_LEFT_ON_ORDER_FAILED: 'POST_GET_TIME_LEFT_ON_ORDER_FAILED',

    GET_TIMESHEETS_FROM_CLIENT_INFO_REQUESTED: 'GET_TIMESHEETS_FROM_CLIENT_INFO_REQUESTED',
    GET_TIMESHEETS_FROM_CLIENT_INFO_SUCCEEDED: 'GET_TIMESHEETS_FROM_CLIENT_INFO_SUCCEEDED',
    GET_TIMESHEETS_FROM_CLIENT_INFO_FAILED: 'GET_TIMESHEETS_FROM_CLIENT_INFO_FAILED',

    GET_ASSIGNMENTDOCUMENT_REQUESTED: 'GET_ASSIGNMENTDOCUMENT_REQUESTED',
    GET_ASSIGNMENTDOCUMENT_SUCCEEDED: 'GET_ASSIGNMENTDOCUMENT_SUCCEEDED',
    GET_ASSIGNMENTDOCUMENT_FAILED: 'GET_ASSIGNMENTDOCUMENT_FAILED',

    DELETE_ASSIGNMENTDOCUMENT_REQUESTED : 'DELETE_ASSIGNMENTDOCUMENT_REQUESTED',
    DELETE_ASSIGNMENTDOCUMENT_SUCCEEDED : 'DELETE_ASSIGNMENTDOCUMENT_SUCCEEDED',
    DELETE_ASSIGNMENTDOCUMENT_FAILED : 'DELETE_ASSIGNMENTDOCUMENT_FAILED',

    GET_SIGNINGHUB_OVERVIEW_REQUESTED: 'GET_SIGNINGHUB_OVERVIEW_REQUESTED',
    GET_SIGNINGHUB_OVERVIEW_SUCCEEDED: 'GET_SIGNINGHUB_OVERVIEW_SUCCEEDED',
    GET_SIGNINGHUB_OVERVIEW_FAILED: 'GET_SIGNINGHUB_OVERVIEW_FAILED',

    CLEAR_SIGNINGHUB_OVERVIEW_REQUESTED: 'CLEAR_SIGNINGHUB_OVERVIEW_REQUESTED',

    GET_ALL_PROJECTS_REQUESTED: 'GET_ALL_PROJECTS_REQUESTED',
    GET_ALL_PROJECTS_SUCCEEDED: 'GET_ALL_PROJECTS_SUCCEEDED',
    GET_ALL_PROJECTS_FAILED: 'GET_ALL_PROJECTS_FAILED',

    GENERATE_PROJECT_REPORT_REQUESTED: 'GENERATE_PROJECT_REPORT_REQUESTED',
    GENERATE_PROJECT_REPORT_SUCCEEDED: 'GENERATE_PROJECT_REPORT_SUCCEEDED',
    GENERATE_PROJECT_REPORT_FAILED: 'GENERATE_PROJECT_REPORT_FAILED',
    CLEAR_PROJECTS_REQUESTED: 'CLEAR_PROJECTS_REQUESTED',

    GET_GROUPS_REQUESTED: 'GET_GROUPS_REQUESTED',
    GET_GROUPS_SUCCEEDED: 'GET_GROUPS_SUCCEEDED',
    GET_GROUPS_FAILED: 'GET_GROUPS_FAILED'
};
