import React from 'react';
import TimesheetHelper from '../../../../helpers/timesheetHelper';

import {StoreTimesheetCode, StoreTimesheetEntry} from '../../../../typescript/storeTypes';

class TimesheetFooterCode extends React.Component<Props> {
    shouldComponentUpdate(nextProps: Props) {
        if (nextProps.entry.normalHours !== this.props.entry.normalHours)
            return true;

        if (nextProps.entry.totalHours !== this.props.entry.totalHours)
            return true;

        return nextProps.entry.comments !== this.props.entry.comments;
    }

    onDetailView = () => {
        this.props.onDetailView(this.props.entry, this.props.timesheetCode);
    };

    getTimesheetCodeTotalClassName = () => {
        let className = 'timesheet-code-total';

        if (TimesheetHelper.hasExtraHours(this.props.entry))
            className += ' has-extra-hours';

        return className;
    };

    render() {
        return (
            <div className="timesheet-code" onClick={this.onDetailView}>
                <div className={`timesheet-code-name ${!this.props.timesheetCode.isTsCodeActive ? 'timesheetcode-inactive' : ''}`}>
                    {TimesheetHelper.formatTimesheetCodeMobile(this.props.timesheetCode.name)}
                </div>
                <div className={this.getTimesheetCodeTotalClassName()}>
                    {this.props.entry.comments ? <i className="fas fa-circle comment-indicator"/> : null}
                    <span>{+this.props.entry.totalHours.toFixed(2)}</span><label> hrs</label>
                    <i className="fas fa-angle-right expand-hours"/>
                </div>
            </div>
        );
    }
}

type Props = {
    timesheetCode: StoreTimesheetCode,
    entry: StoreTimesheetEntry,
    onDetailView: (entry: StoreTimesheetEntry, timesheetCode?: StoreTimesheetCode) => void
};

export default TimesheetFooterCode;
