import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';
import {InitialState} from './initialState';
import {StoreCalls} from '../typescript/storeTypes';

export default function (state: StoreCalls = InitialState.calls, action: Action): StoreCalls {
    switch (action.type) {
        case ActionTypes.TIMESHEET_COMPLETE_REQUESTED:
        case ActionTypes.TIMESHEET_INCOMPLETE_REQUESTED:
        case ActionTypes.TIMESHEETCODE_COMPLETE_REQUESTED:
        case ActionTypes.TIMESHEETCODE_INCOMPLETE_REQUESTED: {
            return Object.assign({}, state, {timesheetCompletionCalled: true});
        }
        case ActionTypes.TIMESHEETCODE_COMPLETE_SUCCEEDED:
        case ActionTypes.TIMESHEETCODE_INCOMPLETE_SUCCEEDED:
        case ActionTypes.TIMESHEET_COMPLETE_SUCCEEDED:
        case ActionTypes.TIMESHEET_INCOMPLETE_SUCCEEDED:
        case ActionTypes.TIMESHEET_COMPLETE_FAILED:
        case ActionTypes.TIMESHEET_INCOMPLETE_FAILED:
        case ActionTypes.TIMESHEETCODE_COMPLETE_FAILED:
        case ActionTypes.TIMESHEETCODE_INCOMPLETE_FAILED: {
            return Object.assign({}, state, {timesheetCompletionCalled: false});
        }
        case ActionTypes.USER_ADFS_LOGIN_REQUESTED:
        case ActionTypes.USER_LOGIN_REQUESTED: {
            return Object.assign({}, state, {loginRequested: true});
        }
        case ActionTypes.OPEN_EXPORT_MONTHLY: {
            return Object.assign({}, state, {exportMonthlyOpen: true});
        }
        case ActionTypes.OPEN_TIMESHEET_COMPLETE: {
            return Object.assign({}, state, {completeTimesheetOpen: true});
        }
        case ActionTypes.OPEN_CHANGE_USER: {
            return Object.assign({}, state, {changeUserOpen: true});
        }
        case ActionTypes.OPEN_SETTINGS: {
            return Object.assign({}, state, {settingsOpen: true});
        }
        case ActionTypes.OPEN_PROFILE: {
            return Object.assign({}, state, {profileOpen: true});
        }
        case ActionTypes.CLOSE_HEADER_SCREENS: {
            return Object.assign({}, state, {exportMonthlyOpen: false, completeTimesheetOpen: false, changeUserOpen: false, profileOpen: false, settingsOpen:false});
        }
        case ActionTypes.USER_LOGIN_SUCCEEDED:
        case ActionTypes.USER_ADFS_LOGIN_SUCCEEDED:
        case ActionTypes.USER_ADFS_LOGIN_FAILED:
        case ActionTypes.USER_LOGIN_FAILED: {
            return Object.assign({}, state, {loginRequested: false});
        }
        case ActionTypes.EXPORT_ACTUALS_CSV_REQUESTED:
        case ActionTypes.EXPORT_ACTUALS_EXCEL_REQUESTED:
        case ActionTypes.EXPORT_ACTUALS_PDF_REQUESTED:
        case ActionTypes.EXPORT_TIMESHEETS_PDF_REQUESTED:
        case ActionTypes.EXPORT_TIMESHEETS_WEEKLY_PDF_REQUESTED:
        case ActionTypes.EXPORT_TIMESHEETS_WEEKLY_EXCEL_REQUESTED:
        case ActionTypes.EXPORT_TIMESHEETS_LANDSCAPE_PDF_REQUESTED:
        case ActionTypes.EXPORT_TIMESHEETS_LANDSCAPE_EXCEL_REQUESTED:
        case ActionTypes.GENERATE_PROJECT_REPORT_REQUESTED: {
            return Object.assign({}, state, {reportRequested: true});
        }
        case ActionTypes.EXPORT_ACTUALS_CSV_SUCCEEDED:
        case ActionTypes.EXPORT_ACTUALS_CSV_FAILED:
        case ActionTypes.EXPORT_ACTUALS_EXCEL_SUCCEEDED:
        case ActionTypes.EXPORT_TIMESHEETS_LANDSCAPE_PDF_SUCCEEDED:
        case ActionTypes.EXPORT_TIMESHEETS_LANDSCAPE_PDF_FAILED:
        case ActionTypes.EXPORT_TIMESHEETS_LANDSCAPE_EXCEL_SUCCEEDED:
        case ActionTypes.EXPORT_TIMESHEETS_LANDSCAPE_EXCEL_FAILED:
        case ActionTypes.EXPORT_TIMESHEETS_WEEKLY_PDF_SUCCEEDED:
        case ActionTypes.EXPORT_TIMESHEETS_WEEKLY_PDF_FAILED:
        case ActionTypes.EXPORT_TIMESHEETS_WEEKLY_EXCEL_SUCCEEDED:
        case ActionTypes.EXPORT_TIMESHEETS_WEEKLY_EXCEL_FAILED:
        case ActionTypes.EXPORT_TIMESHEETS_PDF_SUCCEEDED:
        case ActionTypes.EXPORT_TIMESHEETS_PDF_FAILED:
        case ActionTypes.EXPORT_ACTUALS_EXCEL_FAILED:
        case ActionTypes.EXPORT_ACTUALS_PDF_SUCCEEDED:
        case ActionTypes.EXPORT_ACTUALS_PDF_FAILED:
        case ActionTypes.GENERATE_PROJECT_REPORT_SUCCEEDED:
        case ActionTypes.GENERATE_PROJECT_REPORT_FAILED: {
            return Object.assign({}, state, {reportRequested: false});
        }
        case ActionTypes.VALIDATE_ACTUALS_REQUESTED: {
            return Object.assign({}, state, {validateActualsRequested: true});
        }
        case ActionTypes.VALIDATE_ACTUALS_SUCCEEDED:
        case ActionTypes.VALIDATE_ACTUALS_FAILED: {
            return Object.assign({}, state, {validateActualsRequested: false});
        }
        case ActionTypes.PROCESS_IMPORT_ACTUALS_REQUESTED: {
            return Object.assign({}, state, {processImportActualsRequested: true});
        }
        case ActionTypes.PROCESS_IMPORT_ACTUALS_SUCCEEDED:
        case ActionTypes.PROCESS_IMPORT_ACTUALS_FAILED: {
            return Object.assign({}, state, {processImportActualsRequested: false});
        }
        case ActionTypes.SEARCH_INVOICE_REQUESTED: {
            return Object.assign({}, state, {invoiceRequested: !action.payload.download});
        }
        case ActionTypes.SEARCH_INVOICE_SUCCEEDED:
        case ActionTypes.SEARCH_INVOICE_FAILED: {
            return Object.assign({}, state, {invoiceRequested: false});
        }
        case ActionTypes.GET_SUPPLIER_INVOICENUMBERS_REQUESTED: {
            return Object.assign({}, state, {invoiceNumbersRequested: true});
        }
        case ActionTypes.GET_SUPPLIER_INVOICENUMBERS_SUCCEEDED:
        case ActionTypes.GET_SUPPLIER_INVOICENUMBERS_FAILED: {
            return Object.assign({}, state, {invoiceNumbersRequested: false});
        }
        default: {
            return state;
        }
    }
}
