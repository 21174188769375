import {AnyAction, Dispatch, bindActionCreators} from 'redux';
import {ConnectedProps, connect} from 'react-redux';
import {InitialState} from '../../../../reducers/initialState';
import { PermissionTypes } from '../../../../types/permissionTypes';
import {StoreState} from '../../../../typescript/storeTypes';
import {closeHeaderScreens, openTimesheetComplete} from '../../../../actions/callsActions';
import AccessibleUserHelper from '../../../../helpers/accessibleUserHelper';
import PermissionHelper from '../../../../helpers/permissionHelper';
import React from 'react';
import TimesheetHelper from '../../../../helpers/timesheetHelper';
import UserHelper from '../../../../helpers/userHelper';

export class TimesheetComplete extends React.Component<Props, State> {
    state: State = {
        canComplete: false
    };

    handleHeaderScreen = (): void => {
        if (this.props.calls.completeTimesheetOpen) {
            this.props.closeHeaderScreens();
        } else {
            if (this.props.calls.exportMonthlyOpen) {
                this.props.closeHeaderScreens();
            }

            this.props.openTimesheetComplete();
        }
    };

    static getDerivedStateFromProps(nextProps: Props, state: State) {
        const isImpersonating = UserHelper.isImpersonating(nextProps.user);

        const permissionTimesheetComplete = (isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.POST_TIMESHEET_COMPLETE)) || (!isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.POST_TIMESHEET_COMPLETE_CURRENT_USER));
        const permissionTimesheetCodeComplete = (isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.POST_ASSIGNMENT_COMPLETE)) || (!isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.POST_ASSIGNMENT_COMPLETE_CURRENT_USER));

        if(state.canComplete === permissionTimesheetComplete && permissionTimesheetCodeComplete) return null;

        return {canComplete: permissionTimesheetComplete && permissionTimesheetCodeComplete};
    }

    render() {
        return (
            this.props.user.impersonatedUser !== InitialState.user.impersonatedUser ?
            <div className="timesheet-complete-header">
                { !TimesheetHelper.isTimesheetComplete(this.props.timesheet) && this.state.canComplete && AccessibleUserHelper.isAccessibleUser(this.props.user) ?
                <div className="clickable-area" onClick={this.handleHeaderScreen}>
                    <img className="complete-check-mark" src="/images/check-mark.png"/>
                </div> : null
                }
            </div> : null
        );
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = MappedProps;

type State = {
    canComplete: boolean
};

function mapStateToProps(state: StoreState) {
    return {
        user: state.user,
        calls: state.calls,
        timesheet: state.timesheet
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({
        openTimesheetComplete,
        closeHeaderScreens
    }, dispatch);
}

export default connector(TimesheetComplete);
