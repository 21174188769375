import {Action} from '../../../../typescript/actionTypes';
import {Period} from '../../../../typescript/dateTypes';
import {StoreEmployee, StoreTimesheet, StoreTimesheetEntry,StoreUser} from '../../../../typescript/storeTypes';
import React from 'react';

import DateHelper from '../../../../helpers/dateHelper';
import SwitchMonth from './SwitchMonth';
import TimesheetHelper from '../../../../helpers/timesheetHelper';


class TimesheetNavigation extends React.PureComponent<Props> {
    calculateTotalHours = (): number => {
        let total = 0;

        for (const day of this.props.timesheet.period.days) {
            const entries = TimesheetHelper.getEntries(this.props.timesheet.timesheetCodes, day);
            total += entries.reduce((acc: number, cur: StoreTimesheetEntry) => acc + cur.totalHours, 0);
        }
        return total;
    };

    getTimesheet = (startDate: Date): void => {
        const newStartDate = DateHelper.firstDateOfMonth(startDate);
        const newEndDate = DateHelper.lastDateOfMonth(newStartDate);

        this.props.getTimesheet(DateHelper.getPeriod(newStartDate, newEndDate), this.props.user.impersonatedUser);
    };

    render() {
        return (
            <div className="timesheet-navigation">
                <i className="fas fa-angle-left navigate-left" onClick={this.props.getPreviousMonth}/>
                <div className="timesheet-navigation-month">
                    <SwitchMonth
                        period={this.props.timesheet.period}
                        getTimesheet={this.getTimesheet} />
                    <div className="totalHours-container">
                        <div className="totalHours">{this.calculateTotalHours().toFixed(2)}</div>
                        <div className="totalHoursText">Total hours this month</div>
                    </div>
                </div>
                <i className="fas fa-angle-right navigate-right" onClick={this.props.getNextMonth}/>
            </div>
        );
    }
}

type Props = {
    user: StoreUser,
    timesheet: StoreTimesheet,

    getPreviousMonth: () => void,
    getNextMonth: () => void,
    getTimesheet: (period: Period, employee: StoreEmployee) => Action
};

export default TimesheetNavigation;
