import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';
import {InitialState} from './initialState';
import {StoreActualsToValidate} from '../typescript/storeTypes';

export default function (state: StoreActualsToValidate = InitialState.validatedActuals, action: Action): StoreActualsToValidate {

    switch (action.type) {
        case ActionTypes.VALIDATE_ACTUALS_SUCCEEDED: {
            return (action.payload as StoreActualsToValidate);
        }
        case ActionTypes.VALIDATE_ACTUALS_FAILED: {
            return Object.assign({}, state, {errorMessage: `Something went wrong (${action.payload})`});
        }
        case ActionTypes.CLEAR_VALIDATED_ACTUALS_REQUESTED:
        case ActionTypes.PROCESS_IMPORT_ACTUALS_SUCCEEDED: {
            return InitialState.validatedActuals;
        }
        default:
            return state;
    }
}
