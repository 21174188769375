import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';

export function getMaintenanceDetails(): Action {
    return {type: ActionTypes.MAINTENANCE_DETAILS_REQUESTED, payload: null};
}

export function checkVersion(): Action {
    return {type: ActionTypes.CHECK_VERSION_REQUESTED, payload: null};
}
