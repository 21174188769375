import EmployeeSortHelper from '../../../../helpers/employeeSortHelper';
import React from 'react';
import StringHelper from '../../../../helpers/stringHelper';
import TimesheetReturn from '../../content/timesheet/TimesheetReturn';

import {StoreEmployee, StoreSettings, StoreVisibleUser} from '../../../../typescript/storeTypes';
import ScrollBar from 'react-custom-scrollbars-2';

class ChangeUserDetail extends React.Component<Props, State> {
    state: State = {
        searchString: ''
    };

    searchInput: HTMLInputElement | undefined;

    onChange = (event: React.SyntheticEvent<HTMLInputElement> & { currentTarget: HTMLInputElement }): void => {
        this.setState({searchString: event.currentTarget.value});
    };

    componentDidMount() {
        if (this.searchInput) {
            this.searchInput.focus();
        }
    }

    render() {
        return (
            <div className="change-user-detail">
                <TimesheetReturn title="CHANGE USER" onCloseDetailView={this.props.closeModal} />
                <div className="change-user">
                    <input ref={(searchInput: HTMLInputElement) => this.searchInput = searchInput}
                        className="change-user-input" type="text"
                        onChange={this.onChange} />
                    <div className="change-user-list">
                        <div className="change-user-list-item myself"
                            onClick={() => this.props.changeUser(this.props.me)}>
                            <div className="change-user-avatar">
                                <div className="no-select change-user-avatarFilling">
                                    {StringHelper.getEmployeeAvatarText(this.props.me, this.props.settings.firstNameLastName)}
                                </div>
                            </div>
                            <div className="no-select change-user-username">
                                {StringHelper.getEmployeeFullName(this.props.me, this.props.settings.firstNameLastName, false, false)}
                            </div>
                        </div>
                        <div className="scrollbar-container-changeuserdetail">
                            <ScrollBar>
                                {EmployeeSortHelper.findMatch(
                                    this.state.searchString,
                                    this.props.me.username,
                                    this.props.visibleUsers,
                                    (this.props.settings.firstNameLastName ? 'firstName' : 'lastname')
                                ).map(vu => {
                                    return (
                                        <div key={`${vu.username}${vu.employer}`}
                                            className="change-user-list-item"
                                            onClick={() => this.props.changeUser(vu)}>
                                            <div className="change-user-avatar">
                                                <div className="no-select change-user-avatarFilling">
                                                    {StringHelper.getEmployeeAvatarText(vu, this.props.settings.firstNameLastName)}
                                                </div>
                                            </div>
                                            <div className="no-select change-user-username">
                                                {StringHelper.getEmployeeFullName(vu, this.props.settings.firstNameLastName, false, true)}
                                            </div>
                                        </div>);
                                })}
                            </ScrollBar>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

type Props = {
    me: StoreEmployee,
    settings: StoreSettings,
    visibleUsers: StoreVisibleUser[],

    closeModal: () => void,
    changeUser: (user: StoreEmployee) => void
};

type State = {
    searchString: string
};

export default ChangeUserDetail;
