import {MaintenanceDetails} from '../typescript/maintenanceTypes';
import DateHelper from './dateHelper';

class MaintenanceHelper {
    static isMaintenanceModeConfigured = (maintenanceDetails: MaintenanceDetails): boolean => {
        const scheduleStartTime = new Date(maintenanceDetails.maintenanceScheduleStartTime);
        const now = new Date();

        return DateHelper.dayDiff(now, scheduleStartTime) === 0 && scheduleStartTime > now;
    };

    static getMaintenanceMessage = (maintenanceDetails: MaintenanceDetails): string => {
        return `The timesheet website will be under maintenance between ${DateHelper.getFormattedDateWithTime(maintenanceDetails.maintenanceScheduleStartTime,'/')} and ${DateHelper.getFormattedDateWithTime(maintenanceDetails.maintenanceScheduleEndTime,'/')}`;
    };
}

export default MaintenanceHelper;
