import React from 'react';

class TimesheetEntryHours extends React.PureComponent<Props> {
    render() {
        return (
            <div className="form-group">
                <input name={this.props.name} id={this.props.name}
                       className="form-control"
                       value={this.props.value}
                       onChange={this.props.onChange}
                       onBlur={this.props.onBlur}
                       onClick={this.props.onClick}
                       autoComplete="off"
                       disabled={this.props.isReadOnly}/>
                <label htmlFor={this.props.name}>{this.props.label}</label>
            </div>
        );
    }
}

type Props = {
    name: string,
    label: string,
    value: string,
    isReadOnly: boolean,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onBlur: (event: React.FocusEvent<HTMLInputElement>) => void,
    onClick: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void
};

export default TimesheetEntryHours;
