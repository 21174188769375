import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';


export function openExportMonthly(): Action {
    return {type: ActionTypes.OPEN_EXPORT_MONTHLY, payload: undefined};
}

export function openTimesheetComplete(): Action {
    return {type: ActionTypes.OPEN_TIMESHEET_COMPLETE, payload: undefined};
}

export function openChangeUser(): Action {
    return {type: ActionTypes.OPEN_CHANGE_USER, payload: undefined};
}

export function openSettings(): Action {
    return {type: ActionTypes.OPEN_SETTINGS, payload: undefined};
}

export function closeHeaderScreens(): Action {
    return {type: ActionTypes.CLOSE_HEADER_SCREENS, payload: undefined};
}

export function openProfile(): Action {
    return {type: ActionTypes.OPEN_PROFILE, payload: undefined};
}
