import {Day} from '../../typescript/dateTypes';
import { StoreTimesheet, StoreTimesheetCode } from '../../typescript/storeTypes';
import {createSelector} from 'reselect';
import DateHelper from '../../helpers/dateHelper';
import TimesheetHelper from '../../helpers/timesheetHelper';

const getTimesheet = (state: State) => state.timesheet;
const getDay = (state: State, props: Props) => props.day;

interface State {
    timesheet: StoreTimesheet
}

interface Props {
    day: Day
}

export const getAddableTimesheetCodes = createSelector([getTimesheet, getDay],
    (timesheet: StoreTimesheet, day: Day): StoreTimesheetCode[] => {
        const addableTimesheetCodes = [];

        for (const timesheetCode of timesheet.timesheetCodes) {
            if (TimesheetHelper.getEntry(timesheetCode, day) === TimesheetHelper.defaultEntry &&
                !timesheetCode.isReadOnly &&
                !TimesheetHelper.isTimesheetCodeCompleted(timesheetCode, DateHelper.getNormalizedDate(new Date(day.dateString)))) {
                addableTimesheetCodes.push(timesheetCode);
            }
        }
        return addableTimesheetCodes;
    }
);

export const getRemovableTimesheetCodes = createSelector([getTimesheet],
    (timesheet: StoreTimesheet): StoreTimesheetCode[] => {
        const removableTimesheetCodes = [];

        for (const timesheetCode of timesheet.timesheetCodes) {
            if(timesheetCode.entries.length === 0) {
                removableTimesheetCodes.push(timesheetCode);
            }
        }

        return removableTimesheetCodes;
    }
);
