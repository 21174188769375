import React from 'react';


export class RefreshToast extends React.PureComponent {
    refresh = () => {
        window.location.reload();
    };

    render() {
        return (
            <div>
                <div className="refresh-browser-message">You are currently running an outdated version of the website. Please refresh</div>
                <input className="refresh-button" type="submit" value="Refresh" onClick={this.refresh}/>
            </div>
        );
    }
}

export default RefreshToast;
