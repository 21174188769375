import ClosableModal from './ClosableModal';
import DefaultSubmit from './DefaultSubmit';
import React from 'react';

const AreYouSureModal = (props: Props) => {
    return (
        <ClosableModal title={props.title} closeModal={props.closeModal}>
            <div className="clear-fix button-container">
            {
                <React.Fragment>
                    <input className="ask-decline" type="submit" value="NO"
                            onClick={props.closeModal} />
                    <DefaultSubmit className="ask-accept" value="YES"
                        onSubmit={props.yes} />
                </React.Fragment>
            }
            </div>
        </ClosableModal>
    );
};

type Props = {
    title: string,

    yes: () => void,
    closeModal: () => void
};

export default AreYouSureModal;
