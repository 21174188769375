import {AnyAction, Dispatch,bindActionCreators} from 'redux';
import {ConnectedProps, connect} from 'react-redux';
import {getAllSuppliers} from '../../../../actions/supplierActions';
import {getPermissions, getUser, getVisibleUsers, loginAdfsUser, loginUser, resetPassword} from '../../../../actions/userActions';
import {updateSetting} from '../../../../actions/settingsActions';
import ComponentHelper from '../../../../helpers/componentHelper';
import LoginModal from './LoginModal';
import QueryString from 'query-string/';
import React from 'react';
import WindowHelper from '../../../../helpers/windowHelper';

import {MappedUserCredentials, UserCredentials} from '../../../../typescript/userTypes';

import { LocationTypes } from '../../../../types/locationTypes';
import { StoreCalls, StoreState, StoreUser} from '../../../../typescript/storeTypes';

export class Login extends React.Component<Props, State> {
    state: State = {
        calls: this.props.calls,
        user: this.props.user,
        credentials: {
            username: localStorage.getItem('login_Username') === null ? '' : (localStorage.getItem('login_Username') as any),
            password: ''
        },
        credentialsErrors: ComponentHelper.createStateErrorsObject({
            username: '',
            password: ''
        })
    };

    onChange = (event: React.SyntheticEvent<HTMLInputElement> & { currentTarget: HTMLInputElement }): void => {
        if (!this.props.calls.loginRequested) {
            const credentials = Object.assign({}, this.state.credentials);
            (credentials as {[key: string]: any})[event.currentTarget.name] = event.currentTarget.value;
            return this.setState({credentials});
        }
    };

    onSubmit = (event: React.MouseEvent<HTMLInputElement, MouseEvent>): void => {
        event.preventDefault();

        if (!this.props.calls.loginRequested) {
            const errors = ComponentHelper.createStateErrorsObject(this.state.credentials);

            if (!this.state.credentials.username) errors.username = 'Please enter a username.';
            if (!this.state.credentials.password) errors.password = 'Please enter a password.';

            if (Object.keys(errors).find(key => errors[key])) {
                return this.setState({credentialsErrors: errors});
            } else {
                this.setState({credentialsErrors: errors});
            }

            this.props.loginUser(this.state.credentials);
        }
    };

    adfsReturnedCheck = () => {
        const windowLocation = WindowHelper.getWindowLocation();
        if(windowLocation.pathname.toLocaleLowerCase() === LocationTypes.ADFS_LOGIN.toLocaleLowerCase() && !this.props.calls.loginRequested) {
            if(this.props.user.authenticatedUser.token === '') {
                const item = QueryString.parse(windowLocation.search);
                this.props.loginAdfsUser({
                    adfsToken: item.code as string,
                    clientId: process.env.MOBILE_CLIENT_ID,
                    resource : 'http://localhost/TiaApiTest',
                    redirectUri: process.env.MOBILE_REDIRECT_URI
                });
            } else {
                WindowHelper.setWindowLocation(LocationTypes.HOME);
            }
        }
    };

    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        if (!nextProps.calls.loginRequested && prevState.calls.loginRequested) {
            if (nextProps.user.authenticatedUser.token !== '' && prevState.user.authenticatedUser.username)
            {
                return {credentials: {username: prevState.credentials.username, password: ''}};
            }
            else {
                WindowHelper.setWindowLocation(LocationTypes.HOME);
            }
        }

        return null;
    }

    onComponentInit = (): void => {
        if (!this.props.calls.loginRequested && this.props.user.authenticatedUser.token && this.props.user.authenticatedUser.username) {
            this.props.getUser(this.props.user.authenticatedUser.username);
            this.props.getPermissions();
            this.props.getVisibleUsers();
            this.props.getAllSuppliers();
            window.scrollTo(0,0);
        }
    };

    componentDidMount() {
       this.onComponentInit();
    }

    componentDidUpdate(prevProps: Props): void {
        if(this.props.user.authenticatedUser.token !== prevProps.user.authenticatedUser.token)
        {
            this.onComponentInit();
        }
    }

    render() {
        if(!this.props.user.authenticationError) {
            this.adfsReturnedCheck();
        }
        return this.props.user.authenticatedUser.token ? null : (
            <div className="login">
                <LoginModal credentials={this.state.credentials} credentialsErrors={this.state.credentialsErrors}
                            onChange={this.onChange} onSubmit={this.onSubmit}
                            authenticationError={this.props.user.authenticationError}
                            updateSetting={this.props.updateSetting}
                            resetPassword={this.props.resetPassword} />
            </div>
        );
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = MappedProps;

type State = {
    credentials: UserCredentials,
    credentialsErrors: MappedUserCredentials,
    calls: StoreCalls,
    user: StoreUser
};

function mapStateToProps(state: StoreState) {
    return {
        user: state.user,
        calls: state.calls
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({
        loginUser,
        loginAdfsUser,
        getUser,
        getPermissions,
        getVisibleUsers,
        updateSetting,
        getAllSuppliers,
        resetPassword
    }, dispatch);
}

export default connector(Login);
