import {ActionTypes} from '../types/actionTypes';
import {saveAs} from 'file-saver';

import {Action} from '../typescript/actionTypes';
import {StoreEmptyObject} from '../typescript/storeTypes';

export default function (state: StoreEmptyObject = {}, action: Action): StoreEmptyObject {
    switch (action.type) {
        case ActionTypes.GET_ASSIGNMENTDOCUMENT_SUCCEEDED: {
            saveAs(action.payload.blob, action.payload.fileName);
            return state;
        }
        default:
            return state;
    }
}
