import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';
import {StoreAssignment, StoreUser} from '../typescript/storeTypes';
import {TimesheetCode} from '../typescript/timesheetTypes';
import UserHelper from '../helpers/userHelper';

export function addAssignment(assignment: StoreAssignment, user: StoreUser): Action {
    const payload = Object.assign({}, assignment, { useUsername: UserHelper.isImpersonating(user)});
    return {type: ActionTypes.ADD_ASSIGNMENT_REQUESTED, payload: payload };
}

export function completeTimesheetCode(timesheetCodeToComplete: TimesheetCode, user: StoreUser): Action {
    const payload = Object.assign({}, timesheetCodeToComplete, { useUsername: UserHelper.isImpersonating(user)});
    return {type: ActionTypes.TIMESHEETCODE_COMPLETE_REQUESTED, payload: payload};
}

export function incompleteTimesheetCode(timesheetCodeToIncomplete: TimesheetCode, user: StoreUser): Action {
    const payload = Object.assign({}, timesheetCodeToIncomplete, { useUsername: UserHelper.isImpersonating(user)});
    return {type: ActionTypes.TIMESHEETCODE_INCOMPLETE_REQUESTED, payload: payload};
}

export function deleteTimesheetCode(timesheetCodeToDelete: TimesheetCode, user: StoreUser): Action {
    const payload = Object.assign({}, timesheetCodeToDelete, { useUsername: UserHelper.isImpersonating(user)});
    return {type: ActionTypes.TIMESHEETCODE_DELETE_REQUESTED, payload: payload};
}

export function getAssignedTimesheetCodes(username: string, month: number, year: number): Action {
    const payload = Object.assign({}, { username: username, month: month, year: year});
    return {type: ActionTypes.GET_ASSIGNED_TIMESHEET_CODES_REQUESTED, payload: payload};
}

export function getTimesheetsFromClientInfo(assignmentId: number): Action {
    return {type: ActionTypes.GET_TIMESHEETS_FROM_CLIENT_INFO_REQUESTED, payload: assignmentId};
}
