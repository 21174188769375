import AuthorizedRequest from './authorizedRequests';
import ErrorHelper from '../helpers/errorHelper';

class ReleaseNoteApi {
    static getReleaseNotes() {
        return AuthorizedRequest.getAuthorizedRequest('releasenote')
            .ok(res => res.status === 200)
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }
}

export default ReleaseNoteApi;
