import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';
import {AdfsTokenModel, UserCredentials} from '../typescript/userTypes';
import {StoreEmployee} from '../typescript/storeTypes';


export function loginUser(credentials: UserCredentials): Action {
    return {type: ActionTypes.USER_LOGIN_REQUESTED, payload: credentials};
}

export function loginAdfsUser(token: AdfsTokenModel): Action {
    return {type: ActionTypes.USER_ADFS_LOGIN_REQUESTED, payload: token};
}

export function forceRefreshToken(): Action {
    return {type: ActionTypes.FORCE_REFRESH_TOKEN_REQUESTED, payload: null};
}

export function logoutUser(): Action {
    return {type: ActionTypes.USER_LOGOUT, payload: null};
}

export function getUser(username: string): Action {
    return {type: ActionTypes.USER_REQUESTED, payload: username};
}

export function getPermissions(): Action {
    return {type: ActionTypes.PERMISSIONS_REQUESTED, payload: null};
}

export function getVisibleUsers(): Action {
    return {type: ActionTypes.VISIBLE_USERS_REQUESTED, payload: null};
}

export function getVisibleSuppliers(): Action {
    return {type: ActionTypes.VISIBLE_SUPPLIERS_REQUESTED, payload: null};
}

export function getInvoiceViewerSuppliers(): Action {
    return {type: ActionTypes.INVOICEVIEWER_SUPPLIERS_REQUESTED, payload: null};
}

export function getActiveUsersWithGroups(groupIds?: number[]): Action {
    return {type: ActionTypes.USER_GROUPS_REQUESTED, payload: groupIds};
}

export function changeUser(user: StoreEmployee): Action {
    return {type: ActionTypes.CHANGE_USER_REQUESTED, payload: user};
}

export function resetPassword(username: string): Action {
    return {type: ActionTypes.RESET_PASSWORD_REQUESTED, payload: username};
}
