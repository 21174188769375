export const MessageTypes = {
    INFO: 'INFO',
    ERROR: 'ERROR',
    QUESTION_RELEASENOTES: 'QUESTION_RELEASENOTES'
};

export const Messages = {
    InactivePayroll: {type: MessageTypes.INFO, message: 'Your payroll is inactive, please contact your timesheet admin and ask them to correct your payroll data in Cronos Central / TIA.'},
    DeletedDocumentSuccessfully: {type: MessageTypes.INFO, message: 'The selected document was successfully deleted.'},
    UploadedDocumentSuccessfully: {type: MessageTypes.INFO, message: 'The selected document was successfully uploaded.'}
};
