import * as React from 'react';

class ErrorBoundary extends React.Component<Props, State> {
    state: State = {
        error: undefined
    };

    componentDidCatch(error: Error) {
        this.setState({ error });
    }

    render() {
        if (this.state.error) {
            return (
                <div className="wrong">
                    <img src="/images/wrong.png"/>
                    <h1>Oops</h1>
                    <div>We&apos;re sorry but something went horribly wrong. Try refreshing after a few minutes.</div>
                    <div className="wrong-error-message">{this.state.error.message}</div>
                </div>
            );
        } else {
            return (<React.Fragment>{this.props.children}</React.Fragment>);
        }
    }
}

type Props = {
    children: React.ReactElement[] | React.ReactElement
};

type State = {
    error: any
};

export default ErrorBoundary;
