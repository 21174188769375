import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';
import {call, put, takeEvery} from 'redux-saga/effects';
import supplierApi from '../api/supplierApi';

function* getSuppliers(action: Action): Generator<any, any, any> {
    try {
        const suppliers = yield call(supplierApi.getSuppliers, action.payload);
        yield put({type: ActionTypes.SUPPLIERS_SUCCEEDED, payload: suppliers});
    } catch (e) {
        const suppliers: any[] = [];
        yield put({type: ActionTypes.SUPPLIERS_FAILED, payload: suppliers});
    }
}

function* getAllSuppliers(): Generator<any, any, any> {
    try {
        const suppliers = yield call(supplierApi.getAllSuppliers);
        yield put({type: ActionTypes.GET_ALL_SUPPLIERS_SUCCEEDED, payload: suppliers});
    } catch (e) {
        const suppliers: any[] = [];
        yield put({type: ActionTypes.GET_ALL_SUPPLIERS_FAILED, payload: suppliers});
    }
}

function* getSuppliersEmployees(action: Action): Generator<any, any, any> {
    try {

        const employees = yield call(supplierApi.getSuppliersEmployees, action.payload);
        yield put({type: ActionTypes.SUPPLIERS_EMPLOYEES_SUCCEEDED, payload: employees});
    } catch (e) {
        const employees: any[] = [];
        yield put({type: ActionTypes.SUPPLIERS_EMPLOYEES_FAILED, payload: employees});
    }
}

function* getInvoiceNumbers(action: Action): Generator<any, any, any> {
    try {
        const invoiceNumbers = yield call(supplierApi.getInvoiceNumbers, action.payload.supplierId, action.payload.page);
        yield put({type: ActionTypes.GET_SUPPLIER_INVOICENUMBERS_SUCCEEDED, payload: invoiceNumbers});
    } catch (e) {
        const invoiceNumbers: any[] = [];
        yield put({type: ActionTypes.GET_SUPPLIER_INVOICENUMBERS_FAILED, payload: invoiceNumbers});
    }
}

function* supplierSaga(): Generator<any, any, any> {
    yield takeEvery(ActionTypes.SUPPLIERS_REQUESTED, getSuppliers);
    yield takeEvery(ActionTypes.GET_ALL_SUPPLIERS_REQUESTED, getAllSuppliers);
    yield takeEvery(ActionTypes.SUPPLIERS_EMPLOYEES_REQUESTED, getSuppliersEmployees);
    yield takeEvery(ActionTypes.GET_SUPPLIER_INVOICENUMBERS_REQUESTED, getInvoiceNumbers);
}

export default supplierSaga;
