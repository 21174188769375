import {StoreCalls, StoreTimesheet, StoreTimesheetCode} from '../../../../typescript/storeTypes';
import AreYouSureModal from '../../../../common/AreYouSureModal';
import DateHelper from '../../../../helpers/dateHelper';
import React from 'react';
import ScrollBar from 'react-custom-scrollbars-2';
import TimesheetCompleteWarningModal from '../../header/timesheetComplete/TimesheetCompleteWarningModal';
import TimesheetHelper from '../../../../helpers/timesheetHelper';
import TimesheetReturn from './TimesheetReturn';

class TimesheetCompleteDetail extends React.Component<Props, State> {
    state: State = {
        selectedTsCodes: [] as StoreTimesheetCode[],
        selectAllChecked: false,
        areYouSureModalOpen: false,
        days: 0
    };

    onSelectAll = (): void => {
        const selectedTsCodes = [];
        if (!this.state.selectAllChecked) {
            for (const tsCode of this.filteredTimesheetCodes()) {
                selectedTsCodes.push(tsCode);
            }

            this.setState({selectAllChecked: true});
        }
        else {
            this.setState({selectAllChecked: false});
        }

        this.setState({selectedTsCodes: selectedTsCodes});
    };

    complete = (): void => {
        if (this.state.selectAllChecked) {
            const days = TimesheetHelper.calculateDays(this.props.timesheet);
            if (days > 0) {
                this.setState({days: days});
                return;
            } else {
                this.props.completeTimesheet();
            }
        } else {
            this.setState({areYouSureModalOpen: true});
        }
    };

    onTimesheetCodeClick = (tsCode: StoreTimesheetCode): void => {
        if (this.state.selectedTsCodes.find(timesheetCode => timesheetCode.id === tsCode.id)) {
            const index = this.state.selectedTsCodes.indexOf(tsCode);
            this.state.selectedTsCodes.splice(index, 1);
        } else {
            this.state.selectedTsCodes.push(tsCode);
        }

        this.setState({
            selectedTsCodes: this.state.selectedTsCodes,
            selectAllChecked: this.state.selectedTsCodes.length === this.filteredTimesheetCodes().length
        });
    };

    filteredTimesheetCodes = (): StoreTimesheetCode[] => {
        return this.props.timesheet.timesheetCodes
        .filter(tsCode =>
            !tsCode.isReadOnly &&
            !TimesheetHelper.isTimesheetCodeCompletedEntries(tsCode, this.props.timesheet.period)
        );
    };

    completeTimesheetCodes = () => {
        this.closeAreYouSureModal();
        this.props.completeTimesheetCodes(this.state.selectedTsCodes);
    };

    closeAreYouSureModal = () => {
        this.setState({areYouSureModalOpen: false});
    };

    tryCompleteTimesheet = () => {
        const isComplete = this.props.timesheet.timesheetCodes.filter(tsCode => !tsCode.isReadOnly).length > 0
            && this.props.timesheet.timesheetCodes.findIndex(t =>
                !TimesheetHelper.isTimesheetCodeCompletedEntries(t, this.props.timesheet.period)) === -1;

        if (this.props.calls.timesheetCompletionCalled || isComplete) return;

        this.complete();
    };

    closeTimesheetWarningModal = () => {
        this.setState({days: 0});
    };

    canComplete = (): boolean => {
        const canSelectAll = this.canSelectAll();
        const tsCodesSelected: boolean = this.state.selectAllChecked || this.state.selectedTsCodes.length > 0;

        return (canSelectAll && tsCodesSelected);
    };

    canSelectAll = (): boolean => {
        const timesheetCodes = this.props.timesheet.timesheetCodes.filter(tsCode => !tsCode.isReadOnly);
        return (!(timesheetCodes.length === 0) && !this.props.calls.timesheetCompletionCalled);
    };

    render () {
        return (
            <div className="timesheet-complete-detail">
                <TimesheetReturn title="SELECT TIMESHEET CODE(S) TO COMPLETE"
                                 onCloseDetailView={this.props.closeModal} />
                <div className="timesheet-complete">
                    <div className="timesheet-complete-list">
                        <div className="scrollbar-container-timesheet-complete-list">
                            <ScrollBar>
                                {this.filteredTimesheetCodes().map(tsCode => {
                                    return (
                                        <div className="timesheet-code" key={tsCode.id} onClick={() => this.onTimesheetCodeClick(tsCode)}>
                                            <input className="timesheet-code-checkbox"
                                                onChange={() => this.onTimesheetCodeClick(tsCode)}
                                                type="checkbox"
                                                checked={this.state.selectedTsCodes.find(selected => selected.id === tsCode.id) !== undefined} />
                                            <div className="timesheet-code-name">{tsCode.name}</div>
                                        </div>
                                    );
                                })}
                            </ScrollBar>
                        </div>
                    </div>
                    <div className="timesheet-complete-actions">
                        <div className="button-spacing">
                            <input className={'timesheet-complete-select-all' + (this.canSelectAll() ? '' : ' disabled')}
                                   type="submit"
                                   onClick={this.onSelectAll}
                                   value={this.state.selectAllChecked ? 'DESELECT ALL' : 'SELECT ALL'} />
                        </div>
                        <input className={'timesheet-complete-complete' + (this.canComplete() ? '' : ' disabled')}
                               type="submit" value={this.state.selectAllChecked ? 'COMPLETE TIMESHEET' : 'COMPLETE'}
                               onClick={this.canComplete() ? this.tryCompleteTimesheet : () => { return; }}/>
                    </div>
                </div>
                {this.state.areYouSureModalOpen ?
                <AreYouSureModal title={'Are you sure you want to complete this assignment?'}
                                 yes={this.completeTimesheetCodes}
                                 closeModal={this.closeAreYouSureModal} /> : null}
                { this.state.days > 0 ? <TimesheetCompleteWarningModal closeModal={this.closeTimesheetWarningModal}
                                            days={this.state.days}
                                            complete={this.props.completeTimesheet}
                                            month={DateHelper.monthName(this.props.timesheet.period.month)}
                                            year={this.props.timesheet.period.year} /> : null }
            </div>
        );
    }
}

type State = {
    selectedTsCodes: StoreTimesheetCode[],
    selectAllChecked: boolean,
    areYouSureModalOpen: boolean,
    days: number
};

type Props = {
    calls: StoreCalls,
    closeModal: () => void,
    timesheet: StoreTimesheet,
    completeTimesheet: () => void,
    completeTimesheetCodes: (timesheetCodes: StoreTimesheetCode[]) => void
};

export default TimesheetCompleteDetail;
