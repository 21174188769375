import AuthorizedRequest from './authorizedRequests';

import {
    MonthlyTimesheetToExport,
    TimesheetToComplete,
    TimesheetToIncomplete,
    WeeklyTimesheetToExport
} from '../typescript/timesheetTypes';
import {Period} from '../typescript/dateTypes';
import {StoreEmployee, StoreTimesheetEntry} from '../typescript/storeTypes';
import {TimesheetEntryInfo} from '../typescript/infoTypes';
import {UploadClientTimesheet} from '../typescript/uploadTypes';
import DateHelper from '../helpers/dateHelper';
import ErrorHelper from '../helpers/errorHelper';

class TimesheetApi {
    static getTimesheet(period: Period, employee: StoreEmployee) {
        const formattedStartDate = DateHelper.getIsoFormattedDate(period.startDate);
        const formattedEndDate = DateHelper.getIsoFormattedDate(period.endDate);

        return AuthorizedRequest.getAuthorizedRequest(`timesheet/period/${encodeURIComponent(employee.username)}/${formattedStartDate}/${formattedEndDate}`)
            .ok(res => res.status === 200)
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static postTimesheetEntry(entry: StoreTimesheetEntry, entryInfo: TimesheetEntryInfo) {
        const newEntry = {
            tsCodeId: entryInfo.tsCodeId,
            workDate: entryInfo.workDate,
            normalHours: entry.normalHours,
            extraHours: entry.extraHours,
            interventionHours: entry.interventionHours,
            standByHours: entry.standByHours,
            comments: entry.comments ? entry.comments : null
        };

        return AuthorizedRequest.postAuthorizedRequest(`timesheet/entry/${encodeURIComponent(entryInfo.username)}`, newEntry)
            .ok(res => res.status === 201)
            .then(response => Object.assign({}, entry, {id: response.body.id, date: newEntry.workDate, modDate: new Date()}))
            .catch(ErrorHelper.showMessageAndRethrow());
    }

    static postTimesheetEntryCurrentUser(entry: StoreTimesheetEntry, entryInfo: TimesheetEntryInfo) {
        const newEntry = {
            tsCodeId: entryInfo.tsCodeId,
            workDate: entryInfo.workDate,
            normalHours: entry.normalHours,
            extraHours: entry.extraHours,
            interventionHours: entry.interventionHours,
            standByHours: entry.standByHours,
            comments: entry.comments ? entry.comments : null
        };

        return AuthorizedRequest.postAuthorizedRequest('timesheet/entry', newEntry)
            .ok(res => res.status === 201)
            .then(response => Object.assign({}, entry, {id: response.body.id, date: newEntry.workDate, modDate: new Date()}))
            .catch(ErrorHelper.showMessageAndRethrow());
    }

    static putTimesheetEntry(entry: StoreTimesheetEntry, entryInfo: TimesheetEntryInfo) {
        const updatedEntry = {
            normalHours: entry.normalHours,
            extraHours: entry.extraHours,
            interventionHours: entry.interventionHours,
            standByHours: entry.standByHours,
            comments: entry.comments ? entry.comments : null
        };

        return AuthorizedRequest.putAuthorizedRequest(`timesheet/entry/${encodeURIComponent(entryInfo.username)}/${entry.id}`, updatedEntry)
            .ok(res => res.status === 200)
            .then(() => Object.assign(entry, {modDate: new Date()}))
            .catch(error => {
                if (error.status === 403)
                {
                    throw new Error(error.response.statusText);
                }

                throw ErrorHelper.getCustomWebsiteError(error);
            });
    }

    static putTimesheetEntryCurrentUser(entry: StoreTimesheetEntry) {
        const updatedEntry = {
            normalHours: entry.normalHours,
            extraHours: entry.extraHours,
            interventionHours: entry.interventionHours,
            standByHours: entry.standByHours,
            comments: entry.comments ? entry.comments : null
        };

        return AuthorizedRequest.putAuthorizedRequest(`timesheet/entry/${entry.id}`, updatedEntry)
            .ok(res => res.status === 200)
            .then(() => Object.assign(entry, {modDate: new Date()}))
            .catch(error => {
                if (error.response && error.status === 403)
                {
                    throw new Error(error.response.statusText);
                }
                throw new Error(error.status + '|' + error.response.statusText);
            });
    }

    static deleteTimesheetEntry(entry: StoreTimesheetEntry, entryInfo: TimesheetEntryInfo) {
        return AuthorizedRequest.deleteAuthorizedRequest(`timesheet/entry/${encodeURIComponent(entryInfo.username)}/${entry.id}`)
            .ok(res => res.status === 200)
            .then(() => {
                return entry.id;
            })
            .catch(error => {
                if (error.response && error.status === 403)
                {
                    throw new Error(error.response.statusText);
                }

                throw ErrorHelper.getCustomWebsiteError(error);
            });
    }

    static deleteTimesheetEntryCurrentUser(entry: StoreTimesheetEntry) {
        return AuthorizedRequest.deleteAuthorizedRequest(`timesheet/entry/${entry.id}`)
            .ok(res => res.status === 200)
            .then(() => {
                return entry.id;
            })
            .catch(error => {
                if (error.response && error.status === 403)
                {
                    throw new Error(error.response.statusText);
                }

                throw ErrorHelper.getCustomWebsiteError(error);
            });
    }

    static postTimesheetComplete(timesheetToComplete: TimesheetToComplete) {
        return AuthorizedRequest.postAuthorizedRequest(`timesheet/complete/${encodeURIComponent(timesheetToComplete.username)}/${timesheetToComplete.period.month}/${timesheetToComplete.period.year}`, {})
            .ok(res => res.status === 200)
            .then(() => { return; })
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static postTimesheetCompleteCurrentUser(timesheetToComplete: TimesheetToComplete) {
        return AuthorizedRequest.postAuthorizedRequest(`timesheet/complete/${timesheetToComplete.period.month}/${timesheetToComplete.period.year}`, {})
            .ok(res => res.status === 200)
            .then(() => { return; })
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static postTimesheetIncomplete(timesheetToIncomplete: TimesheetToIncomplete) {
        return AuthorizedRequest.postAuthorizedRequest(`timesheet/incomplete/${encodeURIComponent(timesheetToIncomplete.username)}/${timesheetToIncomplete.period.month}/${timesheetToIncomplete.period.year}`, {})
            .ok(res => res.status === 200)
            .then(() => { return; })
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static postTimesheetIncompleteCurrentUser(timesheetToIncomplete: TimesheetToIncomplete) {
        return AuthorizedRequest.postAuthorizedRequest(`timesheet/incomplete/${timesheetToIncomplete.period.month}/${timesheetToIncomplete.period.year}`, {})
            .ok(res => res.status === 200)
            .then(() => { return; })
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static postExportMonthlyTimesheet(monthlyTimesheetToExport: MonthlyTimesheetToExport) {
        return AuthorizedRequest.postAuthorizedRequest('timesheet/export/pdf', monthlyTimesheetToExport)
            .ok(res => res.status === 200)
            .responseType('blob')
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static postExportMonthlyTimesheetExcel(monthlyTimesheetToExport: MonthlyTimesheetToExport) {
        return AuthorizedRequest.postAuthorizedRequest('timesheet/export/excel', monthlyTimesheetToExport)
            .ok(res => res.status === 200)
            .responseType('blob')
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static postExportWeeklyTimesheet(weeklyTimesheetToExport: WeeklyTimesheetToExport) {
        return AuthorizedRequest.postAuthorizedRequest('timesheet/exportweekly/pdf', weeklyTimesheetToExport)
            .ok(res => res.status === 200)
            .responseType('blob')
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static postExportWeeklyTimesheetExcel(weeklyTimesheetToExport: WeeklyTimesheetToExport) {
        return AuthorizedRequest.postAuthorizedRequest('timesheet/exportweekly/excel', weeklyTimesheetToExport)
            .ok(res => res.status === 200)
            .responseType('blob')
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static postClientTimesheet(clientTimesheet: UploadClientTimesheet) {
        return AuthorizedRequest.postAuthorizedRequest(`timesheet/timesheetclient/${encodeURIComponent(clientTimesheet.username)}`, clientTimesheet)
            .ok(res => res.status === 201)
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static postClientTimesheetCurrentUser(clientTimesheet: UploadClientTimesheet) {
        return AuthorizedRequest.postAuthorizedRequest('timesheet/timesheetclient', clientTimesheet)
            .ok(res => res.status === 201)
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static getIncompleteTimesheets() {
        return AuthorizedRequest.getAuthorizedRequest('timesheet/incomplete')
            .ok(res => res.status === 200)
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static getSigningHubOverviewByAssignment(assignmentIds: number[]) {
        return AuthorizedRequest.getAuthorizedRequest(`digitalsigning/overview?assignmentIds=${assignmentIds.join(',')}`)
            .ok(res => res.status === 200)
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }
}

export default TimesheetApi;
