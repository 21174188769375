import MobileClosableModal from '../../common/MobileClosableModal';
import React from 'react';

const ProfileModal = (props: Props) => {
    return (
        <MobileClosableModal title={props.title} closeModal={props.closeModal}>
            {props.canUserChangeUser ? <div className="change-user menu-item" onClick={props.onChangeUserHandle}>CHANGE USER</div> : <div/>}
            <div className="settings menu-item" onClick={props.openSettings}>SETTINGS</div>
            <div className="logoff menu-item" onClick={props.logOff}>LOG OFF</div>
        </MobileClosableModal>
    );
};

type Props = {
    title?: string,
    canUserChangeUser: boolean,

    closeModal: () => void,
    logOff: () => void,
    openSettings: () => void,
    onChangeUserHandle: () => void
};

export default ProfileModal;
