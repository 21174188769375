import Modal from './Modal';
import React from 'react';

export class ClosableModal extends React.PureComponent<Props> {
    static defaultProps = {
        marginBottom: 0,
        className: ''
    };

    shouldClose = false;

    closeModal = (event: KeyboardEvent): void => {
        if (event.key === 'Escape' || event.key === 'Esc') {
            this.props.closeModal();
        }
    };

    mouseDownLogic = (event: React.SyntheticEvent<MouseEvent> & { target: HTMLElement }): void => {
        if (event.target.className === 'modal-forefront') {
            this.shouldClose = true;
        } else {
            this.shouldClose = false;
        }
    };

    closeOnClick = (event: React.SyntheticEvent<MouseEvent> & { target: HTMLElement }): void => {
        if (this.shouldClose && event.target.className === 'modal-forefront') {
            this.shouldClose = false;
            this.props.closeModal();
        }
    };

    render() {
        return (
            <Modal className={`${this.props.className}`}>
                <div className="closable-modal">
                    <div className="closable-modal-header" style={{marginBottom: this.props.marginBottom}}>
                        <div className="closable-modal-close-icon">
                            <i className="fas fa-times" aria-hidden="true"
                               onClick={this.props.closeModal}/>
                        </div>
                        <div className="closable-modal-title">{this.props.title}</div>
                    </div>
                    {this.props.children}
                </div>
            </Modal>
        );
    }

    componentDidMount() {
        document.body.addEventListener('keydown', this.closeModal);
        document.body.addEventListener('click', this.closeOnClick as any);
        document.body.addEventListener('mousedown', this.mouseDownLogic as any);
    }

    componentWillUnmount() {
        document.body.removeEventListener('keydown', this.closeModal);
        document.body.removeEventListener('click', this.closeOnClick as any);
        document.body.removeEventListener('mousedown', this.mouseDownLogic as any);
    }
}

type Props = {
    children: React.ReactElement[] | React.ReactElement,
    title?: string,
    marginBottom: number,
    className: string,

    closeModal: () => void,
    onClick?: () => void
};

export default ClosableModal;
