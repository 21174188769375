class HourHelper {

    static isValidHour(input: string): boolean {
        return /^([1-9]?[0-9]([.,][0-9]{0,2})?)?$/.test(input) || /^\d{1,2}:(([0-5]\d?)?)$/.test(input);
    }

    static getHoursInNumberFormat(input: string): string {
        if (!input) return input;

        input = input.replace(',','.');

        if(input.indexOf(':') === 1 && input.length === 4 ||
            input.indexOf(':') === 2 && input.length === 5) {

            const arr = input.split(':');
            input = (parseInt(arr[0], 10)*1 + parseInt(arr[1], 10)/60).toFixed(2).toString();
        }
        return input;
    }

    static getHoursAsText = (hours: number, emptyChar = ''): string => hours && hours !== 0 ? hours.toString() : emptyChar;
}

export default HourHelper;
