import React from 'react';

class TimesheetReturn extends React.PureComponent<Props> {
    render() {
        return (
            <div className="timesheet-return">
                <i className="fas fa-angle-left return" onClick={this.props.onCloseDetailView}/>
                <div className="message">{this.props.title}</div>
            </div>
        );
    }
}

type Props = {
    title: string,
    onCloseDetailView: () => void
};

export default TimesheetReturn;
