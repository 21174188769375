import AuthorizedRequest from './authorizedRequests';
import ErrorHelper from '../helpers/errorHelper';

class MaintenanceApi {
    static getMaintenanceDetails() {
        return AuthorizedRequest.getAuthorizedRequest('settings/maintenancemode')
            .ok(res => res.status === 200)
            .then(response => {
                return response.body;
            }).catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static checkVersion() {
        let version = '';
        if(process.env.BUILD_VERSION && process.env.BUILD_VERSION !== null) {
            version = process.env.BUILD_VERSION;
        }

        return AuthorizedRequest.getAuthorizedRequest(`settings/version/website/islatest/${version}`)
            .ok(res => res.status === 200)
            .then(response => {
                return response.body;
            }).catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }
}

export default MaintenanceApi;
