import Cookies from 'js-cookie';

class CookieHelper {

    static sessionCookiePaths = {
        'user.impersonatedUser' : { name: 'timesheetapp_user_impersonateduser'}
    };

    static cookiePaths = {
        'user.authenticatedUser' : { name: 'timesheetapp_user_authenticateduser'},
        'user.employee.username' : { name: 'timesheetapp_user_employee'},
        'settings' : { name: 'timesheetapp_settings'},
    };

    static expiresIn2Hours = 0.083;
    static expiresIn1Year = 365;

    static isInPaths(paths: any, name: string): boolean {
        for(const prop in paths) {
            if (paths[prop].name === name) {return true;}
        }
        return false;
    }

    static getPaths(): any {
        return Object.assign({}, {...this.cookiePaths}, {...this.sessionCookiePaths});
    }

    static getCookie(name: string): any {
        return Cookies.get(name);
    }

    static setCookie(name: string, value: string) {
        if (value === undefined || value === null) {
            Cookies.remove(name);
            return;
        }

        if(this.isInPaths(this.sessionCookiePaths, name)) {
            Cookies.set(name, value, {expires: this.expiresIn2Hours});
            return;
        }
        if(this.isInPaths(this.cookiePaths, name)) {
            Cookies.set(name, value, {expires: this.expiresIn1Year});
        }
    }
}

export default CookieHelper;
