import {ActionTypes} from '../types/actionTypes';
import {call, put, takeEvery} from 'redux-saga/effects';
import projectApi from '../api/projectApi';

import {Action} from '../typescript/actionTypes';
import { MessageTypes } from '../types/messageTypes';
import MessageHelper from '../helpers/messageHelper';

function* getAllProjects(): Generator<any, any, any> {
    try {
        const projects = yield call(projectApi.getAllProjects);
        yield put({type: ActionTypes.GET_ALL_PROJECTS_SUCCEEDED, payload: projects});
    } catch (e) {
        const projects: any[] = [];
        yield put({type: ActionTypes.GET_ALL_PROJECTS_FAILED, payload: projects});
    }
}

function* generateProjectReport(action: Action): Generator<any, any, any> {
    try {
        yield call(projectApi.generateProjectReport, action.payload);
        MessageHelper.showMessage({type: MessageTypes.INFO, message: 'Report successfully added to mail queue.'});
        yield put({type: ActionTypes.GENERATE_PROJECT_REPORT_SUCCEEDED, payload: null});
    } catch (e) {
        const report: any[] = [];
        yield put({type: ActionTypes.GENERATE_PROJECT_REPORT_FAILED, payload: report});
    }
}

function* projectSaga(): Generator<any, any, any> {
    yield takeEvery(ActionTypes.GET_ALL_PROJECTS_REQUESTED, getAllProjects);
    yield takeEvery(ActionTypes.GENERATE_PROJECT_REPORT_REQUESTED, generateProjectReport);
}
export default projectSaga;
