class WildCardHelper {
    static containsString(searchValue: string, value: string): boolean {
        if (!searchValue || searchValue.length === 0) {
            return false;
        }

        let searchValues = [];
        if(searchValue.indexOf('%') === -1) {
            searchValues = [searchValue];
        }
        else {
            searchValues = searchValue.replace(/^%|%$/, '').split('%');
        }

        for(const searchVal of searchValues) {
            if(value.toLowerCase().indexOf(searchVal.toLowerCase()) === -1) {
                return false;
            }
        }
        return true;
    }
}

export default WildCardHelper;
