import React from 'react';

class MaintenanceModeDisclaimer extends React.PureComponent {
    render() {
        return (
            <div className="maintenancemode-disclaimer">
                Maintenance mode is active
            </div>
        );
    }
}

export default MaintenanceModeDisclaimer;
