import {Store, applyMiddleware, createStore} from 'redux';
import reduxCookiesMiddleware, { getStateFromCookies } from 'redux-cookies-middleware';

import {InitialState} from '../reducers/initialState';
import {MessageTypes} from '../types/messageTypes';

import CookieHelper from '../helpers/cookieHelper';
import MessageHelper from '../helpers/messageHelper';
import assignmentSaga from '../sagas/assignmentSaga';
import createSagaMiddleware from 'redux-saga';
import documentSaga from '../sagas/documentSaga';
import groupSaga from '../sagas/groupSaga';
import importSaga from '../sagas/importSaga';
import invoiceSaga from '../sagas/invoiceSaga';
import maintenanceSaga from '../sagas/maintenanceSaga';
import presetSaga from '../sagas/presetSaga';
import projectSaga from '../sagas/projectSaga';
import reduxCatch from 'redux-catch';
import releaseNoteSaga from '../sagas/releaseNoteSaga';
import reportSaga from '../sagas/reportSaga';
import rootReducer from '../reducers/rootReducer';
import signingHubSaga from '../sagas/signingHubSaga';
import supplierSaga from '../sagas/supplierSaga';
import timesheetSaga from '../sagas/timesheetSaga';
import tsCodeSaga from '../sagas/tsCodeSaga';
import userSaga from '../sagas/userSaga';

import {Action} from '../typescript/actionTypes';
import {StoreState} from '../typescript/storeTypes';

let initialState = Object.assign({},  JSON.parse(JSON.stringify(InitialState)), {timesheet: InitialState.timesheet});

const paths = CookieHelper.getPaths();

initialState = getStateFromCookies(initialState, paths, (name: string) => CookieHelper.getCookie(name));

function errorHandler(error: Error) {
    MessageHelper.showMessage({type: MessageTypes.ERROR, message: error.message});
}

const sagaMiddleware = createSagaMiddleware();
export const store: Store<StoreState, Action> = (createStore)(
    rootReducer,
    initialState,
    applyMiddleware(reduxCookiesMiddleware(paths, { setCookie: (name: string,  value: string) => CookieHelper.setCookie(name, value)}),reduxCatch(errorHandler), sagaMiddleware)
);

sagaMiddleware.run(userSaga);
sagaMiddleware.run(timesheetSaga);
sagaMiddleware.run(signingHubSaga);
sagaMiddleware.run(tsCodeSaga);
sagaMiddleware.run(reportSaga);
sagaMiddleware.run(assignmentSaga);
sagaMiddleware.run(supplierSaga);
sagaMiddleware.run(maintenanceSaga);
sagaMiddleware.run(importSaga);
sagaMiddleware.run(releaseNoteSaga);
sagaMiddleware.run(invoiceSaga);
sagaMiddleware.run(presetSaga);
sagaMiddleware.run(documentSaga);
sagaMiddleware.run(projectSaga);
sagaMiddleware.run(groupSaga);
