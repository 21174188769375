import AuthorizedRequest from './authorizedRequests';
import ErrorHelper from '../helpers/errorHelper';

class TsCodeApi {
    static getTsCodes(search: string, page = 1, month: number = (new Date().getMonth() +1 ), year: number = new Date().getFullYear(), showOldAndReadOnly = false, showAllAliases = false) {
        if (!search) return new Promise(() => { return; }).then(() => []);
        return AuthorizedRequest.getAuthorizedRequest(`timesheetcode/search?page=${page}&name=${encodeURIComponent(search.padEnd(5, '%'))}&month=${month}&year=${year}&showOldAndReadOnly=${showOldAndReadOnly ? '1' : '0'}&showAllAliases=${showAllAliases ? '1' : '0'}`)
            .ok(res => res.status === 200)
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static getMostRecentTsCodes(username: string, month: number, year: number) {
        if (!username) return new Promise(() => { return; }).then(() => []);
        return AuthorizedRequest.getAuthorizedRequest(`timesheetcode/recent/${encodeURIComponent(username)}/${month}/${year}`)
            .ok(res => res.status === 200)
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static getTsCodesIcPartners(tsCodeId: string) {
        return AuthorizedRequest.getAuthorizedRequest(`timesheetcode/${tsCodeId}/icpartnersonorder`)
            .ok(res => res.status === 200)
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }
}

export default TsCodeApi;
