import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';
import {InitialState} from './initialState';

import {StoreProject, StoreProjects} from '../typescript/storeTypes';


export default function (state: StoreProjects = InitialState.projects, action: Action): StoreProjects {
    switch (action.type) {
        case ActionTypes.GET_ALL_PROJECTS_SUCCEEDED: {
            return Object.assign({}, state, { projects: action.payload as StoreProject[] });
        }
        case ActionTypes.CLEAR_PROJECTS_REQUESTED: {
            return Object.assign({}, state, { projects: [] as StoreProject[] });
        }
        default: {
            return state;
        }
    }
}
