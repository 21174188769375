import DateHelper from '../../../../helpers/dateHelper';
import HourHelper from '../../../../helpers/hourHelper';
import React from 'react';
import TextAreaInput from '../../../../common/TextAreaInput';
import TimesheetEntryHours from './TimesheetEntryHours';
import TimesheetEntryOptions from './TimesheetEntryOptions';
import TimesheetHelper from '../../../../helpers/timesheetHelper';

import {Day} from '../../../../typescript/dateTypes';
import {StoreTimesheetCode, StoreTimesheetEntry} from '../../../../typescript/storeTypes';
import ObjectHelper from '../../../../helpers/objectHelper';

class TimesheetEntry extends React.PureComponent<Props, State> {
    state: State = {
        entry: Object.assign({}, this.props.entry),
        normalHours: this.props.entry.normalHours ? this.props.entry.normalHours.toString() : '',
        extraHours: this.props.entry.extraHours ? this.props.entry.extraHours.toString() : '',
        standByHours: this.props.entry.standByHours ? this.props.entry.standByHours.toString() : '',
        interventionHours: this.props.entry.interventionHours ? this.props.entry.interventionHours.toString() : '',
        additionalHours: TimesheetHelper.hasExtraHours(this.props.entry)
    };

    onChange = (event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>): void => {
        if (event.currentTarget.name === 'comments') {
            const entry = Object.assign({}, this.state.entry);
            entry.comments = event.currentTarget.value;
            this.setState({entry});
        } else {
            if (event.currentTarget.value.length === 1 && (ObjectHelper.charAt(event.currentTarget.value, 0) === ',' || ObjectHelper.charAt(event.currentTarget.value, 0) === '.')) {
                event.currentTarget.value = '0.';
            }

            if (HourHelper.isValidHour(event.currentTarget.value)) {

                event.currentTarget.value = HourHelper.getHoursInNumberFormat(event.currentTarget.value);

                const entry = Object.assign({}, this.state.entry);
                entry[event.currentTarget.name] = event.currentTarget.value ? parseFloat(event.currentTarget.value) : 0;
                entry.totalHours = entry.normalHours + entry.extraHours + entry.standByHours + entry.interventionHours;

                if (entry[event.currentTarget.name] !== this.state.entry[event.currentTarget.name]) {
                    this.setState({entry} as any);
                }

                this.setState({[event.currentTarget.name]: event.currentTarget.value} as any);
            }
        }
    };

    onBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
        if (event.currentTarget.value.endsWith('.')) {
            event.currentTarget.value = event.currentTarget.value.slice(0, -1);
            this.setState({[event.currentTarget.name]: event.currentTarget.value} as any);
        }
    };

    onSubmit = (event: React.MouseEvent<HTMLInputElement, MouseEvent>): void => {
        event.preventDefault();
        if (this.props.timesheetCode) {
            this.props.onSubmit(this.state.entry, this.props.timesheetCode);
        }
    };

    onAdditionalHours = (): void => {
        this.setState({additionalHours: true});
    };

    onSelect = (event: React.MouseEvent<HTMLInputElement, MouseEvent>): void => {
        event.currentTarget.select();
    };

    onRemove = () => {
        this.props.onRemoveTimesheetCode();
    };

    isCommentRequired = (): boolean => {
        return !!this.props.timesheetCode && this.props.timesheetCode.commentMandatory && !this.state.entry.comments
                    && !!(this.state.normalHours || this.state.extraHours ||
                          this.state.standByHours || this.state.interventionHours);
    };

    isReadOnly = ():boolean => {
        if(this.props.timesheetCode)
         return !this.props.timesheetCode.isTsCodeActive || this.props.timesheetCode.isReadOnly;

        return true;
    };

    render() {
        return (
            <form className="timesheet-entry">
                {this.props.entry !== TimesheetHelper.defaultEntry ?
                <div className="timesheet-entry-code">
                    {this.props.timesheetCode && this.props.timesheetCode.name}
                </div> :
                <TimesheetEntryOptions day={this.props.day}
                                       onDetailView={this.props.onDetailView}/>}
                {this.props.timesheetCode ? <div className="timesheet-entry-inputs">
                    {this.state.additionalHours ? <div className="hours">
                        <TimesheetEntryHours name="normalHours"
                                             value={this.state.normalHours}
                                             isReadOnly={this.isReadOnly()}
                                             label={'Normal hours'}
                                             onChange={this.onChange}
                                             onBlur={this.onBlur}
                                             onClick={this.onSelect}/>
                        <TimesheetEntryHours name="extraHours"
                                             value={this.state.extraHours}
                                             isReadOnly={this.isReadOnly()}
                                             label="Extra hours"
                                             onChange={this.onChange}
                                             onBlur={this.onBlur}
                                             onClick={this.onSelect}/>
                        <TimesheetEntryHours name="standByHours"
                                             value={this.state.standByHours}
                                             isReadOnly={this.isReadOnly()}
                                             label="Standby hours"
                                             onChange={this.onChange}
                                             onBlur={this.onBlur}
                                             onClick={this.onSelect}/>
                        <TimesheetEntryHours name="interventionHours"
                                             value={this.state.interventionHours}
                                             isReadOnly={this.isReadOnly()}
                                             label="Intervention hours"
                                             onChange={this.onChange}
                                             onBlur={this.onBlur}
                                             onClick={this.onSelect}/>
                    </div> : <div className="hours">
                        <TimesheetEntryHours name="normalHours"
                                             value={this.state.normalHours}
                                             isReadOnly={this.isReadOnly()}
                                             label={'Hours'}
                                             onChange={this.onChange}
                                             onBlur={this.onBlur}
                                             onClick={this.onSelect}/>
                        <div className="form-group additional-hours"
                             onClick={this.onAdditionalHours}>+ Additional hours
                        </div>
                    </div>}
                    <div className="comments">
                        <TextAreaInput name="comments"
                                       label="Add a comment"
                                       isReadOnly={this.isReadOnly()}
                                       labelExtra="(the client can also see this)"
                                       height="25vw"
                                       value={this.state.entry.comments}
                                       onChange={this.onChange}
                                       error={this.isCommentRequired() ? 'Comments are mandatory' : ''}/>
                    </div>
                </div> : null}
                <div className="timesheet-entry-actions">
                    {this.props.entry === TimesheetHelper.defaultEntry ?
                        <div className="button-spacing">
                            <input id="add" type="button" value="Add new timesheet code"
                                   onClick={this.props.onAddTimesheetCode}
                                   disabled={!this.props.canAddTimesheetCode} />
                        </div> : null}
                    {this.props.timesheetCode ?
                        <input id="done" type="submit" value="Done"
                            onClick={this.onSubmit}
                            disabled={(this.props.timesheetCode.isReadOnly
                                || TimesheetHelper.isTimesheetCodeCompleted(this.props.timesheetCode, DateHelper.getNormalizedDate(new Date(this.props.day.dateString)))
                                || this.isCommentRequired()
                                || this.props.isReadOnly
                                || !this.props.timesheetCode.isTsCodeActive)}/>
                        : <input id="remove" type="button" value="Remove timesheet code"
                                 onClick={this.onRemove}
                                 disabled={!this.props.canDeleteTimesheetCode} />}
                </div>
            </form>
        );
    }
}

type Props = {
    day: Day,
    entry: StoreTimesheetEntry,
    timesheetCode?: StoreTimesheetCode,
    isReadOnly: boolean,
    canAddTimesheetCode: boolean,
    canDeleteTimesheetCode: boolean,

    onSubmit: (entry: StoreTimesheetEntry, timesheetCode: StoreTimesheetCode) => void,
    onDetailView: (entry: StoreTimesheetEntry, timesheetCode?: StoreTimesheetCode) => void,
    onAddTimesheetCode: () => void,
    onRemoveTimesheetCode: () => void
};

type State = {
    entry: StoreTimesheetEntry,
    normalHours: string,
    extraHours: string,
    standByHours: string,
    interventionHours: string,
    additionalHours: boolean
};

export default TimesheetEntry;
