import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';
import {InitialState} from './initialState';
import {saveAs} from 'file-saver';

import DateHelper from '../helpers/dateHelper';

import {AddedAssignment} from '../typescript/assignmentTypes';
import {StoreMonthYear, StoreSigningHubOverview, StoreTimesheet, StoreTimesheetCode} from '../typescript/storeTypes';

export default function (state: StoreTimesheet = InitialState.timesheet, action: Action): StoreTimesheet {
    switch (action.type) {
        case ActionTypes.TIMESHEET_REQUEST_SUCCEEDED: {
            const timesheet: StoreTimesheet = action.payload;
            return Object.assign({}, timesheet, {
                incompleteMonths: [...state.incompleteMonths],
                timesheetCodes: timesheet.timesheetCodes.map(timesheetCode => {
                    const maxModDate = new Date(Math.max.apply(null, timesheetCode.entries.slice().map(t => new Date(t.modDate)) as unknown as number[]));

                    return Object.assign(timesheetCode, {
                        expanded: false,
                        entries: timesheetCode.entries.map(entry => {
                            return Object.assign(entry, {
                                date: DateHelper.createDateFromServerDate(entry.date).toDateString(),
                                totalHours: entry.normalHours + entry.extraHours + entry.standByHours + entry.interventionHours
                            });
                        }),
                        modDate: DateHelper.isInvalidDate(maxModDate) ? new Date() : maxModDate
                    });
                }),
                signingHubOverview: [...state.signingHubOverview]
            });
        }
        case ActionTypes.TIMESHEET_COMPLETE_SUCCEEDED: {
            //Remove month from incompletes if neccessary
            const completedMonthIndex = state.incompleteMonths.findIndex(im => im.month === state.period.month && im.year === state.period.year);
            const incompletedMonths = [...state.incompleteMonths];
            if (completedMonthIndex !== -1) {
                incompletedMonths.splice(completedMonthIndex, 1);
            }

            let updatedState = Object.assign({}, state, {
                timesheetCodes: state.timesheetCodes.map(timesheetCode => Object.assign({}, timesheetCode,
                    {
                        entries: timesheetCode.entries.map(entry => Object.assign({}, entry, {completed: true}))
                    })),
                    incompleteMonths: incompletedMonths
                });

            updatedState = Object.assign({}, updatedState, {
                timesheetCodes: updatedState.timesheetCodes.map(timesheetCode =>
                    {
                        const completedMonths: StoreMonthYear[] = [];
                        if (timesheetCode.entries.length > 0) {
                            timesheetCode.entries.forEach(function(entry) {
                                if(entry.completed && completedMonths.findIndex(cm => cm.month === DateHelper.getNormalizedDate(new Date(entry.date)).getMonth()+1 && cm.year === DateHelper.getNormalizedDate(new Date(entry.date)).getFullYear()) === -1) {
                                    completedMonths.push({month: DateHelper.getNormalizedDate(new Date(entry.date)).getMonth()+1, year: DateHelper.getNormalizedDate(new Date(entry.date)).getFullYear()});
                                }
                            });
                        }
                        else {
                            completedMonths.push({month: state.period.month, year: state.period.year});
                        }
                        return Object.assign({}, timesheetCode, {completedMonths: completedMonths});
                    }
                )
            });

            return updatedState;
        }
        case ActionTypes.TIMESHEET_INCOMPLETE_SUCCEEDED: {
            let updatedState = Object.assign({}, state, {
                timesheetCodes: state.timesheetCodes.map(timesheetCode => Object.assign({}, timesheetCode, {
                    entries: timesheetCode.entries.map(entry => Object.assign({}, entry, {completed: false}))
                })),
            });

            updatedState = Object.assign({}, updatedState, {
                timesheetCodes: updatedState.timesheetCodes.map(timesheetCode =>
                    {
                        const completedMonths: StoreMonthYear[] = [];
                        if (timesheetCode.entries.length > 0) {
                            timesheetCode.entries.forEach(function(entry) {
                                if(entry.completed && completedMonths.findIndex(cm => cm.month === DateHelper.getNormalizedDate(new Date(entry.date)).getMonth()+1 && cm.year === DateHelper.getNormalizedDate(new Date(entry.date)).getFullYear()) === -1) {
                                    completedMonths.push({month: DateHelper.getNormalizedDate(new Date(entry.date)).getMonth()+1, year: DateHelper.getNormalizedDate(new Date(entry.date)).getFullYear()});
                                }
                            });
                        }

                        return Object.assign({}, timesheetCode, {completedMonths: completedMonths});
                    }
                )
            });

            return updatedState;
        }
        case ActionTypes.TIMESHEETCODE_COMPLETE_SUCCEEDED: {
            const updatedTsCodeIndex = state.timesheetCodes.findIndex(timesheetCode => timesheetCode.id === action.payload.tsCodeId);

            const updatedState = Object.assign({}, state, {timesheetCodes: [...state.timesheetCodes]});

            updatedState.timesheetCodes[updatedTsCodeIndex] = Object.assign({}, updatedState.timesheetCodes[updatedTsCodeIndex], {
                entries: updatedState.timesheetCodes[updatedTsCodeIndex].entries.map(entry => Object.assign({}, entry, {completed: true})),
                completedMonths: [{month: state.period.month, year: state.period.year}]
            });

            return updatedState;
        }
        case ActionTypes.TIMESHEETCODE_INCOMPLETE_SUCCEEDED: {
            const updatedTsCodeIndex = state.timesheetCodes.findIndex(timesheetCode => timesheetCode.id === action.payload.tsCodeId);

            const updatedState = Object.assign({}, state, {timesheetCodes: [...state.timesheetCodes]});
            const index = updatedState.timesheetCodes[updatedTsCodeIndex].completedMonths.findIndex(cm => cm.month === state.period.month && cm.year === state.period.year);

            const completedMonths = updatedState.timesheetCodes[updatedTsCodeIndex].completedMonths.slice();
            if (index !== -1) {
                completedMonths.splice(index, 1);
            }
            updatedState.timesheetCodes[updatedTsCodeIndex] = Object.assign({}, updatedState.timesheetCodes[updatedTsCodeIndex], {
                entries: updatedState.timesheetCodes[updatedTsCodeIndex].entries.map(entry => Object.assign({}, entry, {completed: false})),
                completedMonths: completedMonths
            });

            return updatedState;
        }
        case ActionTypes.TIMESHEETCODE_DELETE_SUCCEEDED: {
            const deletedTsCodeIndex = state.timesheetCodes.findIndex(timesheetCode => timesheetCode.id === action.payload.tsCodeId);

            const updatedState = Object.assign({}, state, {timesheetCodes: [...state.timesheetCodes]});
            updatedState.timesheetCodes.splice(deletedTsCodeIndex, 1);

            return updatedState;
        }
        case ActionTypes.USER_LOGOUT: {
            return Object.assign({}, state, {timesheetCodes: []});
        }
        case ActionTypes.ADD_ENTRY_SUCCEEDED: {
            const updatedTsCodeIndex = state.timesheetCodes.findIndex(timesheetCode => timesheetCode.id === action.payload.entryInfo.tsCodeId);

            const updatedState = Object.assign({}, state, {timesheetCodes: [...state.timesheetCodes]});
            updatedState.timesheetCodes[updatedTsCodeIndex] = Object.assign({}, updatedState.timesheetCodes[updatedTsCodeIndex], {
                entries: [...updatedState.timesheetCodes[updatedTsCodeIndex].entries, action.payload.entry]
            });

            return updatedState;
        }
        case ActionTypes.UPDATE_ENTRY_SUCCEEDED: {
            const updatedTsCodeIndex = state.timesheetCodes.findIndex(timesheetCode => timesheetCode.id === action.payload.entryInfo.tsCodeId);
            const updatedEntryIndex = state.timesheetCodes[updatedTsCodeIndex].entries.findIndex(entry => entry.id === action.payload.entry.id);

            const updatedState = Object.assign({}, state, {timesheetCodes: [...state.timesheetCodes]});
            updatedState.timesheetCodes[updatedTsCodeIndex] = Object.assign({}, updatedState.timesheetCodes[updatedTsCodeIndex], {
                entries: [...updatedState.timesheetCodes[updatedTsCodeIndex].entries]
            });
            updatedState.timesheetCodes[updatedTsCodeIndex].entries[updatedEntryIndex] = action.payload.entry;

            return updatedState;
        }
        case ActionTypes.DELETE_ENTRY_SUCCEEDED: {
            const updatedTsCodeIndex = state.timesheetCodes.findIndex(timesheetCode => timesheetCode.id === action.payload.tsCodeId);
            const deletedEntryIndex = state.timesheetCodes[updatedTsCodeIndex].entries.findIndex(entry => entry.id === action.payload.entryId);

            const updatedState = Object.assign({}, state, {timesheetCodes: [...state.timesheetCodes]});
            updatedState.timesheetCodes[updatedTsCodeIndex] = Object.assign({}, updatedState.timesheetCodes[updatedTsCodeIndex], {
                entries: [...updatedState.timesheetCodes[updatedTsCodeIndex].entries]
            });

            updatedState.timesheetCodes[updatedTsCodeIndex].entries.splice(deletedEntryIndex, 1);

            return updatedState;
        }
        case ActionTypes.ADD_ASSIGNMENT_SUCCEEDED: {
            const assignment: AddedAssignment = action.payload;
            const newTsCode: StoreTimesheetCode = {
                id: assignment.tsCodeId,
                assignmentIds: assignment.assignmentIds,
                name: assignment.name,
                description: assignment.description,
                internalApprovers: assignment.internalApprovers,
                customerApprovers: assignment.customerApprovers,
                isReadOnly: false,
                isTsCodeActive: true,
                expanded: false,
                isVacationOrIllness: false,
                isHoliday: false,
                isBillable: assignment.isBillable,
                internalApprovalType: assignment.internalApprovalType,
                customerApprovalType: assignment.customerApprovalType,
                entries: [],
                completedMonths: [],
                commentMandatory: assignment.commentMandatory,
                modDate: new Date()
            };

            const firstReadOnlyTimesheetCodeIndex = state.timesheetCodes.findIndex(timesheetCode => timesheetCode.isReadOnly);
            let timesheetCodeIndex = state.timesheetCodes.findIndex(timesheetCode => timesheetCode.name >= newTsCode.name);
            const updatedState = Object.assign({}, state, {timesheetCodes: [...state.timesheetCodes]});

            if (firstReadOnlyTimesheetCodeIndex !== -1 && timesheetCodeIndex === -1) {
                timesheetCodeIndex = firstReadOnlyTimesheetCodeIndex;
            }

            if (timesheetCodeIndex === -1) {
                updatedState.timesheetCodes.push(newTsCode);
            }
            else {
                updatedState.timesheetCodes.splice(timesheetCodeIndex, 0, newTsCode);
            }

            return updatedState;
        }
        case ActionTypes.TIMESHEET_CODE_EXPAND: {
            const updatedTsCodeIndex = state.timesheetCodes.findIndex(timesheetCode => timesheetCode === action.payload);

            const updatedState = Object.assign({}, state, {timesheetCodes: [...state.timesheetCodes]});
            updatedState.timesheetCodes[updatedTsCodeIndex] = Object.assign({}, updatedState.timesheetCodes[updatedTsCodeIndex], {
                expanded: !updatedState.timesheetCodes[updatedTsCodeIndex].expanded
            });

            return updatedState;
        }
        case ActionTypes.GET_INCOMPLETE_TIMESHEETS_SUCCEEDED: {
            const updatedState = Object.assign({}, state, {incompleteMonths: action.payload});
            return updatedState;
        }
        case ActionTypes.EXPORT_MONTHLY_TIMESHEET_SUCCEEDED: {
            saveAs(action.payload, 'Monthly_Export.pdf');
            return state;
        }
        case ActionTypes.EXPORT_MONTHLY_TIMESHEET_EXCEL_SUCCEEDED: {
            saveAs(action.payload, 'Monthly_Export.xlsx');
            return state;
        }
        case ActionTypes.EXPORT_WEEKLY_TIMESHEET_SUCCEEDED: {
            saveAs(action.payload, 'Weekly_Export.pdf');
            return state;
        }
        case ActionTypes.EXPORT_WEEKLY_TIMESHEET_EXCEL_SUCCEEDED: {
            saveAs(action.payload, 'Weekly_Export.xlsx');
            return state;
        }
        case ActionTypes.EXPORT_ACTUALS_PDF_SUCCEEDED:
        case ActionTypes.EXPORT_ACTUALS_CSV_SUCCEEDED:
        case ActionTypes.EXPORT_ACTUALS_EXCEL_SUCCEEDED:
        case ActionTypes.EXPORT_TIMESHEETS_LANDSCAPE_PDF_SUCCEEDED:
        case ActionTypes.EXPORT_TIMESHEETS_LANDSCAPE_EXCEL_SUCCEEDED:
        case ActionTypes.EXPORT_TIMESHEETS_WEEKLY_PDF_SUCCEEDED:
        case ActionTypes.EXPORT_TIMESHEETS_WEEKLY_EXCEL_SUCCEEDED:
        case ActionTypes.EXPORT_TIMESHEETS_PDF_SUCCEEDED: {
            saveAs(action.payload.blob, action.payload.fileName);
            return state;
        }
        case ActionTypes.TIMESHEET_REQUEST_FAILED: {
            return Object.assign({}, state, {period: action.payload.period, timesheetCodes: []});
        }
        case ActionTypes.GET_SIGNINGHUB_OVERVIEW_SUCCEEDED: {
            const overview: StoreSigningHubOverview[] = action.payload;

            const updatedState = Object.assign({}, state, {signingHubOverview: overview});

            return updatedState;
        }
        case ActionTypes.GET_SIGNINGHUB_OVERVIEW_FAILED:
        case ActionTypes.CLEAR_SIGNINGHUB_OVERVIEW_REQUESTED: {
            const updatedState = Object.assign({}, state, {signingHubOverview: [] });

            return updatedState;
        }
        case ActionTypes.CANCEL_SIGNING_HUB_QUEUE_SUCCEEDED: {
            //const updatedIndex = state.signingHubOverview.findIndex(sho => sho.signingHubQueueId === action.payload);
            //const updatedOverviewItem = Object.assign({}, state.signingHubOverview[updatedIndex]);
            //updatedOverviewItem.status = 'Cancelling';
        //
            //const updatedState = Object.assign({}, state)
//
            //return updatedState;
        
            const updatedTsCodeIndex = state.signingHubOverview.findIndex(sho => sho.signingHubQueueId === action.payload);

            const updatedState = Object.assign({}, state, {signingHubOverview: [...state.signingHubOverview]});
            updatedState.signingHubOverview[updatedTsCodeIndex] = Object.assign({}, updatedState.signingHubOverview[updatedTsCodeIndex], {
                status: 'Cancelling'
            });

            return updatedState;
        }
        default: {
            return state;
        }
    }
}
