import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';
import {InitialState} from './initialState';
import {StoreGroups} from '../typescript/storeTypes';

export default function (state: StoreGroups = InitialState.group, action: Action): StoreGroups {
    switch (action.type) {
        case ActionTypes.GET_GROUPS_SUCCEEDED: {
            return {...state, groups: action.payload };
        }
        case ActionTypes.GET_GROUPS_FAILED:
            return {...state, groups: []};
        default:
            return state;
    }
}
