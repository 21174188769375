import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';
import {call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import tsCodeApi from '../api/tsCodeApi';

import { InitialState } from '../reducers/initialState';
import { StoreTsCodes } from '../typescript/storeTypes';

function* getTsCodes(action: Action): Generator<any, any, any> {
    try {
        const tsCodes = yield call(tsCodeApi.getTsCodes, action.payload.search, action.payload.page, action.payload.month, action.payload.year, action.payload.showOldAndReadOnly, action.payload.showAllAliases);
        if(action.payload.page === 1){
            yield put({type: ActionTypes.CLEAR_TSCODES_REQUESTED, payload: null});
        }
        yield put({type: ActionTypes.TSCODES_SUCCEEDED, payload: tsCodes});
    } catch (e) {
        const tsCodes: any[] = [];
        yield put({type: ActionTypes.TSCODES_FAILED, payload: tsCodes});
    }
}

function* getMostRecentTsCodes(action: Action): Generator<any, any, any> {
    try {
        const tsCodes = yield call(tsCodeApi.getMostRecentTsCodes, action.payload.username, action.payload.month, action.payload.year);
        yield put({type: ActionTypes.MOST_RECENT_TSCODES_SUCCEEDED, payload: tsCodes});
    } catch (e) {
        const tsCodes: any[] = [];
        yield put({type: ActionTypes.MOST_RECENT_TSCODES_FAILED, payload: tsCodes});
    }
}

function* getTsCodesIcPartners(action: Action): Generator<any, any, any> {
    try {
        yield put({type: ActionTypes.POST_GET_TIME_LEFT_ON_ORDER_SUCCEEDED, payload: InitialState.reporting.timeLeftOnOrder});
        if (action.payload === '0') {
            yield put({type: ActionTypes.GET_TSCODES_ICPARTNERS_SUCCEEDED, payload: []});
            yield put({type: ActionTypes.POST_GET_TIME_LEFT_ON_ORDER_SUCCEEDED, payload: InitialState.reporting.timeLeftOnOrder});
            return;
        }

        const icPartners = yield call(tsCodeApi.getTsCodesIcPartners, action.payload);
        yield put({type: ActionTypes.GET_TSCODES_ICPARTNERS_SUCCEEDED, payload: icPartners});
        yield put({type: ActionTypes.POST_GET_TIME_LEFT_ON_ORDER_SUCCEEDED, payload: InitialState.reporting.timeLeftOnOrder});
    } catch (e) {
        const icPartners = [] as StoreTsCodes;
        yield put({type: ActionTypes.GET_TSCODES_ICPARTNERS_FAILED, payload: icPartners});
    }
}

function* tsCodeSaga(): Generator<any, any, any> {
    yield takeLatest(ActionTypes.TSCODES_REQUESTED, getTsCodes);
    yield takeEvery(ActionTypes.MOST_RECENT_TSCODES_REQUESTED, getMostRecentTsCodes);
    yield takeEvery(ActionTypes.GET_TSCODES_ICPARTNERS_REQUESTED, getTsCodesIcPartners);
}

export default tsCodeSaga;
