import DateHelper from '../../../../helpers/dateHelper';
import React from 'react';

import {Day} from '../../../../typescript/dateTypes';

class TimesheetDay extends React.Component<Props> {
    shouldComponentUpdate(nextProps: Props) {
        if (nextProps.hasEntries !== this.props.hasEntries)
            return true;

        return nextProps.isActive !== this.props.isActive;
    }

    onNavigateDay = () => {
        this.props.onNavigateDay(this.props.dayIndex);
    };

    getDayClassName = () => {
        let className = 'day';

        if (DateHelper.isToday(this.props.day))
            className += ' today';

        if (this.props.isActive) {
            className += ' active';
        }

        return className;
    };

    render() {
        return (
            <div className="timesheet-day" onClick={this.onNavigateDay}>
                <div className={this.getDayClassName()}>
                    {this.props.day.dayOfMonth}
                    {this.props.hasEntries ? <i className="fas fa-circle entry-indicator"/> : null}
                </div>
            </div>
        );
    }
}

type Props = {
    day: Day,
    hasEntries: boolean,
    isActive: boolean,
    dayIndex: number,
    onNavigateDay: (dayIndex: number) => void
};

export default TimesheetDay;
