import * as SuperAgent from 'superagent';
import {ActionTypes} from '../types/actionTypes';
import {Store} from 'redux';
import DateHelper from '../helpers/dateHelper';

import {Action} from '../typescript/actionTypes';
import {SecureToken} from '../typescript/requestTypes';
import {StoreState} from '../typescript/storeTypes';


//#region API environment variables

if (!process.env.API_KEY || !process.env.API_ENDPOINT ||  !process.env.RESET_PASSWORD_ENDPOINT)
    throw new Error('API environment variables are not defined.');

const API_KEY = process.env.API_KEY;
const API_ENDPOINT = process.env.API_ENDPOINT;
const RESET_PASSWORD_ENDPOINT = process.env.RESET_PASSWORD_ENDPOINT;

//#endregion API environment variables

export default class AuthorizedRequest {
    static store: Store<StoreState, Action>;
    static setStore(store: Store<StoreState, Action>): void {
        AuthorizedRequest.store = store;
    }

    static getAccessToken(): SecureToken {
        let tokenResult = AuthorizedRequest.store.getState().user.authenticatedUser;
        if (tokenResult.token === '' || tokenResult.token === undefined)
        {
            return tokenResult;
        }
        
        const expiresOn = new Date(tokenResult.expiresOn);
        if (DateHelper.isExpired(expiresOn)) {
            AuthorizedRequest.store.dispatch({type: ActionTypes.USER_LOGIN_FAILED, payload: null});
            return tokenResult;
        }
        if (DateHelper.isExpiring(expiresOn)) {
            AuthorizedRequest.getRefreshToken(tokenResult).then((result: SecureToken) => {
                tokenResult = result;
                AuthorizedRequest.store.dispatch({type: ActionTypes.USER_LOGIN_SUCCEEDED, payload: tokenResult});
            });
        }

        return {
            token: tokenResult.token,
            username: tokenResult.username,
            isEmployee: tokenResult.isEmployee,
            hasActivePayroll: tokenResult.hasActivePayroll,
            expiresOn: expiresOn.toISOString(),
            scheme: tokenResult.scheme
        };
    }

    static forceRefreshToken(): void {
        let tokenResult = AuthorizedRequest.store.getState().user.authenticatedUser;
        if (tokenResult.token === '' || tokenResult.token === undefined) return;

        AuthorizedRequest.getRefreshToken(tokenResult).then((result: SecureToken) => {
            tokenResult = result;
            AuthorizedRequest.store.dispatch({type: ActionTypes.USER_LOGIN_SUCCEEDED, payload: tokenResult});
        });
    }

    static getRefreshToken(token: SecureToken) {
        const request = SuperAgent.post(API_ENDPOINT + 'token/refresh');
        request.set('Content-Type', 'application/json');
        request.set('X-ApiKey', API_KEY);
        request.set('Authorization', token.scheme + ' ' + token.token);

        return request
            .send({})
            .ok(res => res.status === 200)
            .then(response => {
                return response.body;
            })
            .catch(error => {
                throw new Error(error.status + '|' + error.statusText);
            });
    }

    static setRequestHeaders(request: SuperAgent.Request, content?: boolean) {
        const accessToken = AuthorizedRequest.getAccessToken();
        if (content) request.set('Content-Type', 'application/json');
        request.set('X-ignorehateoas', '1');
        request.set('X-ApiKey', API_KEY);
        request.set('Authorization', accessToken.scheme + ' ' + accessToken.token);
    }

    static getAuthorizedRequest(resource: string) {
        const request = SuperAgent.get(API_ENDPOINT + resource);
        this.setRequestHeaders(request);
        return request;
    }

    static postAuthorizedRequest(resource: string, data: Record<string, any>): SuperAgent.SuperAgentRequest {
        const request = SuperAgent.post(API_ENDPOINT + resource);
        this.setRequestHeaders(request, true);
        return request.send(data);
    }

    static putAuthorizedRequest(resource: string, data: Record<string, any>) {
        const request = SuperAgent.put(API_ENDPOINT + resource);
        this.setRequestHeaders(request, true);
        return request.send(data);
    }

    static deleteAuthorizedRequest(resource: string) {
        const request = SuperAgent.del(API_ENDPOINT + resource);
        this.setRequestHeaders(request, true);
        return request;
    }
    
    static resetPassword(emailAddress: string) {
        const request = SuperAgent.post(RESET_PASSWORD_ENDPOINT);
        return request.send({userName: emailAddress});
    }
}
