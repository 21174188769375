import { Period } from '../../../typescript/dateTypes';
import { usePopper } from 'react-popper';
import DateHelper from '../../../helpers/dateHelper';
import FocusTrap from 'focus-trap-react';
import React, {MouseEvent, useRef, useState} from 'react';

//#region Year

interface YearProps {
    year: number
}

function Year(props: YearProps) {
    return <div className="month-year-picker__year">
            {props.year}
        </div>;
}

//#endregion Year

//#region Month

interface MonthProps {
    caption: string,
    selectedMonth: Date|undefined,
    date: Date,
    minDate: Date,
    maxDate: Date,
    onClick?: (date: Date) => void
}

function Month(props: MonthProps) {
    const isDateOutOfRange = (): boolean => {
        const minDate = DateHelper.resetTime(DateHelper.firstDateOfMonth(props.minDate));
        const maxDate = DateHelper.resetTime(DateHelper.lastDateOfMonth(props.maxDate));
        return props.date < minDate || props.date > maxDate;
    };

    const onClick = (event: MouseEvent, date: Date) => {
        event.stopPropagation();
        if(isDateOutOfRange()) return;
        props.onClick?.(date);
    };

    const getStyling = () => {
        let styling = '';
        if(props.selectedMonth?.toDateString() === props.date.toDateString()) {
            styling = 'month-year-picker__month--selected';
        }

        if(isDateOutOfRange()) {
            styling += ' month-year-picker__month--disabled';
        }
        return styling;
    };

    return <div className={`month-year-picker__month ${getStyling()}`}
            onClick={(e) => onClick(e, props.date)}>
        {props.caption}
    </div>;
}

//#endregion Month

//#region MonthRow

interface MonthRowProps {
    months: string[],
    rowNumber: number,
    selectedMonth: Date|undefined,
    selectedYear: number,
    minDate: Date,
    maxDate: Date,
    onClick?: (date: Date) => void
}

function MonthRow(props: MonthRowProps) {
    return <div className="month-year-picker__month__row">
        {props.months.map((month, index) =>
            <Month key={`${month}-${index}`} caption={month}
                selectedMonth={props.selectedMonth}
                date={new Date(props.selectedYear, props.rowNumber * props.months.length + index, 1)}
                minDate={props.minDate}
                maxDate={props.maxDate}
                onClick={props.onClick} />
        )}
    </div>;
}

//#endregion MonthRow

export default function MonthYearPicker(props: Props) {

    const date = props.initialValue ? props.initialValue : new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const [selectedMonth, setSelectedMonth] = useState<Date>(props.value ?? date);
    const [selectedYear, setSelectedYear] = useState<number>(props.value?.getFullYear() ?? new Date().getFullYear());
    const [isPopperOpen, setIsPopperOpen] = useState(false);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

    const calculateMonth = (): Date => {
        const period = props.period as Period;
        const day = period.days.find(e => e.dayOfMonth === 1);
        if(day) {
            const firstOfMonth = new Date(day.dateString);
            return new Date(firstOfMonth.getFullYear(), firstOfMonth.getMonth(), 1);
        }

        return new Date(period.year, period.month - 1, 1);
    };

    if(props.period && props.hideWeekends !== undefined) {
        const pickedValue = calculateMonth();
        if(pickedValue.toDateString() !== selectedMonth.toDateString()) {
            setSelectedMonth(pickedValue);
            setSelectedYear(pickedValue.getFullYear());
        }
    } else if (props.value && props.value !== selectedMonth) {
        setSelectedMonth(props.value);
        setSelectedYear(props.value.getFullYear());
    }

    const buttonRef = useRef<HTMLButtonElement>(null);

    const popper = usePopper(buttonRef.current, popperElement, {
        placement: 'bottom-start'
    });

    const pickerLang = {
        months: [
            ['JAN', 'FEB', 'MAR'],
            ['APR', 'MAY', 'JUN'],
            ['JUL', 'AUG', 'SEP'],
            ['OCT', 'NOV', 'DEC']],
        monthsLong: [
            'JANUARY', 'FEBRUARY', 'MARCH',
            'APRIL','MAY', 'JUNE',
            'JULY', 'AUGUST', 'SEPTEMBER',
            'OCTOBER', 'NOVEMBER', 'DECEMBER']
    };

    const handleSelect = (date: Date) => {
        setSelectedMonth(date);
        setSelectedYear(date.getFullYear());
        closePopper();
        props.onChange?.(date.getMonth()+1, date.getFullYear());
    };

    const openPopper = () => {
        if(!props.isReadOnly) setIsPopperOpen(true);
    };

    const closePopper = () => {
        setIsPopperOpen(false);
    };

    const isMinYear = (): boolean => {
        return props.minDate.getFullYear() === selectedYear;
    };

    const isMaxYear = (): boolean => {
        return props.maxDate.getFullYear() === selectedYear;
    };

    return (
        <div className="month-year-picker" onClick={openPopper}>
            <button className={`month-year-picker__button ${props.isReadOnly ? 'month-year-picker__button--readonly' : ''}`} ref={buttonRef}>
                {pickerLang.monthsLong[selectedMonth.getMonth()]} {selectedMonth.getFullYear()}<i className="fas fa-caret-down" />
            </button>
            {isPopperOpen && (
                <FocusTrap active focusTrapOptions={{
                    clickOutsideDeactivates: true,
                    onDeactivate: closePopper,
                    fallbackFocus: buttonRef.current as any
                }}>
                    <div
                        tabIndex={-1}
                        style={popper.styles.popper}
                        className="dialog-sheet"
                        {...popper.attributes.popper}
                        ref={setPopperElement}
                        role="dialog"
                    >
                        <div className="month-year-picker__selector">
                            <div className={`month-year-picker__selector__previous ${isMinYear() ? 'month-year-picker__selector__previous--disabled' : ''}`} onClick={() => setSelectedYear(isMinYear() ? props.minDate.getFullYear() : selectedYear-1)}>&lt;</div>
                            <Year year={selectedYear} />
                            <div className={`month-year-picker__selector__next ${isMaxYear() ? 'month-year-picker__selector__next--disabled' : ''}`} onClick={() => setSelectedYear(isMaxYear() ? props.maxDate.getFullYear() : selectedYear+1)}>&gt;</div>
                        </div>

                        <div className="month-year-picker__month__container">
                            {pickerLang.months.map((monthNames:string[], i:number) =>
                                <MonthRow key={`monthrow-${i}`}
                                    months={monthNames}
                                    rowNumber={i}
                                    selectedMonth={selectedMonth}
                                    selectedYear={selectedYear}
                                    minDate={props.minDate}
                                    maxDate={props.maxDate}
                                    onClick={handleSelect}/>
                            )}
                        </div>
                    </div>
                </FocusTrap>
            )}
        </div>
    );
}

type Props = {
    initialValue?: Date | undefined,
    value?: Date | undefined,
    period?: Period,
    hideWeekends?: boolean,
    minDate: Date,
    maxDate: Date,
    isReadOnly?: boolean,
    onChange?: (month: number, year: number) => void
}
