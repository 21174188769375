import {combineReducers} from 'redux';
import assignedTimesheetCodes from './assignedTimesheetCodesReducer';
import calls from './callsReducer';
import documents from './documentReducer';
import group from './groupReducer';
import invoiceDetails from './invoiceReducer';
import maintenanceDetails from './maintenanceReducer';
import mostRecentTsCodes from './mostRecentTsCodeReducer';
import projects from './projectReducer';
import releaseNotes from './releaseNoteReducer';
import reporting from './reportReducer';
import settings from './settingsReducer';
import suppliers from './supplierReducer';
import timesheet from './timesheetReducer';
import timesheetsFromClient from './timesheetsFromClientReducer';
import tsCodes from './tsCodeReducer';
import user from './userReducer';
import userPresets from './presetReducer';
import validatedActuals from './validatedActualsReducer';

const rootReducer = combineReducers({
    user,
    timesheet,
    settings,
    tsCodes,
    mostRecentTsCodes,
    calls,
    suppliers,
    reporting,
    maintenanceDetails,
    validatedActuals,
    releaseNotes,
    invoiceDetails,
    assignedTimesheetCodes,
    userPresets,
    timesheetsFromClient,
    documents,
    projects,
    group
});

export default rootReducer;
