import {ActionTypes} from '../types/actionTypes';
import {call, put, takeEvery} from 'redux-saga/effects';
import assignmentApi from '../api/assignmentApi';

import {Action} from '../typescript/actionTypes';

function* postAssignment(action: Action): Generator<any, any, any> {
    try {
        let result;
        if(action.payload.useUsername)
        {
            result = yield call(assignmentApi.postAssignment, action.payload);
        }
        else {
            result = yield call(assignmentApi.postAssignmentCurrentUser, action.payload);
        }

        action.payload.assignmentIds = [result.id];
        action.payload.commentMandatory = result.commentMandatory;

        yield put({type: ActionTypes.ADD_ASSIGNMENT_SUCCEEDED, payload: action.payload});
    } catch (e) {
        yield put({type: ActionTypes.ADD_ASSIGNMENT_FAILED, payload: e.message});
    }
}

function* postTimesheetCodeComplete(action: Action): Generator<any, any, any> {
    try {
        if(action.payload.useUsername)
        {
            yield call(assignmentApi.postTimesheetCodeComplete, action.payload);
        }
        else {
            yield call(assignmentApi.postTimesheetCodeCompleteCurrentUser, action.payload);
        }

        yield put({type: ActionTypes.TIMESHEETCODE_COMPLETE_SUCCEEDED, payload: action.payload});
    } catch (e) {
        yield put({type: ActionTypes.TIMESHEETCODE_COMPLETE_FAILED, payload: e.message});
    }
}

function* postTimesheetCodeIncomplete(action: Action): Generator<any, any, any> {
    try {
        if(action.payload.useUsername)
        {
            yield call(assignmentApi.postTimesheetCodeIncomplete, action.payload);
        }
        else {
            yield call(assignmentApi.postTimesheetCodeIncompleteCurrentUser, action.payload);
        }

        yield put({type: ActionTypes.TIMESHEETCODE_INCOMPLETE_SUCCEEDED, payload: action.payload});
    } catch (e) {
        yield put({type: ActionTypes.TIMESHEETCODE_INCOMPLETE_FAILED, payload: e.message});
    }
}

function* deleteTimesheetCode(action: Action): Generator<any, any, any> {
    try {
        if(action.payload.useUsername)
        {
            yield call(assignmentApi.deleteTimesheetCode, action.payload);
        }
        else {
            yield call(assignmentApi.deleteTimesheetCodeCurrentUser, action.payload);
        }
        yield put({type: ActionTypes.TIMESHEETCODE_DELETE_SUCCEEDED, payload: action.payload});
    } catch (e) {
        yield put({type: ActionTypes.TIMESHEETCODE_DELETE_FAILED, payload: e.message});
    }
}

function* getAssignedTimesheetCodes(action: Action): Generator<any, any, any> {
    try {
        yield put({
            type: ActionTypes.GET_ASSIGNED_TIMESHEET_CODES_SUCCEEDED,
            payload: yield call(assignmentApi.getAssignedTimesheetCodes, action.payload.username, action.payload.month, action.payload.year)
        });
    } catch (e) {
        yield put({type: ActionTypes.GET_ASSIGNED_TIMESHEET_CODES_FAILED, payload: e.message});
    }
}

function* getTimesheetsFromClientInfo(action: Action): Generator<any,any,any> {
    try {
        yield put({
            type: ActionTypes.GET_TIMESHEETS_FROM_CLIENT_INFO_SUCCEEDED,
            payload: yield call(assignmentApi.getTimesheetsFromClientInfo, action.payload)
        });
    } catch (e) {
        yield put({type: ActionTypes.GET_TIMESHEETS_FROM_CLIENT_INFO_FAILED, payload: e.message});
    }
}

function* assignmentSaga(): Generator<any, any, any> {
    yield takeEvery(ActionTypes.ADD_ASSIGNMENT_REQUESTED, postAssignment);
    yield takeEvery(ActionTypes.TIMESHEETCODE_COMPLETE_REQUESTED, postTimesheetCodeComplete);
    yield takeEvery(ActionTypes.TIMESHEETCODE_INCOMPLETE_REQUESTED, postTimesheetCodeIncomplete);
    yield takeEvery(ActionTypes.TIMESHEETCODE_DELETE_REQUESTED, deleteTimesheetCode);
    yield takeEvery(ActionTypes.GET_ASSIGNED_TIMESHEET_CODES_REQUESTED, getAssignedTimesheetCodes);
    yield takeEvery(ActionTypes.GET_TIMESHEETS_FROM_CLIENT_INFO_REQUESTED, getTimesheetsFromClientInfo);
}

export default assignmentSaga;
