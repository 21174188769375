import {StoreUser} from '../typescript/storeTypes';

class AccessibleUserHelper {
    static isAccessibleUser(user: StoreUser): boolean {
       try {
            if(user.impersonatedUser.username.toLowerCase() === user.employee.username.toLowerCase()) {
                return true;
            }

            return user.visibleUsers.filter(vu => vu.isWritable)
                    .map(vu => vu.username.toLowerCase())
                    .indexOf(user.impersonatedUser.username.toLowerCase()) !== -1;
       } catch (error) {
            return false;
       }
    }
}

export default AccessibleUserHelper;
