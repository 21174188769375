import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';
import {
    MonthlyTimesheetToExport,
    TimesheetToComplete,
    TimesheetToIncomplete,
    WeeklyTimesheetToExport
} from '../typescript/timesheetTypes';
import {Period} from '../typescript/dateTypes';
import {StoreEmployee, StoreTimesheetCode, StoreTimesheetEntry, StoreUser} from '../typescript/storeTypes';
import {TimesheetEntryInfo} from '../typescript/infoTypes';
import {UploadClientTimesheet} from '../typescript/uploadTypes';
import UserHelper from '../helpers/userHelper';

export function getTimesheet(period: Period, employee: StoreEmployee): Action {
    return {type: ActionTypes.TIMESHEET_REQUESTED, payload: {period, employee}};
}

export function addEntry(entry: StoreTimesheetEntry, entryInfo: TimesheetEntryInfo, user: StoreUser) {
    const payload = Object.assign({}, {entry, entryInfo}, { useUsername: UserHelper.isImpersonating(user)});
    return {type: ActionTypes.ADD_ENTRY_REQUESTED, payload: payload};
}

export function updateEntry(entry: StoreTimesheetEntry, entryInfo: TimesheetEntryInfo, user: StoreUser) {
    const payload = Object.assign({}, {entry, entryInfo}, { useUsername: UserHelper.isImpersonating(user)});
    return {type: ActionTypes.UPDATE_ENTRY_REQUESTED, payload: payload};
}

export function deleteEntry(entry: StoreTimesheetEntry, entryInfo: TimesheetEntryInfo, user: StoreUser) {
    const payload = Object.assign({}, {entry, entryInfo}, { useUsername: UserHelper.isImpersonating(user)});
    return {type: ActionTypes.DELETE_ENTRY_REQUESTED, payload: payload};
}

export function completeTimesheet(timesheetToComplete: TimesheetToComplete, user: StoreUser): Action {
    const payload = Object.assign({}, timesheetToComplete, {useUsername: UserHelper.isImpersonating(user)});
    return {type: ActionTypes.TIMESHEET_COMPLETE_REQUESTED, payload: payload};
}

export function incompleteTimesheet(timesheetToIncomplete: TimesheetToIncomplete, user: StoreUser): Action {
    const payload = Object.assign({}, timesheetToIncomplete, {useUsername: UserHelper.isImpersonating(user)});
    return {type: ActionTypes.TIMESHEET_INCOMPLETE_REQUESTED, payload: payload};
}

export function expandTimesheetCode(timesheetCode: StoreTimesheetCode): Action {
    return {type: ActionTypes.TIMESHEET_CODE_EXPAND, payload: timesheetCode};
}

export function exportMonthlyTimesheet(monthlyTimesheetToExport: MonthlyTimesheetToExport): Action {
    return {type: ActionTypes.EXPORT_MONTHLY_TIMESHEET_REQUESTED, payload: monthlyTimesheetToExport};
}

export function exportMonthlyTimesheetExcel(monthlyTimesheetToExport: MonthlyTimesheetToExport): Action {
    return {type: ActionTypes.EXPORT_MONTHLY_TIMESHEET_EXCEL_REQUESTED, payload: monthlyTimesheetToExport};
}

export function exportWeeklyTimesheet(weeklyTimesheetToExport: WeeklyTimesheetToExport): Action {
    return {type: ActionTypes.EXPORT_WEEKLY_TIMESHEET_REQUESTED, payload: weeklyTimesheetToExport};
}

export function exportWeeklyTimesheetExcel(weeklyTimesheetToExport: WeeklyTimesheetToExport): Action {
    return {type: ActionTypes.EXPORT_WEEKLY_TIMESHEET_EXCEL_REQUESTED, payload: weeklyTimesheetToExport};
}

export function uploadClientTimesheet(clientTimesheet: UploadClientTimesheet, user: StoreUser): Action {
    const payload = Object.assign({}, clientTimesheet, {useUsername: UserHelper.isImpersonating(user)});
    return {type: ActionTypes.UPLOAD_CLIENTTIMESHEET_REQUESTED, payload: payload};
}

export function getIncompleteTimesheets(): Action {
    return {type: ActionTypes.GET_INCOMPLETE_TIMESHEETS_REQUESTED, payload: undefined};
}

export function getSigningHubOverview(assignmentIds: number[]): Action {
    return {type: ActionTypes.GET_SIGNINGHUB_OVERVIEW_REQUESTED, payload: assignmentIds};
}

export function clearSigningHubOverview(): Action {
    return {type: ActionTypes.CLEAR_SIGNINGHUB_OVERVIEW_REQUESTED, payload: null};
}
