import './App.styling';
import Content from './content/Content';
import CookieConsent from '../../common/CookieConsent';
import ErrorBoundary from '../../common/ErrorBoundary';
import Header from './header/Header';
import React from 'react';

class App extends React.PureComponent {
    render() {
        return (
            <ErrorBoundary>
                <div>
                    <Header/>
                    <Content/>
                    {process.env.BUILD_CONFIG !== 'LOCAL' ?
                            <CookieConsent/> : null}
                </div>
            </ErrorBoundary>
        );
    }
}

export default App;
