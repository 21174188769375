import MobileModal from './MobileModal';
import React from 'react';

class MobileClosableModal extends React.PureComponent<Props> {
    static defaultProps = {
        marginBottom: 0
    };

    closeModal = (event: KeyboardEvent): void => {
        if (event.key === 'Escape' || event.key === 'Esc') {
            this.props.closeModal();
        }
    };

    closeOnClick = (event: React.SyntheticEvent<MouseEvent> & { target: HTMLInputElement }): void => {
        if (event.target.className === 'modal-mobile-forefront') {
            this.props.closeModal();
        }
    };

    render() {
        return (
            <MobileModal>
                <div className="closable-mobile-modal">
                    <div className="closable-mobile-modal-header" style={{marginBottom: this.props.marginBottom}}>
                        <div className="closable-mobile-modal-close-icon">
                            <i className="fas fa-times" aria-hidden="true"
                               onClick={this.props.closeModal}/>
                        </div>
                        <div className="closable-mobile-modal-title">{this.props.title}</div>
                    </div>
                    {this.props.children}
                </div>
            </MobileModal>
        );
    }

    componentDidMount() {
        document.body.addEventListener('click', this.closeOnClick as any);
    }

    componentWillUnmount() {
        document.body.removeEventListener('click', this.closeOnClick as any);
    }
}

type Props = {
    title?: string,
    marginBottom: number,
    children: React.ReactElement[] | React.ReactElement,

    closeModal: () => void
};

export default MobileClosableModal;
