import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';
import {put, takeEvery} from 'redux-saga/effects';

import { StoreSavedPreset } from '../typescript/storeTypes';

const storage = window.localStorage;

function* getPresets(): Generator<any, any, any> {
    try {
        const savedPresets = getSavedPresetsFromLocalStorage();
        yield put({type: ActionTypes.GET_PRESETS_SUCCEEDED, payload: savedPresets});
    } catch (e) {
        yield put({type: ActionTypes.GET_PRESETS_FAILED, payload: []});
    }
}

function* savePreset(action: Action): Generator<any, any, any> {
    try {
        const payload = action.payload;
        const savedPresets = getSavedPresetsFromLocalStorage();
        const foundPreset = savedPresets.find((p: StoreSavedPreset) => comparePresets(p, payload));
        if(foundPreset) {
            foundPreset.data = payload.data;
        } else {
            const presetToSave = {
                location: payload.location.toLowerCase(),
                name: payload.name.toLowerCase(),
                data: payload.data
            };
            savedPresets.push(presetToSave);
        }

        savePresetsToLocalStorage(savedPresets);
        yield put({type: ActionTypes.SAVE_PRESET_SUCCEEDED, payload: savedPresets});
    } catch (e) {
        yield put({type: ActionTypes.SAVE_PRESET_FAILED, payload: []});
    }
}

function* deletePreset(action: Action): Generator<any, any, any> {
    try {
        const savedPresets = getSavedPresetsFromLocalStorage();
        const savedPresetWithoutPresetToDelete = savedPresets.filter((p: StoreSavedPreset) => !comparePresets(p, action.payload));

        savePresetsToLocalStorage(savedPresetWithoutPresetToDelete);
        yield put({type: ActionTypes.DELETE_PRESET_SUCCEEDED, payload: savedPresetWithoutPresetToDelete});
    } catch (e) {
        yield put({type: ActionTypes.DELETE_PRESET_FAILED, payload: undefined});
    }
}

function getSavedPresetsFromLocalStorage() {
    const savedPresets = storage.getItem('savedPresets');
    return savedPresets !== null ? JSON.parse(savedPresets) : [];
}

function savePresetsToLocalStorage(presets: StoreSavedPreset[]) {
    if(presets === null) {
        presets = [];
    }
    storage.setItem('savedPresets', JSON.stringify(presets));
}

function comparePresets(preset1: StoreSavedPreset, preset2: StoreSavedPreset) {
    return preset1.location.toLowerCase() === preset2.location.toLowerCase() && preset1.name.toLowerCase() === preset2.name.toLowerCase();
}

function* presetSaga(): Generator<any, any, any> {
    yield takeEvery(ActionTypes.GET_PRESETS_REQUESTED, getPresets);
    yield takeEvery(ActionTypes.SAVE_PRESET_REQUESTED, savePreset);
    yield takeEvery(ActionTypes.DELETE_PRESET_REQUESTED, deletePreset);
}

export default presetSaga;
