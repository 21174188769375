import {ActionTypes} from '../types/actionTypes';
import {call, put, takeEvery} from 'redux-saga/effects';
import maintenanceApi from '../api/maintenanceApi';

function* getMaintenanceDetails(): Generator<any, any, any> {
    try {
        const maintenanceDetails = yield call(maintenanceApi.getMaintenanceDetails);
        yield put({type: ActionTypes.MAINTENANCE_DETAILS_SUCCEEDED, payload: maintenanceDetails});
    } catch (e) {
        yield put({type: ActionTypes.MAINTENANCE_DETAILS_FAILED, payload: e.message});
    }
}

function* checkVersion(): Generator<any, any, any> {
    try {
        const isLatest = yield call(maintenanceApi.checkVersion);
        yield put({type: ActionTypes.CHECK_VERSION_SUCCEEDED, payload: isLatest});
    } catch (e) {
        yield put({type: ActionTypes.CHECK_VERSION_FAILED, payload: e.message});
    }
}

function* maintenanceSaga(): Generator<any, any, any> {
    yield takeEvery(ActionTypes.MAINTENANCE_DETAILS_REQUESTED, getMaintenanceDetails);
    yield takeEvery(ActionTypes.CHECK_VERSION_REQUESTED, checkVersion);
}

export default maintenanceSaga;
