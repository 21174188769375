import {ActualsReportType, TimeLeftOnOrderType, TimesheetsLandscapeReportType, TimesheetsReportType, TimesheetsWeeklyReportType} from '../typescript/reportTypes';
import AuthorizedRequest from './authorizedRequests';
import DateHelper from '../helpers/dateHelper';
import ErrorHelper from '../helpers/errorHelper';

class ReportApi {
    static exportActualsPdf(actuals: ActualsReportType) {
        return AuthorizedRequest.postAuthorizedRequest(`reports/actuals/pdf?orderby=${actuals.orderBy}`, actuals)
            .ok(res => res.status === 200)
            .responseType('blob')
            .then(response => {
                const customResponse = {
                    fileName: `Actuals_${DateHelper.getIsoFormattedDate(actuals.startDate, '-')}_${DateHelper.getIsoFormattedDate(actuals.endDate,'-')}.pdf`,
                    blob: response.body
                };
                return customResponse;
            })
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static getTimeLeftOnOrder(timeLeftOnOrder: TimeLeftOnOrderType) {
        return AuthorizedRequest.postAuthorizedRequest('reports/order/timeleft', timeLeftOnOrder)
            .ok(res => res.status === 200)
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static exportActualsCsv(actuals: ActualsReportType) {
        return AuthorizedRequest.postAuthorizedRequest(`reports/actuals/csv?orderby=${actuals.orderBy}`, actuals)
            .ok(res => res.status === 200)
            .responseType('blob')
            .then(response => {
                const customResponse = {
                    fileName: `Actuals_${DateHelper.getIsoFormattedDate(actuals.startDate, '-')}_${DateHelper.getIsoFormattedDate(actuals.endDate,'-')}.csv`,
                    blob: response.body
                };
                return customResponse;
            })
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static exportActualsExcel(actuals: ActualsReportType) {
        return AuthorizedRequest.postAuthorizedRequest(`reports/actuals/excel?orderby=${actuals.orderBy}`, actuals)
            .ok(res => res.status === 200)
            .responseType('blob')
            .then(response => {
                const customResponse = {
                    fileName: `Actuals_${DateHelper.getIsoFormattedDate(actuals.startDate, '-')}_${DateHelper.getIsoFormattedDate(actuals.endDate,'-')}.xlsx`,
                    blob: response.body
                };
                return customResponse;
            })
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static exportTimesheetsPdf(timesheets: TimesheetsReportType) {
        return AuthorizedRequest.postAuthorizedRequest('reports/timesheets/pdf', timesheets)
            .ok(res => res.status === 200)
            .responseType('blob')
            .then(response => {
                const customResponse = {
                    fileName: `Timesheets_${timesheets.month}_${timesheets.year}.pdf`,
                    blob: response.body
                };
                return customResponse;
            })
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static exportTimesheetsWeeklyPdf(timesheets: TimesheetsWeeklyReportType) {
        return AuthorizedRequest.postAuthorizedRequest('reports/timesheetsweekly/pdf', timesheets)
            .ok(res => res.status === 200)
            .responseType('blob')
            .then(response => {
                const customResponse = {
                    fileName: `Timesheets_${DateHelper.getIsoFormattedDate(timesheets.startDate, '-')}.pdf`,
                    blob: response.body
                };
                return customResponse;
            })
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static exportTimesheetsWeeklyExcel(timesheets: TimesheetsWeeklyReportType) {
        return AuthorizedRequest.postAuthorizedRequest('reports/timesheetsweekly/excel', timesheets)
            .ok(res => res.status === 200)
            .responseType('blob')
            .then(response => {
                const customResponse = {
                    fileName: `Timesheets_${DateHelper.getIsoFormattedDate(timesheets.startDate, '-')}.xlsx`,
                    blob: response.body
                };
                return customResponse;
            })
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static exportTimesheetsLandscapePdf(timesheets: TimesheetsLandscapeReportType) {
        return AuthorizedRequest.postAuthorizedRequest('reports/timesheetslandscape/pdf', timesheets)
            .ok(res => res.status === 200)
            .responseType('blob')
            .then(response => {
                const customResponse = {
                    fileName: `Timesheets_Landscape_${timesheets.month}_${timesheets.year}.pdf`,
                    blob: response.body
                };
                return customResponse;
            })
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static exportTimesheetsLandscapeExcel(timesheets: TimesheetsLandscapeReportType) {
        return AuthorizedRequest.postAuthorizedRequest('reports/timesheetslandscape/excel', timesheets)
            .ok(res => res.status === 200)
            .responseType('blob')
            .then(response => {
                const customResponse = {
                    fileName: `Timesheets_Landscape_${timesheets.month}_${timesheets.year}.xlsx`,
                    blob: response.body
                };
                return customResponse;
            })
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }
}

export default ReportApi;
